import React from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, Box, useTheme, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { BLStatus } from 'models/booking.model';

interface BLHistoryTableProps {
  blHistoryArray: BLStatus[] | undefined;
  blRemarks: string | undefined;
}

const BLHistoryTable: React.FC<BLHistoryTableProps> = ({ blHistoryArray, blRemarks }) => {
  const theme = useTheme();
  return (
    <Box>
      <Table
        sx={{
          mt: '1rem',
          '& .MuiTableCell-root': {
            padding: '5px',
            borderBottom: 'none',
            color: theme.palette.secondary[100],
          },
          '& .MuiTableHead-root .MuiTableCell-root': {
            fontWeight: '600',
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>Status Date</TableCell>
            <TableCell>Follow Up</TableCell>
            <TableCell>Follow Up Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {blHistoryArray?.map((blStatus, index) => (
            <TableRow key={index}>
              <TableCell>{blStatus.status}</TableCell>
              <TableCell>{blStatus.statusDate ? dayjs(blStatus.statusDate).format('DD/MM/YYYY') : ''}</TableCell>
              <TableCell>{blStatus.followUp}</TableCell>
              <TableCell>{blStatus.followUpDate ? dayjs(blStatus.followUpDate).format('DD/MM/YYYY') : ''}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Typography variant='body2' sx={{ mt: '1rem', pl: '0.3rem', color: 'white' }}>
        Remarks: {blRemarks}
      </Typography>
    </Box>
  );
};

export default BLHistoryTable;
