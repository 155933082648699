import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Box, TextField, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { sec } from '../../auth/accessToken';
import { useGetContactsQuery } from '../../state/api';
import { BaseDialogueProps, Contact } from 'models/index.model';
import AlertSnackbar from 'components/AlertSnackbar';
import GeneralDialogueActions from 'components/GeneralDialogueActions';
import { uniqueNameRefinement } from 'utils/utils';

export interface CustomerContactProps extends BaseDialogueProps {
  contactToUpdate?: Contact;
}

const CreateContactDialogue: React.FC<CustomerContactProps> = ({ handleClose, open, contactToUpdate }) => {
  const isEditing = !!contactToUpdate?._id;

  const { data: contacts } = useGetContactsQuery({});

  const contactCreationSchema = z.object({
    role: z.string().min(1, { message: 'Role is required' }),
    name: z
      .string()
      .min(1, { message: 'Name is required' })
      .refine(uniqueNameRefinement(contacts?.masterData, contactToUpdate, 'name'), {
        message: 'A contact with that name already exists',
      }),
    phoneNumber: z.string().optional(),
    email: z
      .string()
      .email('Email is not valid')
      .min(1, { message: 'Email is required' })
      .refine(uniqueNameRefinement(contacts?.masterData, contactToUpdate, 'email'), {
        message: 'A contact with that email already exists',
      }),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<Contact>({
    resolver: zodResolver(contactCreationSchema),
  });

  useEffect(() => {
    reset(contactToUpdate);
  }, [contactToUpdate, reset]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleSnackbarClose = (_event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const onSubmit = async (data: Contact) => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();
      let apiUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/contacts/`;

      if (isEditing) {
        apiUrl += `${contactToUpdate._id}`;
        await axios.patch(apiUrl, data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      } else {
        await axios.post(apiUrl, data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      }
      setSnackbarOpen(true);
      handleClose();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth='md'>
        <DialogTitle
          sx={{
            fontSize: '1.6rem',
          }}
        >
          {`${isEditing ? 'Edit' : 'Create New'} Contact`}
        </DialogTitle>
        <DialogContent>
          <form>
            <Box display={'grid'} gridTemplateColumns={'1fr 1fr'} gap={'0.5rem'}>
              <TextField
                label='Role *'
                autoComplete='off'
                variant='filled'
                {...register('role')}
                error={!!errors.role}
                helperText={errors.role?.message}
              />
              <TextField
                label='Name *'
                autoComplete='off'
                variant='filled'
                {...register('name')}
                error={!!errors.name}
                helperText={errors.name?.message}
              />
              <TextField
                label='Email *'
                autoComplete='off'
                variant='filled'
                {...register('email')}
                error={!!errors.email}
                helperText={errors.email?.message}
              />
              <TextField
                label='Phone Number '
                autoComplete='off'
                variant='filled'
                {...register('phoneNumber')}
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber?.message}
              />
            </Box>
          </form>
        </DialogContent>
        <GeneralDialogueActions
          onClick={handleSubmit(onSubmit)}
          handleClose={handleClose}
          submitText={isEditing ? 'Edit' : 'Create'}
        />
      </Dialog>
      <AlertSnackbar
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        severity={'success'}
        message={isEditing ? 'Contact Updated!' : 'New Contact Created!'}
      />
    </Box>
  );
};

export default CreateContactDialogue;
