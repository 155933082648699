import { AddOutlined, EditOutlined, RecentActorsOutlined, RefreshOutlined } from '@mui/icons-material';
import { Box, Button, IconButton, Tooltip, useTheme } from '@mui/material';
import { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import AuditLogsDialogue from 'components/AuditLogsDialogue';
import CreateBankAccounts from 'components/CreateBankAccounts';
import CustomDataGrid from 'components/CustomDataGrid';
import { IBankAccounts } from 'models/index.model';
import { default as React, useReducer, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useGetAuditsQuery, useGetBankAccountsQuery } from 'state/api';
import { initialState, reducer } from '../../utils/dialogState';
import Header from 'components/Header';
import SearchBar from 'components/SearchBar';

const BankAccounts = () => {
  const theme = useTheme();
  const [{ open, entityToUpdate }, dispatchAction] = useReducer(reducer<IBankAccounts>, initialState<IBankAccounts>());
  const [bankAccountsData, setBankAccountsData] = useState<IBankAccounts>({} as IBankAccounts);
  const { data: backAccountsAuditLogs } = useGetAuditsQuery(['BankAccounts', bankAccountsData?._id]);
  const [openAuditLogs, setOpenAuditLogs] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [totalRows, setTotalRows] = useState(0);
  const [searchText, setSearchText] = useState<string>('');
  const [selectedField, setSelectedField] = useState<string>('');

  const {
    data: bankAccounts,
    isLoading,
    refetch,
    isFetching,
  } = useGetBankAccountsQuery({ page: (page + 1).toString(), limit: pageSize.toString(), searchText, selectedField });

  React.useEffect(() => {
    setTotalRows(bankAccounts?.totalRecords ?? 0);
  }, [bankAccounts?.totalRecords]);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    bank: true,
    branch: true,
    address: true,
    accountNumber: true,
    currency: true,
  });

  const defaultValues = {
    bank: undefined,
    branch: undefined,
    address: undefined,
    accountNumber: undefined,
    currency: undefined,
    iban: undefined,
    sortCode: undefined,
    swiftBic: undefined,
  };

  const currentForm = useForm<IBankAccounts>({
    defaultValues: defaultValues,
  });

  const { reset } = currentForm;
  const handleClickOpenCreate = () => {
    dispatchAction({ type: 'OPEN_DIALOG', payload: {} as IBankAccounts });
  };

  const handleClickOpenEdit = (bankAccount: IBankAccounts) => {
    dispatchAction({ type: 'OPEN_DIALOG', payload: bankAccount as IBankAccounts });
  };

  const handleClose = () => {
    dispatchAction({ type: 'CLOSE_DIALOG' });
    reset(defaultValues);
    refetch();
  };

  const columns: GridColDef[] = [
    {
      field: 'bank',
      headerName: 'Bank',
      flex: 0.3,
    },
    {
      field: 'branch',
      headerName: 'Branch',
      flex: 0.3,
    },
    {
      field: 'address',
      headerName: 'Address',
      flex: 0.3,
    },
    {
      field: 'accountNumber',
      headerName: 'A/C No.',
      flex: 0.3,
    },
    {
      field: 'currency',
      headerName: 'Currency',
      flex: 0.3,
    },
    {
      field: 'Actions',
      flex: 0.4,
      renderCell: (cellValues: any) => {
        return (
          <Box display={'flex'} justifyContent={'space-evenly'}>
            <Box>
              <Tooltip title='View Audit Logs'>
                <IconButton
                  sx={{ color: theme.palette.secondary[400] }}
                  onClick={() => {
                    setBankAccountsData(cellValues.row);
                    setOpenAuditLogs(true);
                  }}
                >
                  <RecentActorsOutlined />
                </IconButton>
              </Tooltip>
            </Box>
            <Box>
              <Tooltip title='Edit Bank accounts'>
                <IconButton
                  sx={{ color: theme.palette.secondary[400] }}
                  onClick={() => handleClickOpenEdit(cellValues.row)}
                >
                  <EditOutlined />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        );
      },
    },
  ];

  const handleColumnVisibilityChange = (newModel: GridColumnVisibilityModel) => {
    setColumnVisibilityModel(newModel);
  };

  return (
    <Box m='1.5rem 2.5rem'>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        <Box display={'flex'} gap={2} justifyContent={'center'} alignItems={'center'}>
          <Header title='Bank Accounts' />
          <SearchBar
            setSearchText={setSearchText}
            setSelectedField={setSelectedField}
            setPage={setPage}
            columns={columns}
          />
        </Box>
        <Box>
          <Tooltip title={`Create a Bank Account`}>
            <Button
              variant='contained'
              startIcon={<AddOutlined />}
              onClick={handleClickOpenCreate}
              sx={{
                bgcolor: theme.palette.secondary[400],
                '&:hover': {
                  backgroundColor: theme.palette.secondary[500],
                },
              }}
            >
              {`Create Bank Account`}
            </Button>
          </Tooltip>
        </Box>

        {/* <Box>
            <Tooltip title='Refresh'>
              <IconButton onClick={() => refetch()}>
                <RefreshOutlined />
              </IconButton>
            </Tooltip>
          </Box> */}
      </Box>

      <CustomDataGrid
        data={bankAccounts?.masterData as IBankAccounts[]}
        columns={columns}
        isLoading={isLoading}
        isFetching={isFetching}
        columnVisibilityModel={columnVisibilityModel}
        handleColumnVisibilityChange={handleColumnVisibilityChange}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
        totalRows={totalRows}
      />
      <CreateBankAccounts
        useFormReference={currentForm}
        defaultValues={defaultValues}
        handleClose={handleClose}
        open={open}
        entityToUpdate={entityToUpdate}
      />
      <AuditLogsDialogue
        open={openAuditLogs}
        handleClose={() => setOpenAuditLogs(false)}
        auditLogs={backAccountsAuditLogs}
      />
    </Box>
  );
};

export default BankAccounts;
