import React, { useReducer, useState } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { AddOutlined } from '@mui/icons-material';
import Header from '../../components/Header';
import { useGetAuditsQuery, useGetVendorsQuery } from '../../state/api';
import CreateVendorDialogue from '../../components/CreateVendorDialogue';
import { Box, Button, useTheme } from '@mui/material';
import { Contact, Vendor } from 'models/index.model';
import { initialState, reducer } from '../../utils/dialogState';
import { useUserPermissions } from 'utils/utils';
import { generateActionsColumn } from 'utils/generateActionsColumn';
import { sec } from 'auth/accessToken';
import axios from 'axios';
import CustomDataGrid from 'components/CustomDataGrid';
import AuditLogsDialogue from 'components/AuditLogsDialogue';
import SearchBar from 'components/SearchBar';

const Vendors = () => {
  const theme = useTheme();

  const userPermissions = useUserPermissions();
  const hasCreateMasterDataPermission = userPermissions.includes('create:masterdata');
  const hasUpdateMasterDataPermission = userPermissions.includes('update:masterdata');
  const hasDeleteMasterDataPermission = userPermissions.includes('delete:masterdata');

  const [{ open, entityToUpdate }, dispatch] = useReducer(reducer<Vendor>, initialState<Vendor>());

  const handleClickOpenCreate = () => {
    dispatch({ type: 'OPEN_DIALOG', payload: {} as Vendor });
  };

  const handleClickOpenEdit = (vendor: Vendor) => {
    dispatch({ type: 'OPEN_DIALOG', payload: vendor });
  };

  const handleClose = () => {
    dispatch({ type: 'CLOSE_DIALOG' });
    refetch();
  };

  const [openConfirmDialogue, setOpenConfirmDialogue] = useState(false);
  const [openAuditDialogue, setOpenAuditDialogue] = useState(false);
  const [currentMasterDataId, setCurrentMasterDataId] = useState<string>('');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [totalRows, setTotalRows] = useState(0);
  const [searchText, setSearchText] = useState<string>('');
  const [selectedField, setSelectedField] = useState<string>('');

  const {
    data: vendors,
    isLoading,
    refetch,
  } = useGetVendorsQuery({ page: (page + 1).toString(), limit: pageSize.toString(), searchText, selectedField });

  React.useEffect(() => {
    setTotalRows(vendors?.totalRecords ?? 0);
  }, [vendors?.totalRecords]);

  const { data: auditLogs } = useGetAuditsQuery(['Vendor', currentMasterDataId]);

  const deleteVendor = async (vendorId: string) => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();

      await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/v1/vendors/${vendorId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.5,
    },
    {
      field: 'address',
      headerName: 'Address',
      flex: 1.2,
      valueGetter(params) {
        return `${params.row.address}, ${params.row.city}, ${params.row.postcode}, ${params.row.country}`;
      },
    },
    {
      field: 'contacts',
      headerName: 'Contacts',
      flex: 0.6,
      valueGetter(params) {
        return (params.row?.contacts as Contact[] | undefined)?.map(contact => contact.name).join(', ');
      },
    },
    {
      field: 'creditPeriod',
      headerName: 'Credit Period',
      flex: 0.2,
    },
    {
      field: 'creditLimit',
      headerName: 'Credit Limit (£)',
      flex: 0.2,
    },
    {
      field: 'vendorType',
      headerName: 'Vendor Type',
      flex: 0.3,
    },
  ];

  if (hasUpdateMasterDataPermission || hasDeleteMasterDataPermission) {
    columns.push(
      generateActionsColumn<Vendor>(
        hasUpdateMasterDataPermission,
        hasDeleteMasterDataPermission,
        handleClickOpenEdit,
        setOpenAuditDialogue,
        deleteVendor,
        openConfirmDialogue,
        setOpenConfirmDialogue,
        currentMasterDataId,
        setCurrentMasterDataId,
      ),
    );
  }

  return (
    <Box m='1.5rem 2.5rem'>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Box display={'flex'} gap={2} justifyContent={'center'} alignItems={'center'}>
          <Header title='Vendors' />
          <SearchBar
            setSearchText={setSearchText}
            setSelectedField={setSelectedField}
            setPage={setPage}
            columns={columns}
          />
        </Box>
        <Box>
          {hasCreateMasterDataPermission && (
            <Button
              variant='contained'
              startIcon={<AddOutlined />}
              onClick={handleClickOpenCreate}
              sx={{
                bgcolor: theme.palette.secondary[400],
                '&:hover': {
                  backgroundColor: theme.palette.secondary[500],
                },
              }}
            >
              Create Vendor
            </Button>
          )}
          <CreateVendorDialogue handleClose={handleClose} open={open} vendorToUpdate={entityToUpdate} />
        </Box>
      </Box>
      <CustomDataGrid
        data={vendors?.masterData as Vendor[]}
        columns={columns}
        isLoading={isLoading}
        sortModel={[{ field: 'name', sort: 'asc' }]}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
        totalRows={totalRows}
      />
      <AuditLogsDialogue
        open={openAuditDialogue}
        handleClose={() => setOpenAuditDialogue(false)}
        auditLogs={auditLogs}
      />
    </Box>
  );
};

export default Vendors;
