import React, { Dispatch, SetStateAction, useMemo, useRef, useState } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  useTheme,
  IconButton,
  Typography,
  Tooltip,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  InfoOutlined,
  AddOutlined,
  EditOutlined,
  MoreVertOutlined,
  DeleteOutlined,
  FileDownloadOutlined,
  RecentActorsOutlined,
  SendOutlined,
} from '@mui/icons-material';
import UpdateInvoiceDialogue from '../UpdateInvoiceDialogue';
import axios, { isAxiosError } from 'axios';
import { sec } from '../../auth/accessToken';
import InvoiceDialogue from '../InvoiceDialogue';
import InvoiceViewer from '../InvoiceViewer';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import { Booking, BookingStatus, Invoice } from 'models/booking.model';
import { currencySymbols, invoiceTypes } from 'dataAssets/constants';
import {
  useUserPermissions,
  totalInvoiceValue,
  checkMissingKeys,
  SnackbarState,
  defaultSnackbarState,
  extractInvoiceShortForm,
} from 'utils/utils';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { pdf, PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import InvoiceConfirmation from 'components/InvoiceConfirmation';
import AlertSnackbar from 'components/AlertSnackbar';
import { default as allAPIs, useGetAuditsQuery, useGetBankAccountsQuery, useGetUserPreferenceQuery } from 'state/api';
import AuditLogsDialogue from 'components/AuditLogsDialogue';
import LoadingButton from 'components/LoadingButton';
import CustomModel from 'components/CustomModel';
import HorizontalStepper from 'components/HorizontalStepper';
import { CustomStepProp } from 'components/HorizontalStepper/HorizontalStepper';
import EmailForm from 'components/EmailForm';
import { z } from 'zod';
import { emailFieldNames, nameType } from 'components/EmailForm/EmailForm';
import { reduxStore } from 'utils/reduxStore';
import { IGetMasterDataResponse } from 'models/index.model';

export interface InvoiceTableProps {
  booking: Booking;
  invoiceType: string;
  refetch: () => void;
}
const InvoiceTable: React.FC<InvoiceTableProps> = ({ booking, invoiceType, refetch }) => {
  const theme = useTheme();

  const userPermissions = useUserPermissions();

  const retryRef = useRef(0);

  const missingKeys = checkMissingKeys(booking);

  const [currentInvoice, setCurrentInvoice]: [Invoice, Dispatch<SetStateAction<Invoice>>] = useState({} as Invoice);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<number | null>(null);
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setOpen(index);
    setCurrentInvoice(invoices[index]);
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setOpen(null);
    setAnchorEl(null);
  };

  const [snackbarOpen, setSnackbarOpen] = useState<SnackbarState>(defaultSnackbarState);

  const [openConfirmDeletionDialogue, setOpenConfirmDeletionDialogue] = useState(false);
  const [openCustomModel, setOpenCustomModel] = useState(false);
  const [openConfirmApprovalDialogue, setOpenConfirmApprovalDialogue] = useState(false);

  const [openInvoice, setOpenInvoice] = useState(false);
  const handleClickOpenInvoice = () => {
    setOpenInvoice(true);
    handleMenuClose();
  };
  const handleCloseInvoice = () => {
    setOpenInvoice(false);
  };

  const [openCreateSalesInvoice, setOpenCreateSalesInvoice] = useState(false);
  const handleClickOpenCreateSalesInvoice = () => {
    setOpenCreateSalesInvoice(true);
  };
  const handleCloseCreateSalesInvoice = () => {
    setOpenCreateSalesInvoice(false);
    refetch();
  };

  const [openUpdate, setOpenUpdate] = useState(false);
  const handleClickOpenUpdate = () => {
    setOpenUpdate(true);
    handleMenuClose();
  };
  const handleCloseUpdate = () => {
    setOpenUpdate(false);
    refetch();
  };

  const [openAuditLogs, setOpenAuditLogs] = useState(false);

  const { data: invoicesAuditLogs, refetch: refetchAuditLogs } = useGetAuditsQuery(['Invoice', currentInvoice?._id]);

  const invoices = booking[invoiceType as keyof Booking] as Invoice[];

  const invoicePDFElement = useMemo(
    () => <InvoiceConfirmation invoice={currentInvoice} booking={booking} invoiceType={invoiceType} />,
    [booking, currentInvoice, invoiceType],
  );

  function updateApproval(sendEmail: true): (data: Record<string, string | string[]>) => Promise<void>;
  function updateApproval(sendEmail?: false | undefined): () => Promise<void>;
  function updateApproval(sendEmail?: boolean): (data?: Record<string, string | string[]>) => Promise<void>;
  function updateApproval(sendEmail = false) {
    return async (data?: Record<string, string | string[]>) => {
      try {
        const formData = new FormData();
        if (sendEmail && typeof data !== 'undefined') {
          const { data: allInvoice } = await reduxStore.dispatch(
            allAPIs.getAccountings.initiate([invoiceType], { forceRefetch: true }),
          );
          const currentYear = new Date().getFullYear().toString().slice(-2);
          const latestApprovedInvoice = (allInvoice?.invoices ?? [])
            .filter(inv => inv.approvalPending === false && inv.approvedInvoiceNumber.includes(currentYear))
            .sort((a, b) => ((a.approvedInvoiceNumber ?? '') > (b.approvedInvoiceNumber ?? '') ? -1 : 1))
            .at(0);
          const lastNumber = latestApprovedInvoice?.approvedInvoiceNumber
            ? parseInt(latestApprovedInvoice.approvedInvoiceNumber.slice(-8))
            : 0;

          const newNumber = (lastNumber % 99999999) + 1;
          const serverData = {
            approvalPending: false,
            disputeDetails: {
              underDispute: false,
            },
            approvalDate: new Date().toISOString(),
            approvedInvoiceNumber: `${extractInvoiceShortForm(
              invoiceType,
            )}/${currentYear}/${newNumber.toString().padStart(8, '0')}`,
          };
          formData.append('patch', JSON.stringify(serverData));
          const newInvoice = {
            ...currentInvoice,
            ...serverData,
          };
          const blob = await pdf(
            <InvoiceConfirmation invoice={newInvoice as Invoice} booking={booking} invoiceType={invoiceType} />,
          ).toBlob();
          formData.append(
            'sendEmail',
            JSON.stringify({
              type: invoiceType,
              to: data.to_emails,
              cc: data.cc_emails,
              from: data.from_emails,
              customEmailSubject: data.customEmailSubject,
              customEmailBody: data.customEmailBody,
            }),
          );
          const formattedApprovedInvoiceNumber = newInvoice.approvedInvoiceNumber.replace(/\//g, '-');
          if (blob !== null) {
            formData.append('pdf', blob, `${formattedApprovedInvoiceNumber}.pdf`);
          }
        }
        const accessToken = await sec.getAccessTokenSilently()();

        await axios
          .patch(`${process.env.REACT_APP_BASE_URL}/api/v1/${invoiceType}/approve/${currentInvoice._id}`, formData, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'multipart/form-data',
            },
          })
          .catch(ex => {
            if (isAxiosError(ex) && ex.response && ex.response.status === 409) {
              // retry
              console.log('retrying ', retryRef.current);
              if (retryRef.current >= 3) {
                throw ex;
              }
              retryRef.current = retryRef.current + 1;
              return updateApproval(sendEmail)(data);
            }
          });
      } catch (err) {
        retryRef.current = retryRef.current - 1;
        console.error(err);
        setSnackbarOpen({
          open: true,
          message: `Unable to approve Invoice. Try again.`,
          color: 'error',
        });
        if (retryRef.current >= 0) {
          throw err;
        }
        return;
      }
      refetch();
      refetchAuditLogs();
      setOpenCustomModel(false);
      setSnackbarOpen({
        open: true,
        message: `Invoice approved ${sendEmail ? 'and mail sent ' : ''}successfully`,
        color: 'success',
      });
    };
  }

  const bankAccounts = useGetBankAccountsQuery({}).data as IGetMasterDataResponse | undefined;

  const createTempSalesInvoice = async () => {
    try {
      const salesInvoice = {
        charges: booking.sellRates,
        numberOfContainers: booking.numberOfContainers,
        bookingNumber: booking.bookingNumber,
        bankAccount: bankAccounts?.masterData[0]?._id,
        invoiceCurrency: bankAccounts?.masterData[0].currency,
        remarks: '',
        customer: booking.bookingType === 'Export' ? booking.consignor : booking.consignee,
      };
      const accessToken = await sec.getAccessTokenSilently()();

      const salesInvoiceResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/salesInvoices/`,
        salesInvoice,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/bookings/${booking._id}`,
        { salesInvoices: [salesInvoiceResponse.data.id] },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      refetch();
      refetchAuditLogs();
    } catch (err) {
      console.error(err);
    }
  };

  const deleteInvoice = async (invoiceId: string) => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();

      await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/v1/${invoiceType}/${invoiceId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      refetch();
    } catch (err) {
      console.error(err);
    }
  };

  const invoiceSentStatus = async (invoiceId: string) => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();
      await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/${invoiceType}/${invoiceId}`,
        { invoiceSent: true },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      setSnackbarOpen({
        open: true,
        message: `Marked mail sent successfully`,
        color: 'success',
      });
      refetch();
    } catch (err) {
      console.error(err);
      setSnackbarOpen({
        open: true,
        message: `Failed to mark mail sent`,
        color: 'error',
      });
    }
  };

  const invoiceConfirmationElement = useMemo<CustomStepProp>(
    () => ({
      element: <PDFViewer style={{ width: '100%', height: '50vh' }}>{invoicePDFElement}</PDFViewer>,
      label: 'Invoice Confirmation',
    }),
    [invoicePDFElement],
  );

  // TODO: already defined in QuotationTable.tsx component - merge with that
  const { data: userPreferenceArr } = useGetUserPreferenceQuery();
  const emailFormElement = useMemo<CustomStepProp>(() => {
    const emailsOfUsers = userPreferenceArr?.masterData?.map(pref => pref.email) || [];
    const zodEmailSelectorValidation = z.array(z.string().email({ message: 'Invalid email format' }));
    const emailConfigs =
      booking.bookingType === 'Export' ? booking.consignor?.emailConfig : booking.consignee?.emailConfig;
    const transformedMasterValues = emailConfigs?.reduce(
      (acc, config) => {
        acc[config.name] = {
          from_emails: config.from,
          to_emails: config.to,
          cc_emails: config.cc,
        };
        return acc;
      },
      {} as Record<string, Record<string, string[]>>,
    );
    return {
      element: (
        <EmailForm
          nameField={{
            type: nameType.list,
            values: emailConfigs?.map(config => config.name),
          }}
          masterValues={transformedMasterValues}
          fieldConfig={[
            {
              name: emailFieldNames.from,
              label: 'From Emails',
              options: [...new Set([...(emailConfigs?.flatMap(conf => conf.from) ?? []), ...emailsOfUsers])],
            },
            {
              name: emailFieldNames.to,
              label: 'To Emails',
              freeSolo: true,
              options: emailConfigs?.flatMap(conf => conf.to) ?? [],
            },
            {
              name: emailFieldNames.cc,
              label: 'CC Emails',
              freeSolo: true,
              options: emailConfigs?.flatMap(conf => conf.cc) ?? [],
            },
          ]}
          customTemplate={true}
        />
      ),
      label: 'Email Confirmation',
      validationSchema: z.object({
        from_emails: zodEmailSelectorValidation
          .min(1, {
            message: 'This field has to be filled.',
          })
          .max(1, {
            message: 'This field cannot have more than one email.',
          }),
        to_emails: zodEmailSelectorValidation.min(1, {
          message: 'This field has to be filled.',
        }),
        cc_emails: zodEmailSelectorValidation,
      }),
    };
  }, [booking.bookingType]);

  return (
    <Box>
      <Box>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography
            sx={{
              letterSpacing: '0.8px',
              color: 'white',
            }}
          >{`${invoiceTypes[invoiceType]}s`}</Typography>
          {booking.bookingStatus !== BookingStatus.Completed && (
            <Tooltip title={'Create'}>
              <IconButton onClick={handleClickOpenCreateSalesInvoice}>
                <AddOutlined sx={{ color: 'white' }} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        {invoiceType === 'salesInvoices' && booking.salesInvoices.length === 0 && (
          <LoadingButton
            sx={{
              backgroundColor: theme.palette.secondary[400],
              color: theme.palette.secondary[100],
              '&:hover': {
                backgroundColor: theme.palette.secondary[500],
              },
            }}
            size='small'
            variant='contained'
            startIcon={<AddOutlined />}
            onClick={() => createTempSalesInvoice()}
            loadingText='Loading'
            text='Create Initial Sales Invoice'
          />
        )}
        <InvoiceDialogue
          handleClose={handleCloseCreateSalesInvoice}
          open={openCreateSalesInvoice}
          id={booking._id}
          invoices={invoices ?? []}
          booking={booking}
          invoiceType={invoiceType}
        />
      </Box>
      {invoices.length !== 0 && (
        <Table
          sx={{
            '& .MuiTableCell-root': {
              padding: '8px',
              borderBottom: 'none',
              color: theme.palette.secondary[100],
            },
            '& .MuiTableHead-root .MuiTableCell-root': {
              fontWeight: '600',
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Invoice No</TableCell>
              <TableCell>Invoice Party</TableCell>
              <TableCell>Total</TableCell>
              <TableCell align='center'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              '& .MuiTableCell-root': {
                paddingTop: '0px',
                paddingBottom: '0px',
              },
            }}
          >
            {invoices.map((invoice, index) => (
              <TableRow key={index}>
                <TableCell>{invoice.approvedInvoiceNumber ?? invoice.performaInvoiceNumber}</TableCell>

                <TableCell>{invoice.vendor?.name ?? invoice.customer?.name ?? 'N/A'}</TableCell>
                <TableCell>{`${currencySymbols[invoice.invoiceCurrency]} ${totalInvoiceValue(
                  invoice.charges,
                  invoice.numberOfContainers,
                )}`}</TableCell>
                <TableCell align='center'>
                  <>
                    <IconButton onClick={event => handleMenuOpen(event, index)}>
                      <MoreVertOutlined sx={{ color: 'white' }} />
                    </IconButton>
                    <Menu id={`menu-${index}`} anchorEl={anchorEl} open={open === index} onClose={handleMenuClose}>
                      <MenuItem
                        onClick={() => {
                          handleClickOpenInvoice();
                        }}
                      >
                        <ListItemIcon>
                          <InfoOutlined />
                        </ListItemIcon>
                        <ListItemText primary='View' />
                      </MenuItem>
                      {!invoice.approvalPending && (
                        <PDFDownloadLink
                          document={invoicePDFElement}
                          fileName={`${invoice.approvedInvoiceNumber?.replace(/\//g, '-')}`}
                        >
                          {({ loading }) =>
                            loading ? null : (
                              <>
                                <MenuItem>
                                  <ListItemIcon>
                                    <FileDownloadOutlined />
                                  </ListItemIcon>
                                  <ListItemText primary='Download' />
                                </MenuItem>
                              </>
                            )
                          }
                        </PDFDownloadLink>
                      )}
                      {invoice.approvalPending && (
                        <MenuItem
                          onClick={() => {
                            handleClickOpenUpdate();
                          }}
                        >
                          <ListItemIcon>
                            <EditOutlined />
                          </ListItemIcon>
                          <ListItemText primary='Edit' />
                        </MenuItem>
                      )}
                      {invoice.approvalPending && userPermissions.includes('delete:invoice') && (
                        <MenuItem
                          onClick={() => {
                            setOpenConfirmDeletionDialogue(true);
                            handleMenuClose();
                          }}
                        >
                          <ListItemIcon>
                            <DeleteOutlined />
                          </ListItemIcon>
                          <ListItemText primary='Delete' />
                        </MenuItem>
                      )}
                      {invoice.approvalPending && userPermissions.includes('update:approval-invoice') && (
                        <MenuItem
                          onClick={() => {
                            if (missingKeys.length !== 0) {
                              setSnackbarOpen({
                                open: true,
                                message: `Cannot approve this invoice -> Booking is missing required fields: ${missingKeys
                                  .join(', ')
                                  .toUpperCase()}`,
                                color: 'warning',
                              });
                              return;
                            }
                            booking.bookingType === 'Export' && invoiceType === 'salesInvoices'
                              ? setOpenCustomModel(true)
                              : setOpenConfirmApprovalDialogue(true);
                            handleMenuClose();
                          }}
                        >
                          <ListItemIcon>
                            <AssignmentTurnedInOutlinedIcon />
                          </ListItemIcon>
                          <ListItemText primary='Approve' />
                        </MenuItem>
                      )}
                      {!invoice.approvalPending && (
                        <MenuItem
                          onClick={() => {
                            invoiceSentStatus(invoice._id);
                            handleMenuClose();
                          }}
                          disabled={invoice.invoiceSent}
                        >
                          <ListItemIcon>
                            <SendOutlined />
                          </ListItemIcon>
                          <ListItemText primary={`${currentInvoice.invoiceSent ? 'Marked' : 'Mark'} as sent`} />
                        </MenuItem>
                      )}
                      <MenuItem
                        onClick={() => {
                          setOpenAuditLogs(true);
                          handleMenuClose();
                        }}
                      >
                        <ListItemIcon>
                          <RecentActorsOutlined />
                        </ListItemIcon>
                        <ListItemText primary='Audit Logs' />
                      </MenuItem>
                    </Menu>
                  </>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      <InvoiceViewer
        handleClose={handleCloseInvoice}
        open={openInvoice}
        invoice={currentInvoice}
        invoiceType={invoiceType}
        pdfView={invoiceConfirmationElement.element}
      />
      <UpdateInvoiceDialogue
        handleClose={handleCloseUpdate}
        open={openUpdate}
        invoice={currentInvoice}
        invoiceType={invoiceType}
      />
      <AuditLogsDialogue
        handleClose={() => setOpenAuditLogs(false)}
        open={openAuditLogs}
        auditLogs={invoicesAuditLogs}
      />
      <ConfirmationDialog
        open={openConfirmDeletionDialogue}
        handleClose={() => setOpenConfirmDeletionDialogue(false)}
        onConfirm={() => {
          deleteInvoice(currentInvoice._id);
          setOpenConfirmDeletionDialogue(false);
        }}
        title={'Delete Invoice'}
        content={`Are you sure you want to delete this invoice?`}
      />
      <ConfirmationDialog
        open={openConfirmApprovalDialogue}
        handleClose={() => setOpenConfirmApprovalDialogue(false)}
        onConfirm={async () => {
          await updateApproval()();
          setOpenConfirmApprovalDialogue(false);
        }}
        title={'Approve Invoice'}
        content={`Are you sure you want to approve this invoice?`}
      />

      <CustomModel
        open={openCustomModel}
        onClose={() => setOpenCustomModel(false)}
        children={
          <HorizontalStepper
            title='Invoice Confirmation'
            steps={[emailFormElement, invoiceConfirmationElement]}
            onSubmit={updateApproval(true)}
          />
        }
      />
      <AlertSnackbar
        open={snackbarOpen.open}
        handleClose={() => setSnackbarOpen(defaultSnackbarState)}
        severity={snackbarOpen.color}
        message={snackbarOpen.message}
      />
    </Box>
  );
};

export default InvoiceTable;
