import { useTheme } from '@mui/system';
import React, { useState } from 'react';
import { PieChart, Pie, Sector, ResponsiveContainer } from 'recharts';

interface PieChartData {
  carrier: string;
  volume: number;
}

interface CustomPieChartProps {
  data: PieChartData[];
  color: string;
  type: 'Export' | 'Import';
}

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 5) * cos; // Shortened the line length by adjusting outerRadius
  const sy = cy + (outerRadius + 5) * sin;
  const mx = cx + (outerRadius + 10) * cos; // Reduced length of line segment
  const my = cy + (outerRadius + 10) * sin;
  const ex = mx + (cos >= 0 ? 8 : -8); // Reduced extension length
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';
  const theme = useTheme();

  return (
    <g>
      <text
        x={cx}
        y={cy - outerRadius - 35} // Increased space between carrier text and pie chart
        textAnchor='middle'
        fill={theme.palette.mode === 'dark' ? '#fff' : '#000'}
        style={{ fontSize: '10px' }}
      >
        {payload.carrier}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 4} // Adjusted slightly for shorter lines
        outerRadius={outerRadius + 8}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill='none' />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
      <text
        x={ex + (cos >= 0 ? 8 : -8)} // Added space between percentage text and the line
        y={ey}
        textAnchor={textAnchor} // Center the percentage text
        fill={theme.palette.mode === 'dark' ? '#fff' : '#000'}
        style={{ fontSize: '10px' }} // Smaller font size for percentage text
      >
        {`${(percent * 100).toFixed(2)}%`}
      </text>
    </g>
  );
};

const CustomPieChart: React.FC<CustomPieChartProps> = ({ data, color, type }) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const theme = useTheme();

  const onPieEnter = (_: any, index: number) => {
    setActiveIndex(index);
  };

  return (
    <ResponsiveContainer width='100%' height='100%'>
      <PieChart
        margin={{
          top: 30,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      >
        <text
          x='50%'
          y='57%'
          textAnchor='middle'
          fill={theme.palette.mode === 'dark' ? '#fff' : '#000'}
          style={{ fontSize: '10px' }}
          dominantBaseline='middle'
        >
          {type}
        </text>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          stroke='none'
          data={data}
          cx='50%'
          cy='50%'
          innerRadius={40}
          outerRadius={60}
          paddingAngle={2}
          fill={color}
          dataKey='volume'
          onMouseEnter={onPieEnter}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default CustomPieChart;
