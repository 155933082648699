import { zodResolver } from '@hookform/resolvers/zod';
import { Autocomplete, Box, Dialog, DialogContent, DialogTitle, TextField } from '@mui/material';
import axios from 'axios';
import AlertSnackbar from 'components/AlertSnackbar';
import GeneralDialogueActions from 'components/GeneralDialogueActions';
import { BaseDialogueProps, Port } from 'models/index.model';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useGetPortsQuery } from 'state/api';
import { uniqueNameRefinement } from 'utils/utils';
import { z } from 'zod';
import { sec } from '../../auth/accessToken';
import countryList from '../../dataAssets/countryList';

export interface PortDialogueProps extends BaseDialogueProps {
  portToUpdate?: Port;
}

const CreatePortDialogue: React.FC<PortDialogueProps> = ({ handleClose, open, portToUpdate }) => {
  const isEditing = !!portToUpdate?._id;

  const { data: ports } = useGetPortsQuery({});

  const portCreationSchema = z.object({
    portName: z
      .string()
      .min(1, { message: 'Name is required' })
      .refine(uniqueNameRefinement(ports?.masterData, portToUpdate, 'portName'), {
        message: 'A port with that name already exists',
      }),
    country: z.string().min(1, { message: 'Country is required' }),
    portCode: z.string().min(1, { message: 'Port Code is required' }).toUpperCase(),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<Port>({
    resolver: zodResolver(portCreationSchema),
  });

  useEffect(() => {
    reset(portToUpdate);
  }, [portToUpdate, reset]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleSnackbarClose = (event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const onSubmit = async (data: Port) => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();
      let apiUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/ports/`;

      if (isEditing) {
        apiUrl += `${portToUpdate?._id}`;
        await axios.patch(apiUrl, data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      } else {
        await axios.post(apiUrl, data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      }

      setSnackbarOpen(true);
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };

  const actionType = isEditing ? 'Edit' : 'Create';

  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()}>
        <DialogTitle
          sx={{
            fontSize: '1.6rem',
          }}
        >
          {actionType} Port
        </DialogTitle>
        <DialogContent>
          <form>
            <Box display={'grid'} gridTemplateColumns={'1fr 1fr 1fr'} gap={'0.5rem'}>
              <TextField
                label='Port Name *'
                autoComplete='off'
                variant='filled'
                {...register('portName')}
                error={!!errors.portName}
                helperText={errors.portName?.message}
              />
              <Controller
                name='country'
                control={control}
                defaultValue='United Kingdom'
                render={({ field: { value, onChange } }) => (
                  <Autocomplete
                    options={countryList}
                    value={value}
                    onChange={(_, newValue) => {
                      onChange(newValue);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label='Country *'
                        autoComplete='off'
                        variant='filled'
                        error={!!errors.country}
                        helperText={errors.country?.message}
                      />
                    )}
                  />
                )}
              />
              <TextField
                label='Port Code *'
                autoComplete='off'
                variant='filled'
                {...register('portCode')}
                error={!!errors.portCode}
                helperText={errors.portCode?.message}
              />
            </Box>
          </form>
        </DialogContent>
        <GeneralDialogueActions onClick={handleSubmit(onSubmit)} handleClose={handleClose} submitText={actionType} />
      </Dialog>
      <AlertSnackbar
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        severity={'success'}
        message={isEditing ? 'Port Updated' : 'Port Created'}
      />
    </Box>
  );
};

export default CreatePortDialogue;
