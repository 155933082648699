import { ColorPartial } from '@mui/material/styles/createPalette';
import { createBreakpoints, Breakpoint } from '@mui/system';

const fontFamily = 'Rubik, sans-serif';
const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1300,
    xl: 1700,
  },
});

// color design tokens export
export const tokensDark: Record<string, ColorPartial> = {
  grey: {
    0: '#ffffff', // manually adjusted
    10: '#f6f6f6', // manually adjusted
    50: '#f0f0f0', // manually adjusted
    100: '#e0e0e0',
    200: '#c2c2c2',
    300: '#a3a3a3',
    400: '#858585',
    500: '#666666',
    600: '#525252',
    700: '#3d3d3d',
    800: '#292929',
    900: '#141414',
    1000: '#202124',
    1100: '#000000', // manually adjusted
  },
  primary: {
    // blue
    100: '#d3d4de',
    200: '#a6a9be',
    300: '#7a7f9d',
    400: '#4d547d',
    500: '#21295c',
    600: '#191F45', // manually adjusted
    700: '#141937',
    800: '#0d1025',
    900: '#070812',
  },
  secondary: {
    100: '#ccdceb',
    200: '#99b9d6',
    300: '#6696c2',
    400: '#3373ad',
    500: '#005099',
    600: '#00407a',
    700: '#00305c',
    800: '#00203d',
    900: '#00101f',
  },
  // secondary: {
  //   // yellow
  //   50: "#f0f0f0", // manually adjusted
  //   100: "#fff6e0",
  //   200: "#ffedc2",
  //   300: "#ffe3a3",
  //   400: "#ffda85",
  //   500: "#ffd166",
  //   600: "#cca752",
  //   700: "#997d3d",
  //   800: "#665429",
  //   900: "#332a14",
  // },
};

const responsiveFontSize = (breakpoint: Breakpoint) => {
  const fontSizeMap = {
    xs: {
      base: 9,
      h1: 26,
      h2: 28,
      h3: 12,
      h4: 8,
      h5: 5,
      h6: 3,
    },
    sm: {
      base: 10,
      h1: 30,
      h2: 22,
      h3: 16,
      h4: 12,
      h5: 9,
      h6: 7,
    },
    md: {
      base: 11,
      h1: 34,
      h2: 26,
      h3: 20,
      h4: 16,
      h5: 13,
      h6: 11,
    },
    lg: {
      base: 11,
      h1: 38,
      h2: 30,
      h3: 22,
      h4: 18,
      h5: 14,
      h6: 12,
    },
    xl: {
      base: 11,
      h1: 40,
      h2: 32,
      h3: 24,
      h4: 20,
      h5: 16,
      h6: 14,
    },
  };

  return fontSizeMap[breakpoint];
};

// function that reverses the color palette
function reverseTokens(tokensDark: Record<string, ColorPartial>) {
  const reversedTokens: Record<string, ColorPartial> = {};
  Object.entries(tokensDark).forEach(([key, val]) => {
    const keys = Object.keys(val);
    const values = Object.values(val);
    const length = keys.length;
    const reversedObj: any = {}; //todo: fix typing
    for (let i = 0; i < length; i++) {
      reversedObj[keys[i]] = values[length - i - 1];
    }
    reversedTokens[key] = reversedObj;
  });
  return reversedTokens;
}
export const tokensLight = reverseTokens(tokensDark);

// mui theme settings
// todo: type this wiht PaletteOptions (need to adjust type first though)
export const themeSettings = (mode: string) => {
  return {
    palette: {
      mode: mode,
      ...(mode === 'dark'
        ? {
            // palette values for dark mode
            primary: {
              ...tokensDark.primary,
              main: tokensDark.primary[400],
              light: tokensDark.primary[400],
            },
            secondary: {
              ...tokensDark.secondary,
              main: tokensDark.secondary[300],
            },
            neutral: {
              ...tokensDark.grey,
              main: tokensDark.grey[500],
            },
            background: {
              default: tokensDark.grey[1000],
              alt: tokensDark.grey[800],
            },
          }
        : {
            // palette values for light mode
            primary: {
              ...tokensDark.primary,
              main: tokensDark.primary[400],
              light: tokensDark.primary[400],
            },
            secondary: {
              ...tokensDark.secondary,
              main: tokensDark.secondary[300],
              light: tokensDark.secondary[300],
            },
            neutral: {
              ...tokensDark.grey,
              main: tokensDark.grey[500],
            },
            background: {
              default: tokensDark.grey[50],
              alt: tokensDark.grey[50],
            },
          }),
    },
    typography: {
      fontFamily,
      // Base font size responsive configuration
      fontSize: responsiveFontSize('md').base,

      // Responsive heading sizes
      h1: {
        fontFamily,
        fontSize: responsiveFontSize('md').h1,
        [`@media (max-width:${breakpoints.values.sm}px)`]: {
          fontSize: responsiveFontSize('xs').h1,
        },
        [`@media (min-width:${breakpoints.values.md}px) and (max-width:${breakpoints.values.lg}px)`]: {
          fontSize: responsiveFontSize('md').h1,
        },
        [`@media (min-width:${breakpoints.values.lg}px) and (max-width:${breakpoints.values.xl}px)`]: {
          fontSize: responsiveFontSize('lg').h1,
        },
        [`@media (min-width:${breakpoints.values.xl}px)`]: {
          fontSize: responsiveFontSize('xl').h1,
        },
      },
      h2: {
        fontFamily,
        fontSize: responsiveFontSize('md').h2,
        [`@media (max-width:${breakpoints.values.sm}px)`]: {
          fontSize: responsiveFontSize('xs').h2,
        },
        [`@media (min-width:${breakpoints.values.md}px) and (max-width:${breakpoints.values.lg}px)`]: {
          fontSize: responsiveFontSize('md').h2,
        },
        [`@media (min-width:${breakpoints.values.lg}px) and (max-width:${breakpoints.values.xl}px)`]: {
          fontSize: responsiveFontSize('lg').h2,
        },
        [`@media (min-width:${breakpoints.values.xl}px)`]: {
          fontSize: responsiveFontSize('xl').h2,
        },
      },
      h3: {
        fontFamily,
        fontSize: responsiveFontSize('md').h3,
        [`@media (max-width:${breakpoints.values.sm}px)`]: {
          fontSize: responsiveFontSize('xs').h3,
        },
        [`@media (min-width:${breakpoints.values.md}px) and (max-width:${breakpoints.values.lg}px)`]: {
          fontSize: responsiveFontSize('md').h3,
        },
        [`@media (min-width:${breakpoints.values.lg}px) and (max-width:${breakpoints.values.xl}px)`]: {
          fontSize: responsiveFontSize('lg').h3,
        },
        [`@media (min-width:${breakpoints.values.xl}px)`]: {
          fontSize: responsiveFontSize('xl').h3,
        },
      },
      h4: {
        fontFamily,
        fontSize: responsiveFontSize('md').h4,
        [`@media (max-width:${breakpoints.values.sm}px)`]: {
          fontSize: responsiveFontSize('xs').h4,
        },
        [`@media (min-width:${breakpoints.values.md}px) and (max-width:${breakpoints.values.lg}px)`]: {
          fontSize: responsiveFontSize('md').h4,
        },
        [`@media (min-width:${breakpoints.values.lg}px) and (max-width:${breakpoints.values.xl}px)`]: {
          fontSize: responsiveFontSize('lg').h4,
        },
        [`@media (min-width:${breakpoints.values.xl}px)`]: {
          fontSize: responsiveFontSize('xl').h4,
        },
      },
      h5: {
        fontFamily,
        fontSize: responsiveFontSize('md').h5,
        [`@media (max-width:${breakpoints.values.sm}px)`]: {
          fontSize: responsiveFontSize('xs').h5,
        },
        [`@media (min-width:${breakpoints.values.md}px) and (max-width:${breakpoints.values.lg}px)`]: {
          fontSize: responsiveFontSize('md').h5,
        },
        [`@media (min-width:${breakpoints.values.lg}px) and (max-width:${breakpoints.values.xl}px)`]: {
          fontSize: responsiveFontSize('lg').h5,
        },
        [`@media (min-width:${breakpoints.values.xl}px)`]: {
          fontSize: responsiveFontSize('xl').h5,
        },
      },
      h6: {
        fontFamily,
        fontSize: responsiveFontSize('md').h6,
        [`@media (max-width:${breakpoints.values.sm}px)`]: {
          fontSize: responsiveFontSize('xs').h6,
        },
        [`@media (min-width:${breakpoints.values.md}px) and (max-width:${breakpoints.values.lg}px)`]: {
          fontSize: responsiveFontSize('md').h6,
        },
        [`@media (min-width:${breakpoints.values.lg}px) and (max-width:${breakpoints.values.xl}px)`]: {
          fontSize: responsiveFontSize('lg').h6,
        },
        [`@media (min-width:${breakpoints.values.xl}px)`]: {
          fontSize: responsiveFontSize('xl').h6,
        },
      },
    },
    breakpoints: breakpoints,
  };
};
