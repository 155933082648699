import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Controller, UseFormReturn, useFieldArray } from 'react-hook-form';
import {
  Box,
  useTheme,
  TextField,
  Autocomplete,
  Divider,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Tooltip,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { useGetCustomersQuery, useGetUserPreferenceQuery } from '../../state/api';
import { useGetPortsQuery } from '../../state/api';
import { useGetVendorsQuery } from '../../state/api';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  DEFAULT_BOOKING,
  bookingTypes,
  bases,
  loadingTypes,
  blTypes,
  containerTypes,
  currencies,
  doorFacingOptions,
  exportBookingTeams,
  importBookingTeams,
  incoterms,
} from '../../dataAssets/constants';
import { Booking, Charge, Invoice } from 'models/booking.model';
import { Enquiry, Vendor, VendorType } from 'models/index.model';
import { generateMenuItemsFromArray } from 'utils/generateMenuItemsFromArray';
import { getValueAndLabelFromPort, getValueAndLabelFromCustomer, getValueAndLabelFromVendor } from 'utils/utils';
import { Customer, Port } from 'models/index.model';
import { BaseIsolatedReRender } from 'utils/renderBase';
import vesselFlags from 'dataAssets/vesselFlags';
import { EditOutlined, RefreshOutlined } from '@mui/icons-material';
import axios from 'axios';
import { useSelector } from 'react-redux';
import ChargeNameAutocomplete from 'components/ChargeNameAutocomplete';
import { Hbl } from 'models/hbl.model';

export interface BookingDialogueContentProps {
  useFormReference: UseFormReturn<Booking>;
  hasExportPermission: boolean;
  hasImportPermission: boolean;
  bookingToUpdate?: Booking;
  disabledFields?: Set<string>;
}

const HSCodeMap: Record<string, string[]> = {};

const BookingDialogueContent: React.FC<BookingDialogueContentProps> = ({
  useFormReference,
  hasExportPermission,
  hasImportPermission,
  bookingToUpdate,
  disabledFields = new Set(),
}) => {
  const theme = useTheme();
  const isCreateBooking = !bookingToUpdate;
  const booking = bookingToUpdate ?? DEFAULT_BOOKING;
  const enquiryDataToBook = useSelector((state: any) => state.global.enquiryDataToBook);
  const userEmail = useSelector((state: any) => state.global.userEmail);
  const { data: userPreferenceArr } = useGetUserPreferenceQuery();
  const availableEmails = React.useMemo(() => {
    return userPreferenceArr?.masterData?.map(pref => pref.email) || [];
  }, [userPreferenceArr]);

  // zod validation not used in booking form - need to look into this
  const {
    register,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormReference;

  useEffect(() => {
    if (isCreateBooking && !enquiryDataToBook?._id) {
      setValue('bookingType', hasExportPermission || !hasImportPermission ? 'Export' : 'Import');
    }
  }, [hasExportPermission, hasImportPermission, isCreateBooking, setValue]);

  const watchedBookingType = watch('bookingType');
  const watchedETD = watch('etd');
  const watchedIsDangerous = watch('dangerousGoods.isDangerous');

  const {
    fields: sellFields,
    append: sellAppend,
    remove: sellRemove,
  } = useFieldArray({
    control,
    name: 'sellRates',
  });

  const {
    fields: buyFields,
    append: buyAppend,
    remove: buyRemove,
  } = useFieldArray({
    control,
    name: 'buyRates',
  });

  const { data: customers, isLoading: customersLoading, refetch: refetchCustomers } = useGetCustomersQuery({});
  const { data: ports, refetch: refetchPorts } = useGetPortsQuery({});
  const { data: vendors, refetch: refetchVendors } = useGetVendorsQuery({});

  const [HSCode, setHSCode] = useState<string[]>([]);
  const [openDangerousGoodsDialog, setOpenDangerousGoodsDialog] = useState(false);

  // todo: make portType enum
  const handlePortChange = (portType: string, value: { value: string; label: string } | null) => {
    if (!value) {
      return;
    }

    const newPort = ports?.masterData?.find(port => port._id === value.value);
    if (newPort) {
      setValue(portType as keyof Booking, newPort);
    }
  };

  const handleCustomerChange = (customerType: string, value: { value: string; label: string } | null) => {
    if (!value) {
      return;
    }

    const newCustomer = customers?.masterData?.find(customer => customer._id === value.value);
    if (newCustomer) {
      setValue(customerType as keyof Booking, newCustomer);
    }
  };

  const handleHSCodeChange = async (value: string | null) => {
    console.log('handle change is called with ' + value);
    if (!value) {
      setHSCode([]);
      return;
    }

    if (value.length < 2) {
      setHSCode([]);
      return;
    }
    const chapter = value.substring(0, 2);
    if (Array.isArray(HSCodeMap[chapter])) {
      setHSCode(HSCodeMap[chapter]);
      return;
    }
    const { data } = await axios.get(
      'https://www.trade-tariff.service.gov.uk/api/v2/goods_nomenclatures/chapter/' + chapter,
    );
    const newHSCodes = (
      (data?.data ?? []) as {
        type: string;
        attributes: { goods_nomenclature_item_id: string };
      }[]
    ).reduce((acc, v) => {
      if (v.type === 'goods_nomenclature') {
        acc.add(v.attributes.goods_nomenclature_item_id);
      }
      return acc;
    }, new Set<string>());
    HSCodeMap[chapter] = [...newHSCodes];
    setHSCode(HSCodeMap[chapter]);
  };

  const handleVendorChange = (vendorType: string, value: { value: string; label: string } | null) => {
    if (!value) {
      return;
    }

    const newVendor = vendors?.masterData?.find(vendor => vendor._id === value.value);
    if (newVendor) {
      setValue(vendorType as keyof Booking, newVendor);
    }
  };

  return (
    <Box padding={'1rem'}>
      <Box display='flex' justifyContent='space-between'>
        <Controller
          name='pic'
          control={control}
          defaultValue={userEmail}
          render={({ field }) => (
            <TextField {...field} select label='Person In Charge' autoComplete='off'>
              {generateMenuItemsFromArray(availableEmails)}
            </TextField>
          )}
        />
        <Tooltip title='Refresh Master Data'>
          <IconButton
            onClick={() => {
              refetchCustomers();
              refetchPorts();
              refetchVendors();
            }}
          >
            <RefreshOutlined />
          </IconButton>
        </Tooltip>
      </Box>
      <Divider>Customer Details</Divider>
      {customers?.masterData || customersLoading ? (
        <Box display='grid' gridTemplateColumns='repeat(3, 1fr)' gap='1rem' m='1rem 0'>
          <Controller
            control={control}
            name={'consignor'}
            rules={{ required: 'Consignor is required' }}
            render={({ field: { value } }) => (
              <Autocomplete
                disabled={disabledFields.has('consignor')}
                value={value ? getValueAndLabelFromCustomer(value) : null}
                onChange={(_, value) => {
                  handleCustomerChange('consignor', value);
                }}
                disablePortal
                options={(customers?.masterData ?? []).map((customer: Customer) =>
                  getValueAndLabelFromCustomer(customer),
                )}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderInput={params => (
                  <TextField
                    {...params}
                    label='Consignor *'
                    error={!!errors.consignor}
                    helperText={errors.consignor?.message}
                  />
                )}
              />
            )}
          />
          <Controller
            control={control}
            name={'consignee'}
            render={({ field: { value, onChange } }) => (
              <Autocomplete
                disabled={disabledFields.has('consignee')}
                value={value ? getValueAndLabelFromCustomer(value) : null}
                onChange={(_, value) => {
                  if (value) {
                    handleCustomerChange('consignee', value);
                  } else {
                    onChange(undefined);
                  }
                }}
                disablePortal
                options={(customers?.masterData ?? []).map((customer: Customer) =>
                  getValueAndLabelFromCustomer(customer),
                )}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderInput={params => <TextField {...params} label='Consignee' />}
              />
            )}
          />
          <Controller
            control={control}
            name={'notifyParty'}
            render={({ field: { value } }) => (
              <Autocomplete
                disabled={disabledFields.has('notifyParty')}
                value={value ? getValueAndLabelFromCustomer(value) : null}
                onChange={(_, value) => {
                  handleCustomerChange('notifyParty', value);
                }}
                disablePortal
                options={(customers?.masterData ?? []).map((customer: Customer) =>
                  getValueAndLabelFromCustomer(customer),
                )}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderInput={params => <TextField {...params} label='Notify Party' />}
              />
            )}
          />
        </Box>
      ) : null}

      {isCreateBooking && (
        <>
          <Divider>Quotation Details</Divider>
          <Box>
            <Box
              m={'1rem 0'}
              overflow={'auto'}
              padding={'1rem'}
              border={'0.5px solid rgba(255, 255, 255, 0.12)'}
              borderRadius={'11px'}
            >
              <Box display={'flex'} justifyContent={'space-between'}>
                <Typography variant='h5'>Buy Charges</Typography>
                <Button
                  variant='contained'
                  type='button'
                  onClick={() => buyAppend({} as Charge)}
                  sx={{
                    bgcolor: theme.palette.secondary[400],
                    '&:hover': {
                      backgroundColor: theme.palette.secondary[500],
                    },
                  }}
                >
                  Add
                </Button>
              </Box>
              <Box m={'1rem 0'}>
                {buyFields.map((item, index) => {
                  return (
                    <Box
                      display='grid'
                      gridTemplateColumns={'35fr 35fr 7fr 10fr 10fr 3fr'}
                      alignItems={'center'}
                      gap={'1rem'}
                      mt={'1rem'}
                      key={item.id}
                    >
                      <ChargeNameAutocomplete
                        control={control}
                        name={`buyRates.${index}.chargeName`}
                        chargeType='Purchase'
                      />
                      <TextField
                        label='Additional Details'
                        autoComplete='off'
                        {...register(`buyRates.${index}.additionalDetails`)}
                      />
                      <Controller
                        name={`buyRates.${index}.currency`}
                        control={control}
                        defaultValue={currencies[0]}
                        render={({ field }) => (
                          <TextField {...field} select autoComplete='off' label='Currency *'>
                            {generateMenuItemsFromArray(currencies)}
                          </TextField>
                        )}
                      />
                      <TextField
                        autoComplete='off'
                        type='number'
                        label='Value *'
                        {...register(`buyRates.${index}.rate`, {
                          required: 'Value is required',
                        })}
                        error={!!errors?.buyRates?.[index]?.rate}
                        helperText={errors?.buyRates?.[index]?.rate?.message}
                      />
                      <BaseIsolatedReRender
                        form={
                          useFormReference as UseFormReturn<
                            Invoice | Booking | Enquiry | Hbl,
                            any,
                            Invoice | Booking | Enquiry | Hbl
                          >
                        }
                        index={index}
                        bases={bases}
                        formPath='buyRates'
                      />
                      <Button
                        variant='contained'
                        type='button'
                        onClick={() => buyRemove(index)}
                        sx={{
                          bgcolor: theme.palette.secondary[400],
                          '&:hover': {
                            backgroundColor: theme.palette.secondary[500],
                          },
                        }}
                      >
                        Delete
                      </Button>
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box
              m={'1rem 0'}
              overflow={'auto'}
              padding={'1rem'}
              border={'0.5px solid rgba(255, 255, 255, 0.12)'}
              borderRadius={'11px'}
            >
              <Box display={'flex'} justifyContent={'space-between'}>
                <Typography variant='h5'>Sale Charges</Typography>
                <Button
                  variant='contained'
                  type='button'
                  onClick={() => sellAppend({} as Charge)}
                  sx={{
                    bgcolor: theme.palette.secondary[400],
                    '&:hover': {
                      backgroundColor: theme.palette.secondary[500],
                    },
                  }}
                >
                  Add
                </Button>
              </Box>
              <Box m={'1rem 0'}>
                {sellFields.map((item, index) => {
                  return (
                    <Box
                      display='grid'
                      gridTemplateColumns={'35fr 35fr 7fr 10fr 10fr 3fr'}
                      alignItems={'center'}
                      gap={'1rem'}
                      mt={'1rem'}
                      key={item.id}
                    >
                      <ChargeNameAutocomplete
                        control={control}
                        name={`sellRates.${index}.chargeName`}
                        chargeType='Sales'
                      />
                      <TextField
                        label='Additional Details'
                        autoComplete='off'
                        {...register(`sellRates.${index}.additionalDetails`)}
                      />
                      <Controller
                        name={`sellRates.${index}.currency`}
                        control={control}
                        defaultValue={currencies[0]}
                        render={({ field }) => (
                          <TextField {...field} select autoComplete='off' label='Currency *'>
                            {generateMenuItemsFromArray(currencies)}
                          </TextField>
                        )}
                      />
                      <TextField
                        autoComplete='off'
                        type='number'
                        label='Value *'
                        {...register(`sellRates.${index}.rate`, {
                          required: 'Value is required',
                        })}
                        error={!!errors?.sellRates?.[index]?.rate}
                        helperText={errors?.sellRates?.[index]?.rate?.message}
                      />
                      <BaseIsolatedReRender
                        form={
                          useFormReference as UseFormReturn<
                            Invoice | Booking | Enquiry | Hbl,
                            any,
                            Invoice | Booking | Enquiry | Hbl
                          >
                        }
                        index={index}
                        bases={bases}
                        formPath='sellRates'
                      />
                      <Button
                        variant='contained'
                        type='button'
                        onClick={() => sellRemove(index)}
                        sx={{
                          bgcolor: theme.palette.secondary[400],
                          '&:hover': {
                            backgroundColor: theme.palette.secondary[500],
                          },
                        }}
                      >
                        Delete
                      </Button>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </>
      )}
      <Divider
        sx={{
          marginBottom: '1rem',
        }}
      />
      <Box display={'flex'} gap={'1rem'} flexDirection={'column'}>
        <Box display={'flex'} gap={'1.5rem'} alignItems={'center'}>
          {isCreateBooking && hasExportPermission && hasImportPermission && (
            <>
              <FormControl component='fieldset'>
                <FormLabel component='legend'>Booking Type</FormLabel>
                <Controller
                  name='bookingType'
                  control={control}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      {bookingTypes.map(bookingType => (
                        <FormControlLabel
                          key={bookingType}
                          value={bookingType}
                          control={<Radio />}
                          label={bookingType}
                        />
                      ))}
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </>
          )}
          {isCreateBooking && (
            <Controller
              name='bookingTeam'
              control={control}
              render={({ field }) => (
                <TextField {...field} sx={{ width: '10rem' }} variant='standard' select label='Team *'>
                  {generateMenuItemsFromArray(
                    watchedBookingType === 'Export' ? exportBookingTeams : importBookingTeams,
                  )}
                </TextField>
              )}
            />
          )}
        </Box>
        <Box display={'flex'} gap={'1.5rem'} alignItems={'center'}>
          <Controller
            name='dangerousGoods.isDangerous'
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    {...field}
                    checked={field.value || false}
                    onChange={e => {
                      const checked = e.target.checked;
                      field.onChange(checked);
                    }}
                  />
                }
                label='Dangerous Goods'
              />
            )}
          />
          {watchedIsDangerous && (
            <Button
              variant='contained'
              onClick={() => setOpenDangerousGoodsDialog(true)}
              sx={{
                bgcolor: theme.palette.secondary[400],
                '&:hover': {
                  backgroundColor: theme.palette.secondary[500],
                },
              }}
              startIcon={<EditOutlined />}
            >
              Dangerous Goods Details
            </Button>
          )}
        </Box>
      </Box>
      <Divider>Shipment Details</Divider>
      <Box display='grid' gridTemplateColumns='repeat(3, 1fr)' gap='1rem' m='1rem 0'>
        <Controller
          control={control}
          name={'carrier'}
          rules={{ required: 'Carrier is required' }}
          render={({ field: { value } }) => (
            <Autocomplete
              disabled={disabledFields.has('carrier')}
              value={value ? getValueAndLabelFromVendor(value) : null}
              onChange={(_, value) => {
                handleVendorChange('carrier', value);
              }}
              disablePortal
              options={(vendors?.masterData ?? [])
                .filter((vendor: Vendor) => vendor.vendorType === VendorType.Carrier)
                .map((vendor: Vendor) => getValueAndLabelFromVendor(vendor))}
              isOptionEqualToValue={(option, value) => option.label === value.label}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Carrier *'
                  error={!!errors.carrier}
                  helperText={errors.carrier?.message}
                />
              )}
            />
          )}
        />
        <TextField
          label='Carrier Quotation Number'
          disabled={disabledFields.has('carrierQuotationReference')}
          autoComplete='off'
          {...register('carrierQuotationReference')}
        />
        <TextField
          label='Carrier Booking Number'
          disabled={disabledFields.has('carrierBookingNumber')}
          autoComplete='off'
          {...register('carrierBookingNumber')}
        />
        <Controller
          control={control}
          name={'agent'}
          render={({ field: { value, onChange } }) => (
            <Autocomplete
              disablePortal
              disabled={disabledFields.has('agent')}
              value={value ? getValueAndLabelFromVendor(value) : null}
              onChange={(_, value) => {
                if (value) {
                  handleVendorChange('agent', value);
                } else {
                  onChange(undefined);
                }
              }}
              options={(vendors?.masterData ?? [])
                .filter((vendor: Vendor) => vendor.vendorType === VendorType.Agent)
                .map((vendor: Vendor) => getValueAndLabelFromVendor(vendor))}
              isOptionEqualToValue={(option, value) => option.label === value.label}
              renderInput={params => <TextField {...params} label='Agent' />}
            />
          )}
        />
        <Box display={'grid'} gridTemplateColumns={'1fr 1fr'} gap={'1rem'}>
          <TextField
            label='MBL Number'
            autoComplete='off'
            disabled={disabledFields.has('mblNumber')}
            {...register('mblNumber')}
          />
          <FormControl component='fieldset'>
            <FormLabel component='legend'>MBL Status</FormLabel>
            <Controller
              name='mblStatus'
              control={control}
              render={({ field }) => (
                <RadioGroup row {...field}>
                  {blTypes.map(blType => (
                    <FormControlLabel
                      key={blType}
                      value={blType}
                      control={<Radio />}
                      label={blType}
                      disabled={disabledFields.has('mblStatus')}
                    />
                  ))}
                </RadioGroup>
              )}
            />
          </FormControl>
        </Box>
        {hasImportPermission && watchedBookingType === 'Import' && (
          <Box display={'grid'} gridTemplateColumns={'1fr 1fr'} gap={'1rem'}>
            <TextField
              label='HBL Number'
              autoComplete='off'
              disabled={disabledFields.has('hblNumber')}
              {...register('hblNumber')}
            />
            <FormControl component='fieldset'>
              <FormLabel component='legend'>HBL Status</FormLabel>
              <Controller
                name='hblStatus'
                control={control}
                render={({ field }) => (
                  <RadioGroup row {...field}>
                    {blTypes.map(blType => (
                      <FormControlLabel
                        key={blType}
                        value={blType}
                        control={<Radio />}
                        label={blType}
                        disabled={disabledFields.has('hblStatus')}
                      />
                    ))}
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Box>
        )}
        <TextField
          label='Purchase Order Number'
          autoComplete='off'
          disabled={disabledFields.has('purchaseOrderNumber')}
          {...register('purchaseOrderNumber')}
        />
      </Box>
      <Box display='grid' gridTemplateColumns='repeat(3, 1fr)' gap='1rem' m='1rem 0'>
        <Controller
          control={control}
          name={'portOfLoading'}
          rules={{ required: 'Port of Loading is required' }}
          render={({ field: { value } }) => (
            <Autocomplete
              value={value ? getValueAndLabelFromPort(value) : null}
              disabled={disabledFields.has('portOfLoading')}
              onChange={(_, value) => {
                handlePortChange('portOfLoading', value);
              }}
              disablePortal
              options={(ports?.masterData ?? []).map((port: Port) => getValueAndLabelFromPort(port))}
              isOptionEqualToValue={(option, value) => option.label === value.label}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Port of Loading *'
                  error={!!errors.portOfLoading}
                  helperText={errors.portOfLoading?.message}
                />
              )}
            />
          )}
        />

        <Controller
          control={control}
          name={'portOfDestination'}
          rules={{ required: 'Port of Destination is required' }}
          render={({ field: { value } }) => (
            <Autocomplete
              value={value ? getValueAndLabelFromPort(value) : null}
              disabled={disabledFields.has('portOfDestination')}
              disablePortal
              options={(ports?.masterData ?? []).map((port: Port) => getValueAndLabelFromPort(port))}
              isOptionEqualToValue={(option, value) => option.label === value.label}
              onChange={(_, value) => {
                handlePortChange('portOfDestination', value);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Port of Destination *'
                  error={!!errors.portOfDestination}
                  helperText={errors.portOfDestination?.message}
                />
              )}
            />
          )}
        />

        <Controller
          control={control}
          name={'placeOfDelivery'}
          rules={{ required: 'Place of Delivery is required' }}
          render={({ field: { value } }) => (
            <Autocomplete
              value={value ? getValueAndLabelFromPort(value) : null}
              disabled={disabledFields.has('placeOfDelivery')}
              disablePortal
              options={(ports?.masterData ?? []).map((port: Port) => getValueAndLabelFromPort(port))}
              isOptionEqualToValue={(option, value) => option.label === value.label}
              onChange={(_, value) => {
                handlePortChange('placeOfDelivery', value);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Place of Delivery *'
                  error={!!errors.placeOfDelivery}
                  helperText={errors.placeOfDelivery?.message}
                />
              )}
            />
          )}
        />

        <TextField
          label='No. of Containers *'
          disabled={disabledFields.has('numberOfContainers')}
          type='number'
          autoComplete='off'
          {...register('numberOfContainers', {
            required: 'No. of Containers is required',
          })}
          error={!!errors.numberOfContainers}
          helperText={errors.numberOfContainers?.message}
        />
        <TextField
          label='Approximate Weight *'
          disabled={disabledFields.has('approximateWeight')}
          type='number'
          autoComplete='off'
          {...register('approximateWeight', {
            required: 'Approximate Weight is required',
          })}
          error={!!errors.approximateWeight}
          helperText={errors.approximateWeight?.message}
          InputProps={{
            endAdornment: <InputAdornment position='end'>KG</InputAdornment>,
          }}
        />
        <TextField
          label='Packages'
          disabled={disabledFields.has('packages')}
          type='number'
          autoComplete='off'
          {...register('packages')}
        />
        {hasImportPermission && watchedBookingType === 'Import' && (
          <TextField
            label='Release Pin'
            autoComplete='off'
            disabled={disabledFields.has('releasePin')}
            {...register('releasePin')}
          />
        )}
        <Box display={'grid'} gridTemplateColumns={'1fr 1fr 1fr'}>
          <FormControl component='fieldset'>
            <FormLabel component='legend'>Export Loading Type</FormLabel>
            <Controller
              name='loadingTypeExport'
              control={control}
              render={({ field }) => (
                <RadioGroup row {...field}>
                  {loadingTypes.map(loadingType => (
                    <FormControlLabel
                      key={loadingType}
                      value={loadingType}
                      control={<Radio />}
                      label={loadingType}
                      disabled={disabledFields.has('loadingTypeExport')}
                    />
                  ))}
                </RadioGroup>
              )}
            />
          </FormControl>
          <FormControl component='fieldset'>
            <FormLabel component='legend'>Import Loading Type</FormLabel>
            <Controller
              name='loadingTypeImport'
              control={control}
              render={({ field }) => (
                <RadioGroup row {...field}>
                  {loadingTypes.map(loadingType => (
                    <FormControlLabel
                      key={loadingType}
                      value={loadingType}
                      control={<Radio />}
                      label={loadingType}
                      disabled={disabledFields.has('loadingTypeImport')}
                    />
                  ))}
                </RadioGroup>
              )}
            />
          </FormControl>
          <FormControl component='fieldset'>
            <FormLabel component='legend'>Door Facing</FormLabel>
            <Controller
              name='doorFacing'
              control={control}
              render={({ field }) => (
                <RadioGroup row {...field}>
                  {doorFacingOptions.map(doorFacing => (
                    <FormControlLabel
                      key={doorFacing}
                      value={doorFacing}
                      control={<Radio />}
                      label={doorFacing}
                      disabled={disabledFields.has('doorFacing')}
                    />
                  ))}
                </RadioGroup>
              )}
            />
          </FormControl>
        </Box>
        <Controller
          name='containerType'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              select
              label='Container Type *'
              disabled={disabledFields.has('containerType')}
              autoComplete='off'
            >
              {generateMenuItemsFromArray(containerTypes)}
            </TextField>
          )}
        />

        <Controller
          name='incoterm'
          control={control}
          render={({ field }) => (
            <TextField {...field} select label='Incoterm' autoComplete='off'>
              {generateMenuItemsFromArray(incoterms)}
            </TextField>
          )}
        />
      </Box>
      <Box display='grid' gridTemplateColumns='repeat(3, 1fr)' gap='1rem' m='1rem 0'>
        <TextField
          label='Cargo Description *'
          autoComplete='off'
          {...register('cargoDescription', {
            required: 'Cargo Description is required',
          })}
          disabled={disabledFields.has('cargoDescription')}
          error={!!errors.cargoDescription}
          helperText={errors.cargoDescription?.message}
        />
        <Controller
          control={control}
          name={'HSCode'}
          render={({ field: { value, onChange } }) => (
            <Autocomplete
              disabled={disabledFields.has('HSCode')}
              value={value ?? null}
              disablePortal
              options={HSCode}
              onChange={(_, value) => onChange(value)}
              isOptionEqualToValue={(option, value) => option === value}
              renderInput={params => (
                <TextField
                  {...params}
                  label='HSCode'
                  error={!!errors.HSCode}
                  onChange={value => {
                    handleHSCodeChange(value.target.value);
                  }}
                  helperText={errors.HSCode?.message}
                />
              )}
            />
          )}
        />
        <Box display={'grid'} gridTemplateColumns={'3fr 9fr'} gap={'1rem'}>
          <Controller
            name='cargoValue.currency'
            control={control}
            render={({ field }) => (
              <TextField {...field} select label='Currency' disabled={disabledFields.has('cargoValue')}>
                {generateMenuItemsFromArray(currencies)}
              </TextField>
            )}
          />
          <TextField
            label='Cargo Value'
            type='number'
            autoComplete='off'
            disabled={disabledFields.has('cargoValue')}
            {...register('cargoValue.value')}
          />
        </Box>
      </Box>
      <Box display='grid' gridTemplateColumns='repeat(3, 1fr)' gap='1rem' m='1rem 0'>
        <TextField
          label='Vessel Name *'
          disabled={disabledFields.has('vesselVoyage')}
          autoComplete='off'
          {...register('vesselVoyage.vesselName', {
            required: 'Vessel Name is required',
          })}
          error={!!errors.vesselVoyage?.vesselName}
          helperText={errors.vesselVoyage?.vesselName?.message}
        />
        <TextField
          label='Voyage Number *'
          disabled={disabledFields.has('vesselVoyage')}
          autoComplete='off'
          {...register('vesselVoyage.voyageNumber', {
            required: 'Voyage Number is required',
          })}
          error={!!errors.vesselVoyage?.voyageNumber}
          helperText={errors.vesselVoyage?.voyageNumber?.message}
        />
        <Controller
          name='vesselVoyage.vesselFlag'
          control={control}
          rules={{ required: 'Vessel Flag is required' }}
          render={({ field: { value, onChange } }) => (
            <Autocomplete
              disablePortal
              value={value ?? null}
              onChange={(_, value) => onChange(value)}
              options={vesselFlags}
              isOptionEqualToValue={(option, value) => option === value}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Vessel Flag *'
                  disabled={disabledFields.has('vesselVoyage')}
                  error={!!errors.vesselVoyage?.vesselFlag}
                  helperText={errors.vesselVoyage?.vesselFlag?.message}
                />
              )}
            />
          )}
        />
      </Box>
      <Box display='grid' gridTemplateColumns='repeat(3, 1fr)' gap='1rem' m='1rem 0'>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Controller
            control={control}
            name={'etaPOL'}
            rules={{
              required: 'ETA (POL) is required',
              validate: (value, { etd, eta }) => {
                if (dayjs(value).isAfter(dayjs(etd))) {
                  return 'ETA (POL) cannot be after ETD';
                }
                if (dayjs(value).isAfter(dayjs(eta))) {
                  return 'ETA (POL) cannot be after ETA (POD)';
                }
                return true;
              },
            }}
            render={({ field: { onChange, value, ref } }) => (
              <DatePicker
                format='DD/MM/YYYY'
                label={'ETA (POL) *'}
                value={value ? dayjs(value) : booking.etaPOL ? dayjs(booking.etaPOL) : null}
                disabled={disabledFields.has('etaPOL')}
                inputRef={ref}
                onChange={date => onChange(dayjs(date).format())}
                maxDate={watchedETD ? dayjs(watchedETD) : booking.etd ? dayjs(booking.etd) : null}
                slotProps={{
                  textField: {
                    error: !!errors.etaPOL,
                    helperText: errors.etaPOL?.message,
                  },
                }}
              />
            )}
          />
          <Controller
            control={control}
            name={'etd'}
            rules={{ required: 'ETD is required' }}
            render={({ field: { onChange, value, ref } }) => (
              <DatePicker
                format='DD/MM/YYYY'
                label={'ETD *'}
                value={value ? dayjs(value) : booking.etd ? dayjs(booking.etd) : null}
                disabled={disabledFields.has('etd')}
                inputRef={ref}
                onChange={date => onChange(dayjs(date).format())}
                minDate={dayjs()}
                slotProps={{
                  textField: {
                    error: !!errors.etd,
                    helperText: errors.etd?.message,
                  },
                }}
              />
            )}
          />
          <Controller
            control={control}
            name={'eta'}
            rules={{
              required: 'ETA is required',
              validate: (value, { etd }) => {
                if (dayjs(value).isBefore(dayjs(etd))) {
                  return 'ETA (POD) cannot be before ETD';
                }
                return true;
              },
            }}
            render={({ field: { onChange, value, ref } }) => (
              <DatePicker
                format='DD/MM/YYYY'
                label={'ETA (POD) *'}
                value={value ? dayjs(value) : booking.eta ? dayjs(booking.eta) : null}
                disabled={disabledFields.has('eta')}
                inputRef={ref}
                onChange={date => onChange(dayjs(date).format())}
                minDate={watchedETD ? dayjs(watchedETD) : booking.etd ? dayjs(booking.etd) : dayjs()}
                slotProps={{
                  textField: {
                    error: !!errors.eta,
                    helperText: errors.eta?.message,
                  },
                }}
              />
            )}
          />
          <Controller
            control={control}
            name={'actualEtaPOL'}
            render={({ field: { onChange, value, ref } }) => (
              <DatePicker
                format='DD/MM/YYYY'
                label={'Actual ETA (POL)'}
                value={value ? dayjs(value) : booking.actualEtaPOL ? dayjs(booking.actualEtaPOL) : null}
                disabled={disabledFields.has('actualEtaPOL')}
                inputRef={ref}
                onChange={date => onChange(dayjs(date).format())}
              />
            )}
          />
          <Controller
            control={control}
            name={'actualEtd'}
            render={({ field: { onChange, value, ref } }) => (
              <DatePicker
                format='DD/MM/YYYY'
                label={'Actual ETD'}
                value={value ? dayjs(value) : booking.actualEtd ? dayjs(booking.actualEtd) : null}
                disabled={disabledFields.has('actualEtd')}
                inputRef={ref}
                onChange={date => onChange(dayjs(date).format())}
              />
            )}
          />
          <Controller
            control={control}
            name={'actualEtaPOD'}
            render={({ field: { onChange, value, ref } }) => (
              <DatePicker
                format='DD/MM/YYYY'
                label={'Actual ETA (POD)'}
                value={value ? dayjs(value) : booking.actualEtaPOD ? dayjs(booking.actualEtaPOD) : null}
                disabled={disabledFields.has('actualEtaPOD')}
                inputRef={ref}
                onChange={date => onChange(dayjs(date).format())}
              />
            )}
          />
        </LocalizationProvider>
      </Box>
      <Dialog
        open={openDangerousGoodsDialog}
        onClose={() => setOpenDangerousGoodsDialog(false)}
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle>Dangerous Goods Details</DialogTitle>
        <DialogContent>
          <Controller
            name='dangerousGoods.unNumber'
            control={control}
            render={({ field }) => (
              <TextField {...field} label='UN Number' fullWidth margin='normal' autoComplete='off' />
            )}
          />
          <Controller
            name='dangerousGoods.class'
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                freeSolo
                options={['1', '2', '3', '4', '5', '6', '7', '8', '9']}
                getOptionLabel={option => option}
                onChange={(_, newValue) => field.onChange(newValue)}
                renderInput={params => (
                  <TextField {...params} label='Class' fullWidth margin='normal' autoComplete='off' />
                )}
              />
            )}
          />
          <Controller
            name='dangerousGoods.packingGroup'
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                freeSolo
                options={['I', 'II', 'III']}
                getOptionLabel={option => option}
                onChange={(_, newValue) => field.onChange(newValue)}
                renderInput={params => (
                  <TextField {...params} label='Packing Group' fullWidth margin='normal' autoComplete='off' />
                )}
              />
            )}
          />
          <Controller
            name='dangerousGoods.flashPoint'
            control={control}
            render={({ field }) => (
              <TextField {...field} label='Flash Point' fullWidth margin='normal' autoComplete='off' />
            )}
          />
          <Controller
            name='dangerousGoods.ems'
            control={control}
            render={({ field }) => <TextField {...field} label='EMS' fullWidth margin='normal' autoComplete='off' />}
          />
          <Controller
            name='dangerousGoods.marinePollutant'
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                freeSolo
                options={['Yes', 'No']}
                getOptionLabel={option => option}
                onChange={(_, newValue) => field.onChange(newValue)}
                renderInput={params => (
                  <TextField {...params} label='Marine Pollutant' fullWidth margin='normal' autoComplete='off' />
                )}
              />
            )}
          />

          <Controller
            name='dangerousGoods.subsidiaryRisk'
            control={control}
            render={({ field }) => (
              <TextField {...field} label='Subsidiary Risk' fullWidth margin='normal' autoComplete='off' />
            )}
          />
          <Controller
            name='dangerousGoods.specialProvision'
            control={control}
            render={({ field }) => (
              <TextField {...field} label='Special Provision' fullWidth margin='normal' autoComplete='off' />
            )}
          />
          <Controller
            name='dangerousGoods.hazardCode'
            control={control}
            render={({ field }) => (
              <TextField {...field} label='Hazard Code' fullWidth margin='normal' autoComplete='off' />
            )}
          />
          <Controller
            name='dangerousGoods.state'
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                freeSolo
                options={['Solid', 'Liquid', 'Gas']}
                getOptionLabel={option => option}
                onChange={(_, newValue) => field.onChange(newValue)}
                renderInput={params => (
                  <TextField {...params} label='State' fullWidth margin='normal' autoComplete='off' />
                )}
              />
            )}
          />
          <Controller
            name='dangerousGoods.emergencyContact'
            control={control}
            render={({ field }) => (
              <TextField {...field} label='Emergency Contact' fullWidth margin='normal' autoComplete='off' />
            )}
          />
          <Controller
            name='dangerousGoods.emergencyContactNumber'
            control={control}
            render={({ field }) => (
              <TextField {...field} label='Emergency Contact Number' fullWidth margin='normal' autoComplete='off' />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDangerousGoodsDialog(false)}>Cancel</Button>
          <Button onClick={() => setOpenDangerousGoodsDialog(false)} variant='contained' color='primary'>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default BookingDialogueContent;
