import React, { useState } from 'react';
import axios from 'axios';
import { UseFormReturn } from 'react-hook-form';
import { Box, Dialog, DialogTitle, DialogContent, useTheme, Button } from '@mui/material';
import { sec } from '../../auth/accessToken';
import { BaseDialogueProps } from 'models/index.model';
import { Hbl, HblSellRates } from 'models/hbl.model';
import HblDocumentDialogueContent from 'components/HblDocumentDialogueContent';
import GeneralDialogueActions from 'components/GeneralDialogueActions';
import AlertSnackbar from 'components/AlertSnackbar';
import { AlertColor } from '@mui/material/Alert';
import { checkHblMissingKeys, handleSnackbarClose, useUserPermissions } from 'utils/utils';
import ConfirmationDialog from 'components/ConfirmationDialog';

export interface HblDocumentDialogueProps extends BaseDialogueProps {
  bookingId: string;
  hblDetails: Hbl | undefined;
  defaultSellRates: HblSellRates[];
  hblDataRefetch: Function;
  bookingDataRefetch: Function;
  useFormReference: UseFormReturn<Hbl>;
}

const HblDocumentDialogue: React.FC<HblDocumentDialogueProps> = ({
  handleClose,
  open,
  bookingId,
  hblDetails,
  defaultSellRates,
  hblDataRefetch,
  bookingDataRefetch,
  useFormReference,
}) => {
  const theme = useTheme();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('success');
  const [openConfirmApprovalDialogue, setOpenConfirmApprovalDialogue] = useState(false);
  const userPermissions = useUserPermissions();

  const { handleSubmit, getValues } = useFormReference;

  const handleApprove = () => {
    const hblFormData = getValues();
    const missingKeys = checkHblMissingKeys(hblFormData);
    if (missingKeys.length !== 0) {
      setSnackbarMessage(
        `Cannot approve this HBL -> HBL is missing required fields: ${missingKeys.join(', ').toUpperCase()}`,
      );
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }
    setOpenConfirmApprovalDialogue(true);
  };

  const approveHbl = async () => {
    const accessToken = await sec.getAccessTokenSilently()();
    const data = { hblApproval: true };

    try {
      await axios.patch(`${process.env.REACT_APP_BASE_URL}/api/v1/hbl/approveHbl/${hblDetails?._id}`, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setSnackbarMessage('HBL Details Approved Successfully !');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      hblDataRefetch();
      bookingDataRefetch();
      handleClose();
    } catch (err) {
      console.log(err);
      setSnackbarMessage('Failed While Approving HBL Details !');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const onSubmit = async (data: Hbl) => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();

      if (hblDetails?._id) {
        await axios.patch(`${process.env.REACT_APP_BASE_URL}/api/v1/hbl/${hblDetails?._id}`, data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      } else {
        await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/v1/hbl`,
          { hblData: data, bookingId: bookingId },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
      }
      hblDetails?._id
        ? setSnackbarMessage('HBL Details Updated Successfully !')
        : setSnackbarMessage('HBL Details Added Successfully !');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      hblDetails?._id && hblDataRefetch();
      bookingDataRefetch();
      handleClose();
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status === 400) {
        const errorMessage = err.response?.data?.message || 'An error occurred';
        setSnackbarMessage(errorMessage);
        setSnackbarSeverity('error');
      } else {
        setSnackbarMessage('An unexpected error occurred');
        setSnackbarSeverity('error');
      }
      setSnackbarOpen(true);
    }
  };

  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth='xl'>
        <DialogTitle
          sx={{
            fontSize: '1.6rem',
          }}
        >
          {hblDetails?._id ? 'Edit HBL Details' : 'Add HBL Details'}
        </DialogTitle>
        <DialogContent>
          <HblDocumentDialogueContent
            hblDetails={hblDetails}
            useFormReference={useFormReference}
            defaultSellRates={defaultSellRates}
          />
          <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'} gap={2} marginX={1}>
            <>
              {hblDetails?._id && userPermissions.includes('update:approval-hbl') && (
                <Button
                  onClick={handleApprove}
                  sx={{
                    color: theme.palette.primary[700],
                    bgcolor: theme.palette.secondary[200],
                    '&:hover': { bgcolor: theme.palette.secondary[300] },
                  }}
                >
                  Approve
                </Button>
              )}
            </>
            <GeneralDialogueActions
              onClick={handleSubmit(onSubmit)}
              handleClose={handleClose}
              submitText={hblDetails?._id ? 'Edit' : 'Submit'}
            />
          </Box>
        </DialogContent>
      </Dialog>
      <ConfirmationDialog
        open={openConfirmApprovalDialogue}
        handleClose={() => setOpenConfirmApprovalDialogue(false)}
        onConfirm={async () => {
          await approveHbl();
          setOpenConfirmApprovalDialogue(false);
        }}
        title={'Approve HBL'}
        content={`Are you sure you want to approve this HBL?`}
      />
      <AlertSnackbar
        open={snackbarOpen}
        handleClose={(_: any, reason: string) => handleSnackbarClose(reason, setSnackbarOpen)}
        severity={snackbarSeverity}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default HblDocumentDialogue;
