import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useForm, useFieldArray, Controller, UseFormReturn } from 'react-hook-form';
import { Box, Button, useTheme, TextField, Dialog, DialogTitle, DialogContent, Typography } from '@mui/material';
import { sec } from '../../auth/accessToken';
import { currencies, bases } from '../../dataAssets/constants';
import { generateMenuItemsFromArray } from '../../utils/generateMenuItemsFromArray';
import { BaseDialogueProps, Enquiry } from 'models/index.model';
import { Booking, Charge, Invoice } from 'models/booking.model';
import AlertSnackbar from 'components/AlertSnackbar';
import GeneralDialogueActions from 'components/GeneralDialogueActions';
import { BaseIsolatedReRender } from 'utils/renderBase';
import { useGetAuditsQuery, useGetExpectedProfitQuery } from 'state/api';
import ChargeNameAutocomplete from 'components/ChargeNameAutocomplete';
import { Hbl, PaymentType } from 'models/hbl.model';

export interface QuotationDialogueProps extends BaseDialogueProps {
  id: string;
  buyRates: Charge[];
  sellRates: Charge[];
}

const QuotationDialogue: React.FC<QuotationDialogueProps> = ({ handleClose, open, id, buyRates, sellRates }) => {
  const theme = useTheme();

  const { refetch: auditLogsRefetch } = useGetAuditsQuery(['Booking', id]);
  const { refetch: expectedProfitRefetch } = useGetExpectedProfitQuery(id);
  // default value is empty array
  const currentForm = useForm<Booking>({
    defaultValues: {
      buyRates: [],
      sellRates: [],
    },
  });

  // set the buyrates and sellRates when the dialogue opens
  useEffect(() => {
    if (open) {
      currentForm.reset({
        buyRates: buyRates,
        sellRates: sellRates,
      });
    }
  }, [buyRates, sellRates, open, currentForm]);

  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = currentForm;

  const {
    fields: buyFields,
    append: buyAppend,
    remove: buyRemove,
  } = useFieldArray({
    control,
    name: 'buyRates',
  });

  const {
    fields: sellFields,
    append: sellAppend,
    remove: sellRemove,
  } = useFieldArray({
    control,
    name: 'sellRates',
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleSnackbarClose = (event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const onSubmit = async (data: { buyRates: Charge[]; sellRates: Charge[] }) => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();

      await axios.patch(`${process.env.REACT_APP_BASE_URL}/api/v1/bookings/${id}`, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const booking = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/bookings/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (booking?.data?.generatedHblId) {
        const hbl = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/hbl/${booking?.data?.generatedHblId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const updatedSellRates = [];
        for (let i = 0; i < data.sellRates.length; i++) {
          if (hbl.data.sellRates[i]) {
            hbl.data.sellRates[i] = {
              ...data.sellRates[i],
              paymentType: hbl.data.sellRates[i].paymentType,
              exchangeRate: hbl.data.sellRates[i].exchangeRate,
            };
          } else {
            hbl.data.sellRates[i] = { ...data.sellRates[i], paymentType: PaymentType.Prepaid };
          }
          updatedSellRates.push(hbl.data.sellRates[i]);
        }

        if (hbl?.data?.hblApproval === false) {
          await axios.patch(
            `${process.env.REACT_APP_BASE_URL}/api/v1/hbl/${hbl?.data?._id}`,
            { sellRates: updatedSellRates },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            },
          );
        }
      }

      handleClose();
      setSnackbarOpen(true);
      auditLogsRefetch();
      expectedProfitRefetch();
    } catch (err) {
      console.log(err);
    }
  };
  // onCancel reset the state of the form
  const onCancel = () => {
    reset({
      buyRates: [],
      sellRates: [],
    });
    handleClose();
  };

  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth='xl'>
        <DialogTitle
          sx={{
            fontSize: '1.6rem',
          }}
        >
          {'Update Quotation'}
        </DialogTitle>
        <DialogContent>
          <form>
            <Box
              m={'1rem 0'}
              overflow={'auto'}
              padding={'1rem'}
              border={
                theme.palette.mode === 'dark'
                  ? '0.5px solid rgba(255, 255, 255, 0.12)'
                  : '0.5px solid rgba(0, 0, 0, 0.12)'
              }
              borderRadius={'11px'}
            >
              <Box display={'flex'} justifyContent={'space-between'} id='buy-charges-container'>
                <Typography variant='h5'>Buy Charges</Typography>
                <Button
                  variant='contained'
                  type='button'
                  onClick={() => buyAppend({} as Charge)}
                  sx={{
                    bgcolor: theme.palette.secondary[400],
                    '&:hover': {
                      backgroundColor: theme.palette.secondary[500],
                    },
                  }}
                >
                  Add
                </Button>
              </Box>
              <Box m={'1rem 0'}>
                {buyFields.map((item, index) => {
                  return (
                    <Box
                      display='grid'
                      gridTemplateColumns={'35fr 35fr 7fr 10fr 10fr 3fr'}
                      alignItems={'center'}
                      gap={'1rem'}
                      mt={'1rem'}
                      key={item.id}
                    >
                      <ChargeNameAutocomplete
                        control={control}
                        name={`buyRates.${index}.chargeName`}
                        chargeType='Purchase'
                      />
                      <TextField
                        label='Additional Details'
                        autoComplete='off'
                        {...register(`buyRates.${index}.additionalDetails`)}
                      />
                      <Controller
                        name={`buyRates.${index}.currency`}
                        control={control}
                        defaultValue={currencies[0]}
                        render={({ field }) => (
                          <TextField {...field} select autoComplete='off' label='Currency *'>
                            {generateMenuItemsFromArray(currencies)}
                          </TextField>
                        )}
                      />
                      <TextField
                        autoComplete='off'
                        type='number'
                        label='Value *'
                        defaultValue={0}
                        {...register(`buyRates.${index}.rate`, {
                          required: 'Value is required',
                        })}
                        error={!!errors?.buyRates?.[index]?.rate}
                        helperText={errors?.buyRates?.[index]?.rate?.message}
                      />
                      <BaseIsolatedReRender
                        form={
                          currentForm as UseFormReturn<
                            Invoice | Booking | Enquiry | Hbl,
                            any,
                            Invoice | Booking | Enquiry | Hbl
                          >
                        }
                        index={index}
                        bases={bases}
                        formPath='buyRates'
                      />
                      <Button
                        variant='contained'
                        type='button'
                        onClick={() => buyRemove(index)}
                        sx={{
                          bgcolor: theme.palette.secondary[400],
                          '&:hover': {
                            backgroundColor: theme.palette.secondary[500],
                          },
                        }}
                      >
                        Delete
                      </Button>
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box
              m={'1rem 0'}
              overflow={'auto'}
              padding={'1rem'}
              border={
                theme.palette.mode === 'dark'
                  ? '0.5px solid rgba(255, 255, 255, 0.12)'
                  : '0.5px solid rgba(0, 0, 0, 0.12)'
              }
              borderRadius={'11px'}
            >
              <Box display={'flex'} justifyContent={'space-between'} id='sell-charges-container'>
                <Typography variant='h5'>Sell Charges</Typography>
                <Button
                  variant='contained'
                  type='button'
                  onClick={() => sellAppend({} as Charge)}
                  sx={{
                    bgcolor: theme.palette.secondary[400],
                    '&:hover': {
                      backgroundColor: theme.palette.secondary[500],
                    },
                  }}
                >
                  Add
                </Button>
              </Box>
              <Box m={'1rem 0'}>
                {sellFields.map((item, index) => {
                  return (
                    <Box
                      display='grid'
                      gridTemplateColumns={'35fr 35fr 7fr 10fr 10fr 3fr'}
                      alignItems={'center'}
                      gap={'1rem'}
                      mt={'1rem'}
                      key={item.id}
                    >
                      <ChargeNameAutocomplete
                        control={control}
                        name={`sellRates.${index}.chargeName`}
                        chargeType='Sales'
                      />
                      <TextField
                        label='Additional Details'
                        autoComplete='off'
                        {...register(`sellRates.${index}.additionalDetails`)}
                      />
                      <Controller
                        name={`sellRates.${index}.currency`}
                        control={control}
                        defaultValue={currencies[0]}
                        render={({ field }) => (
                          <TextField {...field} select autoComplete='off' label='Currency *'>
                            {generateMenuItemsFromArray(currencies)}
                          </TextField>
                        )}
                      />
                      <TextField
                        autoComplete='off'
                        type='number'
                        label='Value *'
                        defaultValue={0}
                        {...register(`sellRates.${index}.rate`, {
                          required: 'Value is required',
                        })}
                        error={!!errors?.sellRates?.[index]?.rate}
                        helperText={errors?.sellRates?.[index]?.rate?.message}
                      />
                      <BaseIsolatedReRender
                        form={
                          currentForm as UseFormReturn<
                            Invoice | Booking | Enquiry | Hbl,
                            any,
                            Invoice | Booking | Enquiry | Hbl
                          >
                        }
                        index={index}
                        bases={bases}
                        formPath='sellRates'
                      />
                      <Button
                        variant='contained'
                        type='button'
                        onClick={() => sellRemove(index)}
                        sx={{
                          bgcolor: theme.palette.secondary[400],
                          '&:hover': {
                            backgroundColor: theme.palette.secondary[500],
                          },
                        }}
                      >
                        Delete
                      </Button>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </form>
        </DialogContent>{' '}
        <GeneralDialogueActions onClick={handleSubmit(onSubmit)} handleClose={onCancel} submitText='Update' />
      </Dialog>
      <AlertSnackbar
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        severity={'success'}
        message={'Quotation Updated Successfully !'}
      />
    </Box>
  );
};

export default QuotationDialogue;
