import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';
import { CargoDetails, Hbl, HblSellRates } from 'models/hbl.model';
import dayjs from 'dayjs';
// @ts-expect-error: TODO: Fix typing issue
import FontRubikRegular from '../../assets/fonts/Rubik-Regular.ttf';
// @ts-expect-error: TODO: Fix typing issue
import FontRubikMedium from '../../assets/fonts/Rubik-Medium.ttf';
// @ts-expect-error: TODO: Fix typing issue
import FontRubikSemiBold from '../../assets/fonts/Rubik-SemiBold.ttf';
// @ts-expect-error: TODO: Fix typing issue
import FontRubikBold from '../../assets/fonts/Rubik-Bold.ttf';
// @ts-expect-error: TODO: Fix typing issue
import ReudanLogo from '../../assets/REUDAN LOGO.png';

Font.register({
  family: 'Rubik',
  fonts: [
    { src: FontRubikRegular },
    { src: FontRubikMedium, fontWeight: 'medium' },
    { src: FontRubikSemiBold, fontWeight: 'semibold' },
    { src: FontRubikBold, fontWeight: 'bold' },
  ],
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 315,
    paddingBottom: 175,
    paddingHorizontal: 15,
    fontFamily: 'Rubik',
    fontSize: 7,
    position: 'relative',
  },
  sectionHeading: {
    fontSize: 7,
    marginVertical: 6,
  },
  watermark: {
    position: 'absolute',
    transform: 'translate(-100%, 100%)',
    opacity: 0.2,
    fontSize: 30,
    fontWeight: 'bold',
    color: 'gray',
    pointerEvents: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '800px',
  },
  watermarkText: {
    transform: 'rotate(-45deg)',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  fixed: {
    bottom: 30,
    left: 0,
    right: 0,
    paddingHorizontal: 15,
    position: 'absolute',
  },
  topFixed: {
    top: 10,
    left: 0,
    right: 0,
    paddingHorizontal: 15,
    position: 'absolute',
  },
  page: {
    position: 'relative',
    minHeight: '300px',
    overflow: 'hidden',
  },
  tableContainer: {
    position: 'absolute',
    top: 603,
    left: 0,
    right: 0,
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    height: 'auto',
  },
  column: {
    flex: 1,
    borderRight: '1px solid black',
    textAlign: 'center',
    fontSize: 8,
    paddingVertical: 4,
    paddingHorizontal: 2,
  },
  title: {
    fontSize: 7,
    marginVertical: 2,
    textAlign: 'center',
    paddingLeft: 3,
  },
  cell: {
    textTransform: 'uppercase',
    paddingVertical: 5,
    paddingHorizontal: 3,
    textAlign: 'center',
    marginBottom: 2,
  },
  cargoDetailsContainer: {
    width: '100%',
  },
  clauseContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    width: '100%',
  },
  clauseColumn: {
    width: '50%',
    paddingHorizontal: 10,
    paddingVertical: 2,
  },
  clauseText: {
    marginBottom: 4,
  },
  pageNumbers: {
    position: 'absolute',
    right: 20,
    bottom: 0,
    textAlign: 'center',
    fontSize: 8,
  },
});

export interface HblDocumentProps {
  hbl: Hbl | undefined;
  copyVersion?: Boolean;
}

interface HblDetailsProp {
  hbl: Hbl | undefined;
  checkAndTruncate: Function;
}

interface CargoDetailsProps {
  hbl: Hbl | undefined;
  totalGrossWeight: number;
  totalTareWeight: number;
  totalVolume: number;
  checkAndTruncate: Function;
}

interface TotalWeightsComponentProps {
  totalGrossWeight: number;
  totalTareWeight: number;
  totalVolume: number;
}

interface ChargesComponentProps {
  charges: HblSellRates[] | undefined;
}

interface FooterComponentProps {
  hbl: Hbl | undefined;
  bottomText: string | undefined;
  numberOfOriginalBls: string | undefined;
}
interface ExceededLimit {
  key: string;
  value: string;
}

const ShipmentDetailsComponent = ({ hbl, checkAndTruncate }: HblDetailsProp) => {
  return (
    <>
      <View
        style={{
          width: '50%',
          borderRight: '1px solid black',
          height: '280px',
        }}
      >
        <View style={{ marginBottom: 2, height: '33%' }}>
          <Text style={styles.sectionHeading}>Shipper</Text>
          <Text
            style={{
              textTransform: 'uppercase',
              fontSize: 8.5,
              marginTop: 3,
            }}
          >
            {hbl?.shipper?.name && checkAndTruncate(hbl?.shipper?.name, 110)}
          </Text>
          <Text
            style={{
              textTransform: 'uppercase',
              fontSize: 8.5,
            }}
          >
            {hbl?.shipper?.address && checkAndTruncate(hbl?.shipper?.address, 110)}
          </Text>
          <Text
            style={{
              textTransform: 'uppercase',
              fontSize: 8.5,
            }}
          >
            {hbl?.shipper?.city && checkAndTruncate(hbl?.shipper?.city, 25)}{' '}
            {hbl?.shipper?.postcode && checkAndTruncate(hbl?.shipper?.postcode, 25)}
          </Text>
          <Text
            style={{
              textTransform: 'uppercase',
              fontSize: 8.5,
            }}
          >
            {hbl?.shipper?.country && checkAndTruncate(hbl?.shipper?.country, 50)}
          </Text>
        </View>
        <View
          style={{
            borderTop: '1px solid black',
            marginBottom: 2,
            height: '33%',
          }}
        >
          <Text style={styles.sectionHeading}>Consignee (If "To order" so indicate)</Text>
          <Text
            style={{
              textTransform: 'uppercase',
              fontSize: 8.5,
              marginTop: 3,
            }}
          >
            {hbl?.consignee?.name && checkAndTruncate(hbl?.consignee?.name, 110)}
          </Text>
          <Text
            style={{
              textTransform: 'uppercase',
              fontSize: 8.5,
            }}
          >
            {hbl?.consignee?.address && checkAndTruncate(hbl?.consignee?.address, 110)}
          </Text>
          <Text
            style={{
              textTransform: 'uppercase',
              fontSize: 8.5,
            }}
          >
            {hbl?.consignee?.city && checkAndTruncate(hbl?.consignee?.city, 25)}{' '}
            {hbl?.consignee?.postcode && checkAndTruncate(hbl?.consignee?.postcode, 25)}
          </Text>
          <Text
            style={{
              textTransform: 'uppercase',
              fontSize: 8.5,
            }}
          >
            {hbl?.consignee?.country && checkAndTruncate(hbl?.consignee?.country, 50)}
          </Text>
        </View>
        <View
          style={{
            borderTop: '1px solid black',
            borderBottom: '1px solid black',
            height: '34%',
          }}
        >
          <Text style={styles.sectionHeading}>Notify Party (no claim shall attach for failure to notify)</Text>
          <Text
            style={{
              textTransform: 'uppercase',
              fontSize: 8.5,
              marginTop: 3,
            }}
          >
            {hbl?.notifyParty?.name && checkAndTruncate(hbl?.notifyParty?.name, 110)}
          </Text>
          <Text
            style={{
              textTransform: 'uppercase',
              fontSize: 8.5,
            }}
          >
            {hbl?.notifyParty?.address && checkAndTruncate(hbl?.notifyParty?.address, 110)}
          </Text>
          <Text
            style={{
              textTransform: 'uppercase',
              fontSize: 8.5,
            }}
          >
            {hbl?.notifyParty?.city && checkAndTruncate(hbl?.notifyParty?.city, 25)}{' '}
            {hbl?.notifyParty?.postcode && checkAndTruncate(hbl?.notifyParty?.postcode, 25)}
          </Text>
          <Text
            style={{
              textTransform: 'uppercase',
              fontSize: 8.5,
            }}
          >
            {hbl?.notifyParty?.country && checkAndTruncate(hbl?.notifyParty?.country, 50)}
          </Text>
        </View>
      </View>
    </>
  );
};

const CargoDetailsComponent = ({ hbl, checkAndTruncate }: HblDetailsProp) => {
  return (
    <>
      <View
        style={{
          width: '50%',
          borderRight: '1px solid black',
          display: 'flex',
          flexDirection: 'row',
          height: '90px',
        }}
      >
        <View
          style={{
            width: '50%',
            borderRight: '1px solid black',
          }}
        >
          <View
            style={{
              marginBottom: 2,
            }}
          >
            <Text style={styles.sectionHeading}>Pre-carriage by</Text>
            <Text
              style={{
                textTransform: 'uppercase',
                fontSize: 7,
                marginTop: -2,
              }}
            >
              {hbl?.preCarriageBy && checkAndTruncate(hbl?.preCarriageBy, 30)}
            </Text>
          </View>
          <View
            style={{
              borderTop: '1px solid black',
              marginBottom: 2,
            }}
          >
            <Text style={styles.sectionHeading}>Ocean Vessel</Text>
            <Text
              style={{
                textTransform: 'uppercase',
                fontSize: 7,
                marginTop: -2,
              }}
            >
              {hbl?.oceanVessel.vesselName && checkAndTruncate(hbl?.oceanVessel.vesselName, 10)}{' '}
              {hbl?.oceanVessel?.voyageNumber && checkAndTruncate(hbl?.oceanVessel?.voyageNumber, 10)}
            </Text>
          </View>
          <View
            style={{
              borderTop: '1px solid black',
            }}
          >
            <Text style={styles.sectionHeading}>Port of Discharge</Text>
            <Text
              style={{
                textTransform: 'uppercase',
                fontSize: 7,
                marginTop: -2,
              }}
            >
              {hbl?.portOfDischarge?.portName && checkAndTruncate(hbl?.portOfDischarge?.portName, 30)}
            </Text>
          </View>
        </View>
        <View
          style={{
            width: '50%',
          }}
        >
          <View
            style={{
              marginBottom: 2,
              paddingLeft: 3,
            }}
          >
            <Text style={{ ...styles.sectionHeading, marginTop: 6 }}>Place of Receipt</Text>
            <Text
              style={{
                textTransform: 'uppercase',
                fontSize: 7,
                marginTop: -2,
              }}
            >
              {hbl?.placeOfReceipt?.city && checkAndTruncate(hbl?.placeOfReceipt?.city, 30)}
            </Text>
          </View>
          <View
            style={{
              borderTop: '1px solid black',
              marginBottom: 2,
              paddingLeft: 3,
            }}
          >
            <Text style={styles.sectionHeading}>Port of Loading</Text>
            <Text
              style={{
                textTransform: 'uppercase',
                fontSize: 7,
                marginTop: -2,
              }}
            >
              {hbl?.portOfLoading?.portName && checkAndTruncate(hbl?.portOfLoading?.portName, 30)}
            </Text>
          </View>
          <View
            style={{
              borderTop: '1px solid black',
              paddingLeft: 3,
            }}
          >
            <Text style={styles.sectionHeading}>Place of Delivery</Text>
            <Text
              style={{
                textTransform: 'uppercase',
                fontSize: 7,
                marginTop: -2,
              }}
            >
              {hbl?.placeOfDelivery?.portName && checkAndTruncate(hbl?.placeOfDelivery?.portName, 30)}
            </Text>
          </View>
        </View>
      </View>
    </>
  );
};

const DeliveryContactDetailsComponent = ({ hbl, checkAndTruncate }: HblDetailsProp) => {
  return (
    <>
      <View
        style={{
          width: '50%',
          height: '90px',
          paddingLeft: 3,
        }}
      >
        <Text style={styles.sectionHeading}>For delivery please contact:</Text>
        <Text
          style={{
            fontSize: 8.5,
            marginTop: 3,
          }}
        >
          {hbl?.deliveryContactDetails?.name && checkAndTruncate(hbl?.deliveryContactDetails?.name, 110)}
        </Text>
        <Text
          style={{
            fontSize: 8.5,
          }}
        >
          {hbl?.deliveryContactDetails?.address && checkAndTruncate(hbl?.deliveryContactDetails?.address, 110)}
        </Text>
        <Text
          style={{
            fontSize: 8.5,
          }}
        >
          {hbl?.deliveryContactDetails?.city && checkAndTruncate(hbl?.deliveryContactDetails?.city, 25)}{' '}
          {hbl?.deliveryContactDetails?.postcode && checkAndTruncate(hbl?.deliveryContactDetails?.postcode, 25)}
        </Text>
        <Text
          style={{
            fontSize: 8.5,
          }}
        >
          {hbl?.deliveryContactDetails?.country && checkAndTruncate(hbl?.deliveryContactDetails?.country, 50)}{' '}
        </Text>
      </View>
    </>
  );
};

const CompanyDetailsComponent = ({ hbl, checkAndTruncate }: HblDetailsProp) => {
  return (
    <>
      <View
        style={{
          width: '50%',
          height: '280px',
          borderBottom: '1px solid black',
        }}
      >
        <View
          style={{
            paddingLeft: 3,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: 10,
            paddingVertical: 1,
          }}
        >
          <Text style={{ fontSize: 9, fontWeight: 'semibold', marginHorizontal: 2 }}>B/L Number</Text>
          <Text
            style={{
              fontSize: 9,
            }}
          >
            {hbl?.blNumber}
          </Text>
        </View>
        <View
          style={{
            borderTop: '1px solid black',
            marginBottom: 10.5,
            paddingLeft: 3,
          }}
        >
          <View
            style={{
              width: '100%',
              height: '80px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 4,
            }}
          >
            <Image src={ReudanLogo} />
          </View>
          <Text style={{ textAlign: 'center', fontSize: 16 }}>REUDAN INTERNATIONAL LTD</Text>
          <Text style={{ textAlign: 'center', fontSize: 8, fontWeight: 'medium', marginVertical: 1 }}>LONDON</Text>
          <Text style={{ textAlign: 'center', fontSize: 8, fontWeight: 'medium' }}>
            Company Registration No.11564481
          </Text>
          <Text style={{ textAlign: 'center', fontSize: 13, fontWeight: 'medium', marginTop: 3 }}>
            Ocean Bill of Lading
          </Text>
          <Text style={{ textAlign: 'center', fontSize: 7, marginTop: 3 }}>
            NOT NEGOTIABLE UNLESS CONSIGNED “TO ORDER”
          </Text>
          <Text style={{ fontSize: 6, paddingLeft: 10, marginTop: 2, textAlign: 'justify' }}>
            RECEIVED by the Carrier the Goods as specified below in apparent good order and condition unless otherwise
            stated to be transported to such place as agreed, authorised or permitted herein and subject to all terms
            and conditions appearing on the front and reverse of this Bill of Lading to which the Merchant agrees by
            accepting this Bill of Lading, any local privileges and customs notwithstanding.
          </Text>
          <Text style={{ fontSize: 6, paddingLeft: 10, marginTop: 4, textAlign: 'justify' }}>
            The Particulars given below as stated by the shipper and the weight, measure , quantity, condition, contents
            and the value of Goods are unknown for the Carrier.
          </Text>
          <Text style={{ fontSize: 6, paddingLeft: 10, marginTop: 4, textAlign: 'justify', marginBottom: 2 }}>
            In WITNESS whereof one (1) original Bill of Lading has been signed if not otherwise stated hereafter, the
            same being accomplished the other(s). if any, to be void. If required by the Carrier one (1) original Bill
            of Lading must be surrendered duly endorsed in exchange for the Goods or Delivery Order.
          </Text>
        </View>
      </View>
    </>
  );
};

const CargoDetailsTableComponent = ({
  hbl,
  totalGrossWeight,
  totalTareWeight,
  totalVolume,
  checkAndTruncate,
}: CargoDetailsProps) => {
  const cargoDetails = hbl?.cargoDetails ? [...hbl.cargoDetails] : [];

  if (hbl?.additionalDescription?.length) {
    cargoDetails.push({
      marks: '',
      numbers: '',
      numberOfPackages: '',
      kindOfPackages: '',
      grossWeightCargo: '',
      tareWeight: '',
      volume: '',
      descriptionOfGoodsAndPackages: hbl.additionalDescription,
    });
  }

  return (
    <>
      <View>
        <View style={styles.cargoDetailsContainer}>
          {/* Header Row */}
          <View style={styles.row}>
            <Text
              style={{
                ...styles.column,
                fontWeight: 'bold',
                flex: '12',
                borderTop: '1px solid black',
                borderBottom: '1px solid black',
              }}
            >
              Marks & No.
            </Text>
            <Text
              style={{
                ...styles.column,
                fontWeight: 'bold',
                flex: '15',
                borderTop: '1px solid black',
                borderBottom: '1px solid black',
              }}
            >
              No. & Kind of Pkgs
            </Text>
            <Text
              style={{
                ...styles.column,
                fontWeight: 'bold',
                flex: '50.5',
                borderTop: '1px solid black',
                borderBottom: '1px solid black',
              }}
            >
              Description of Goods & Packages
            </Text>
            <Text
              style={{
                ...styles.column,
                fontWeight: 'bold',
                flex: '8.34',
                borderTop: '1px solid black',
                borderBottom: '1px solid black',
              }}
            >
              Gross Wt. (Kgs)
            </Text>
            <Text
              style={{
                ...styles.column,
                fontWeight: 'bold',
                flex: '8.33',
                borderTop: '1px solid black',
                borderBottom: '1px solid black',
              }}
            >
              Tare Wt. (Kgs)
            </Text>
            <Text
              style={{
                ...styles.column,
                fontWeight: 'bold',
                borderRight: 'none',
                flex: '8.33',
                borderTop: '1px solid black',
                borderBottom: '1px solid black',
              }}
            >
              Vol. (CBM)
            </Text>
          </View>

          {/* Data Rows */}
          {cargoDetails.map((cargoDetail, i) => (
            <View key={i} style={styles.row}>
              <View style={{ ...styles.column, flex: '12', textAlign: 'left' }}>
                <Text>{cargoDetail.marks || ''}</Text>
                <Text>{cargoDetail.numbers || ''}</Text>
              </View>
              {cargoDetail.numberOfPackages && cargoDetail.kindOfPackages ? (
                <Text style={{ ...styles.column, flex: '15' }}>
                  {cargoDetail.numberOfPackages} X {cargoDetail.kindOfPackages}
                </Text>
              ) : (
                <Text style={{ ...styles.column, flex: '15' }}></Text>
              )}

              {cargoDetail?.descriptionOfGoodsAndPackages &&
                (i === cargoDetails.length - 1 ? (
                  <Text
                    style={{
                      ...styles.column,
                      textAlign: 'left',
                      paddingHorizontal: 10,
                      flex: '47.5',
                    }}
                  >
                    {cargoDetail.descriptionOfGoodsAndPackages}
                  </Text>
                ) : (
                  <Text
                    style={{
                      ...styles.column,
                      textAlign: 'left',
                      paddingHorizontal: 10,
                      flex: '47.5',
                    }}
                  >
                    {checkAndTruncate(cargoDetail.descriptionOfGoodsAndPackages, 62)}
                  </Text>
                ))}

              <Text style={{ ...styles.column, flex: '8.34' }}>{cargoDetail.grossWeightCargo || ''}</Text>
              <Text style={{ ...styles.column, flex: '8.33' }}>{cargoDetail.tareWeight || ''}</Text>
              <Text style={{ ...styles.column, borderRight: 'none', flex: '8.33' }}>{cargoDetail.volume || ''}</Text>
            </View>
          ))}
        </View>

        {/* Footer */}
        <>
          <TotalWeightsComponent
            totalGrossWeight={totalGrossWeight}
            totalTareWeight={totalTareWeight}
            totalVolume={totalVolume}
          />
          {hbl?.sellRates && hbl?.sellRates?.length > 0 && <ChargesTableComponent charges={hbl?.sellRates} />}
        </>
      </View>
    </>
  );
};

const TotalWeightsComponent = ({ totalGrossWeight, totalTareWeight, totalVolume }: TotalWeightsComponentProps) => {
  return (
    <>
      <View
        style={{
          borderTop: '1px solid black',
          display: 'flex',
          flexDirection: 'row',
          height: '25px',
        }}
      >
        <View style={{ width: '25%', display: 'flex', flexDirection: 'row' }}>
          <View style={{ width: '65%' }}></View>
          <View style={{ width: '35%' }}></View>
        </View>
        <View
          style={{
            width: '50%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <Text
            style={{
              fontSize: 7,
              marginVertical: 2,
              textAlign: 'center',
              marginRight: 10,
              marginTop: 4,
            }}
          >
            Total
          </Text>
        </View>
        <View style={{ width: '25%', display: 'flex', flexDirection: 'row', marginTop: 4 }}>
          <View style={{ width: '33%' }}>
            <Text
              style={{
                fontSize: 7,
                textAlign: 'center',
              }}
            >
              {totalGrossWeight}
            </Text>
          </View>
          <View style={{ width: '33%' }}>
            <Text
              style={{
                fontSize: 7,
                textAlign: 'center',
              }}
            >
              {totalTareWeight}
            </Text>
          </View>
          <View style={{ width: '33%' }}>
            <Text
              style={{
                fontSize: 7,
                textAlign: 'center',
              }}
            >
              {totalVolume}
            </Text>
          </View>
        </View>
      </View>
    </>
  );
};

const ChargesTableComponent = ({ charges }: ChargesComponentProps) => {
  return (
    <View
      style={{
        borderTop: '1px solid black',
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <View style={{ width: '33%', borderRight: '1px solid black', display: 'flex', flexDirection: 'row' }}>
        <View style={{ width: '76%', borderRight: '1px solid black' }}>
          <Text style={{ fontSize: 7, marginVertical: 2, textAlign: 'center', paddingLeft: 3 }}>
            Freights & Charges
          </Text>
          <View style={{ borderTop: '1px solid black' }}>
            {charges?.map((sellRate, i) => (
              <Text
                key={i}
                style={{
                  fontSize: 8,
                  textTransform: 'uppercase',
                  marginTop: 4,
                  paddingLeft: 3,
                  textAlign: 'center',
                }}
              >
                {typeof sellRate.chargeName === 'object' && sellRate.chargeName !== null
                  ? sellRate.chargeName.chargeName
                  : sellRate?.chargeName && sellRate.chargeName.length > 30
                    ? `${sellRate.chargeName.substring(0, 30)}...`
                    : sellRate?.chargeName}
              </Text>
            ))}
          </View>
        </View>
        <View style={{ width: '24%' }}>
          <Text style={{ fontSize: 7, marginVertical: 2, textAlign: 'center', paddingLeft: 3 }}>Rate</Text>
          <View style={{ borderTop: '1px solid black' }}>
            {charges?.map((sellRate, i) => (
              <Text
                key={i}
                style={{
                  fontSize: 8,
                  textTransform: 'uppercase',
                  marginTop: 4,
                  paddingLeft: 3,
                  textAlign: 'center',
                }}
              >
                {sellRate?.rate}
              </Text>
            ))}
          </View>
        </View>
      </View>
      <View style={{ width: '16.5%', borderRight: '1px solid black' }}>
        <View style={{ width: '100%' }}>
          <Text style={{ fontSize: 7, marginVertical: 2, textAlign: 'center', paddingLeft: 3 }}>Unit</Text>
          <View style={{ borderTop: '1px solid black' }}>
            {charges?.map((sellRate, i) => (
              <Text
                key={i}
                style={{
                  fontSize: 8,
                  textTransform: 'uppercase',
                  marginTop: 4,
                  paddingLeft: 3,
                  textAlign: 'center',
                }}
              >
                {sellRate?.base}
              </Text>
            ))}
          </View>
        </View>
      </View>
      <View style={{ width: '16.5%', borderRight: '1px solid black' }}>
        <View style={{ width: '100%' }}>
          <Text style={{ fontSize: 7, marginVertical: 2, textAlign: 'center', paddingLeft: 3 }}>Currency</Text>
          <View style={{ borderTop: '1px solid black' }}>
            {charges?.map((sellRate, i) => (
              <Text
                key={i}
                style={{
                  fontSize: 8,
                  textTransform: 'uppercase',
                  marginTop: 4,
                  paddingLeft: 3,
                  textAlign: 'center',
                }}
              >
                {sellRate?.currency}
              </Text>
            ))}
          </View>
        </View>
      </View>
      <View style={{ width: '34%', display: 'flex', flexDirection: 'row' }}>
        <View style={{ width: '50%', borderRight: '1px solid black' }}>
          <Text style={{ fontSize: 7, marginVertical: 2, textAlign: 'center', paddingLeft: 3 }}>Exchange Rate</Text>
          <View style={{ borderTop: '1px solid black' }}>
            {charges?.map((sellRate, i) => (
              <Text
                key={i}
                style={{
                  fontSize: 8,
                  textTransform: 'uppercase',
                  marginTop: 4,
                  paddingLeft: 3,
                  textAlign: 'center',
                }}
              >
                {sellRate?.exchangeRate}
              </Text>
            ))}
          </View>
        </View>
        <View style={{ width: '50%' }}>
          <Text style={{ fontSize: 7, marginVertical: 2, textAlign: 'center', paddingLeft: 3 }}>Prepaid / Collect</Text>
          <View style={{ borderTop: '1px solid black' }}>
            {charges?.map((sellRate, i) => (
              <Text
                key={i}
                style={{
                  fontSize: 8,
                  textTransform: 'uppercase',
                  marginTop: 4,
                  paddingLeft: 3,
                  textAlign: 'center',
                }}
              >
                {sellRate?.paymentType}
              </Text>
            ))}
          </View>
        </View>
      </View>
    </View>
  );
};

const FooterComponent = ({ hbl, bottomText, numberOfOriginalBls }: FooterComponentProps) => {
  return (
    <>
      <View style={styles.fixed} fixed>
        <View
          style={{
            borderTop: '1px solid black',
            display: 'flex',
            flexDirection: 'row',
            height: '70px',
          }}
        >
          <View style={{ width: '33%', borderRight: '1px solid black' }}>
            <Text style={{ fontWeight: 'bold', marginTop: 12, textAlign: 'center' }}>Jurisdiction and Law Clause</Text>
            <Text style={{ textAlign: 'justify', marginVertical: 4, fontSize: 6.5, paddingHorizontal: 10 }}>
              The contract by or contained in this Bill of Lading is Governed by the Law of Singapore and any claim or
              dispute arising hereunder or in connection herewith shall be determined by the courts in Singapore and no
              other courts
            </Text>
          </View>
          <View style={{ width: '33%', borderRight: '1px solid black', display: 'flex', flexDirection: 'row' }}>
            <View style={{ width: '100%' }}>
              <View>
                <Text style={{ marginTop: 7, fontSize: 6.5, paddingLeft: 3, marginBottom: 7.5, textAlign: 'center' }}>
                  Payable at
                </Text>
              </View>
              <View style={{ borderTop: '1px solid black' }}>
                <Text style={{ marginTop: 7, fontSize: 6.5, paddingLeft: 3, marginBottom: 7.5, textAlign: 'center' }}>
                  No. of original B/L(s)
                </Text>
              </View>
              <View style={{ borderTop: '1px solid black' }}>
                <Text style={{ marginVertical: 7, fontSize: 6.5, paddingLeft: 3, textAlign: 'center' }}>
                  Place and date of issue
                </Text>
              </View>
            </View>
          </View>
          <View style={{ width: '34%' }}>
            <View>
              <Text style={{ marginVertical: 7, paddingLeft: 3, textTransform: 'uppercase', textAlign: 'center' }}>
                {hbl?.payableAt && hbl.payableAt.length > 30 ? `${hbl.payableAt.substring(0, 30)}...` : hbl?.payableAt}
              </Text>
            </View>
            <View style={{ borderTop: '1px solid black' }}>
              <Text
                style={{
                  marginVertical: 7,
                  paddingLeft: 3,
                  textTransform: 'uppercase',
                  textAlign: 'center',
                }}
              >
                {numberOfOriginalBls}
              </Text>
            </View>
            <View style={{ borderTop: '1px solid black' }}>
              <Text style={{ marginVertical: 7, paddingLeft: 3, textTransform: 'uppercase', textAlign: 'center' }}>
                {hbl?.placeAndDateOfIssue.place} {'  '}
                {dayjs(hbl?.placeAndDateOfIssue.date).format('DD/MM/YYYY')}
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            borderTop: '1px solid black',
            display: 'flex',
            flexDirection: 'row',
            height: '50px',
          }}
        >
          <View style={{ width: '33%', borderRight: '1px solid black', borderBottom: '1px solid black' }}>
            <Text style={{ textAlign: 'justify', marginVertical: 6, fontSize: 6.5, paddingHorizontal: 10 }}>
              Excess Value Description : Refer to Clause 6(4)(B) + (C) on reverse side
            </Text>
          </View>
          <View style={{ width: '33%' }}></View>
          <View style={{ width: '34%' }}>
            <Text style={{ marginVertical: 6, fontSize: 6.5 }}>Signed on behalf of the Carrier :</Text>
            <Text
              style={{
                textAlign: 'right',
                marginVertical: 6,
                fontSize: 6.5,
                paddingHorizontal: 10,
                textTransform: 'uppercase',
              }}
            >
              REUDAN INTERNATIONAL LTD
            </Text>
            <Text style={{ textAlign: 'justify', marginVertical: 6, fontSize: 6.5 }}>By :</Text>
          </View>
        </View>
        <Text style={{ fontSize: 12, textTransform: 'uppercase', fontWeight: 'semibold', marginTop: 10 }}>
          {bottomText}
        </Text>
        <Text
          style={styles.pageNumbers}
          render={({ pageNumber, totalPages }) => `Page  ${pageNumber} / ${totalPages}`}
          fixed
        />
      </View>
    </>
  );
};

const HblDocument: React.FC<HblDocumentProps> = ({ hbl, copyVersion }) => {
  const [watermarkText, setWatermarkText] = useState<string>();
  const [numberOfOriginalBls, setNumberOfOriginalBls] = useState(hbl?.numberOfOriginalBls);
  const [bottomText, setBottomText] = useState<string | undefined>('');
  const [totalGrossWeight, setTotalGrossWeight] = useState(0);
  const [totalTareWeight, setTotalTareWeight] = useState(0);
  const [totalVolume, setTotalVolume] = useState(0);
  const [exceededLimitsValues, setExceededLimitsValues] = useState<ExceededLimit[]>([]);

  const checkAndTruncate = (val: string, limit: number): string => {
    if (val && val.length > limit) {
      return `${val.substring(0, limit)}...`;
    }
    return val;
  };

  useEffect(() => {
    if (!hbl?.hblApproval && copyVersion === false) {
      setWatermarkText('DRAFT');
    } else if (hbl?.hblApproval && copyVersion === false) {
      setWatermarkText(hbl?.blType || '');
      setBottomText(hbl?.blType);
    } else if (copyVersion === true) {
      setWatermarkText('COPY NON-NEGOTIABLE');
      setNumberOfOriginalBls('COPY NON-NEGOTIABLE');
      setBottomText('COPY NON-NEGOTIABLE');
    }
    if (hbl?.cargoDetails) {
      hbl.cargoDetails.map(cargoDetail => {
        if (cargoDetail?.grossWeightCargo) {
          setTotalGrossWeight(value => value + Number(cargoDetail?.grossWeightCargo));
        }
        if (cargoDetail?.tareWeight) {
          setTotalTareWeight(value => value + Number(cargoDetail?.tareWeight));
        }
        if (cargoDetail?.volume) {
          setTotalVolume(value => value + Number(cargoDetail?.volume));
        }
      });
    }
  }, [hbl, copyVersion]);

  useEffect(() => {
    const checkForExceededLimits = (val: string | undefined, key: string, limit: number) => {
      if (val && val.length > limit) {
        setExceededLimitsValues(prev => [...prev, { key, value: val }]);
      }
      return;
    };
    if (hbl) {
      checkForExceededLimits(hbl?.shipper?.name, 'Name Of Shipper', 110);
      checkForExceededLimits(hbl?.shipper?.address, 'Address Of Shipper', 110);
      checkForExceededLimits(hbl?.shipper?.city, 'City Of Shipper', 25);
      checkForExceededLimits(hbl?.shipper?.postcode, 'Postcode Of Shipper', 25);
      checkForExceededLimits(hbl?.shipper?.country, 'Country Of Shipper', 50);
      checkForExceededLimits(hbl?.consignee?.name, 'Name Of Consignee', 110);
      checkForExceededLimits(hbl?.consignee?.address, 'Address Of Consignee', 110);
      checkForExceededLimits(hbl?.consignee?.city, 'City Of Consignee', 25);
      checkForExceededLimits(hbl?.consignee?.postcode, 'Postcode Of Consignee', 25);
      checkForExceededLimits(hbl?.consignee?.country, 'Country Of Consignee', 50);
      checkForExceededLimits(hbl?.notifyParty?.name, 'Name Of Notify Party', 110);
      checkForExceededLimits(hbl?.notifyParty?.address, 'Address Of Notify Party', 110);
      checkForExceededLimits(hbl?.notifyParty?.city, 'City Of Notify Party', 25);
      checkForExceededLimits(hbl?.notifyParty?.postcode, 'Postcode Of Notify Party', 25);
      checkForExceededLimits(hbl?.notifyParty?.country, 'Country Of Notify Party', 50);
      checkForExceededLimits(hbl?.preCarriageBy, 'Pre carriage by', 30);
      checkForExceededLimits(hbl?.oceanVessel.vesselName, 'Vessel Name', 10);
      checkForExceededLimits(hbl?.oceanVessel?.vesselFlag, 'Vessel Flag', 10);
      checkForExceededLimits(hbl?.oceanVessel?.voyageNumber, 'Voyage Number', 10);
      checkForExceededLimits(hbl?.portOfDischarge?.portName, 'Port of Discharge', 30);
      checkForExceededLimits(hbl?.placeOfReceipt?.siteName, 'Place of Receipt', 30);
      checkForExceededLimits(hbl?.portOfLoading?.portName, 'Port of Loading', 30);
      checkForExceededLimits(hbl?.placeOfDelivery?.portName, 'Place of Delivery', 30);
      checkForExceededLimits(hbl?.deliveryContactDetails?.name, 'Name of Delivery Contact Details', 110);
      checkForExceededLimits(hbl?.deliveryContactDetails?.address, 'Address of Delivery Contact Details', 110);
      checkForExceededLimits(hbl?.deliveryContactDetails?.city, 'City of Delivery Contact Details', 25);
      checkForExceededLimits(hbl?.deliveryContactDetails?.postcode, 'Postcode of Delivery Contact Details', 25);
      checkForExceededLimits(hbl?.deliveryContactDetails?.country, 'Country of Delivery Contact Details', 50);
      if (hbl?.cargoDetails) {
        for (let i = 0; i < hbl?.cargoDetails?.length; i++) {
          checkForExceededLimits(
            hbl?.cargoDetails[i]?.descriptionOfGoodsAndPackages,
            `Description of Goods and Packages ${i + 1}`,
            62,
          );
        }
      }
    }
  }, [hbl]);

  return (
    <Document>
      <Page size='A4' style={styles.body} wrap>
        {watermarkText && (
          <View style={styles.watermark} fixed>
            <Text style={styles.watermarkText}>{watermarkText}</Text>
          </View>
        )}
        <View
          style={{
            ...styles.topFixed,
          }}
          fixed
        >
          <View>
            <Text
              style={{
                fontSize: 20,
                fontWeight: 'medium',
                textAlign: 'center',
                width: '100%',
              }}
            >
              COMBINED TRANSPORT BILL OF LADING
            </Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              borderTop: '1px solid black',
            }}
          >
            <ShipmentDetailsComponent hbl={hbl} checkAndTruncate={checkAndTruncate} />
            <CompanyDetailsComponent hbl={hbl} checkAndTruncate={checkAndTruncate} />
          </View>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <CargoDetailsComponent hbl={hbl} checkAndTruncate={checkAndTruncate} />
          <DeliveryContactDetailsComponent hbl={hbl} checkAndTruncate={checkAndTruncate} />
        </View>
        <CargoDetailsTableComponent
          hbl={hbl}
          totalGrossWeight={totalGrossWeight}
          totalTareWeight={totalTareWeight}
          totalVolume={totalVolume}
          checkAndTruncate={checkAndTruncate}
        />
        {exceededLimitsValues.length > 0 && (
          <View style={{ width: '100%' }} break>
            <View style={{ borderBottom: '1px solid black' }}>
              <Text style={{ textAlign: 'center', fontSize: 10, fontWeight: 'bold', marginVertical: 4 }}>Annexure</Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                borderBottomWidth: 1,
                borderColor: '#000',
              }}
            >
              <Text
                style={{
                  flex: 1,
                  textAlign: 'center',
                  fontWeight: 'bold',
                  marginVertical: 1,
                }}
              >
                Key
              </Text>
              <View
                style={{
                  borderLeftWidth: 1,
                  borderColor: '#000',
                  marginHorizontal: 8,
                }}
              />
              <Text
                style={{
                  flex: 1,
                  textAlign: 'center',
                  fontWeight: 'bold',
                  marginVertical: 1,
                }}
              >
                Value
              </Text>
            </View>

            {exceededLimitsValues.map(exceededLimit => (
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
                key={exceededLimit.key}
              >
                <Text
                  style={{
                    flex: 1,
                    textAlign: 'center',
                    marginVertical: 4,
                  }}
                >
                  {exceededLimit.key}
                </Text>
                <View
                  style={{
                    borderLeftWidth: 1,
                    borderColor: '#000',
                    marginHorizontal: 8,
                  }}
                />
                <Text
                  style={{
                    flex: 1,
                    textAlign: 'justify',
                    marginVertical: 4,
                  }}
                >
                  {exceededLimit.value}
                </Text>
              </View>
            ))}
          </View>
        )}
        <FooterComponent hbl={hbl} bottomText={bottomText} numberOfOriginalBls={numberOfOriginalBls} />
      </Page>
    </Document>
  );
};

export default HblDocument;
