import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Close, Draw, Settings, AccountCircle } from '@mui/icons-material';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import { useAuth0 } from '@auth0/auth0-react';
import { useTheme } from '@mui/system';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: 0,
    borderBottom: 0,
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiPaper-root': {
    height: '90vh',
    maxWidth: '90vw',
  },
}));

const UserSettings = () => {
  const { user } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    navigate('..');
    setOpen(false);
  };

  // load signature route at initial render
  React.useEffect(() => navigate('./user-profile', { relative: 'path', state: { user } }), []);
  return (
    <BootstrapDialog fullWidth onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
      <DialogTitle id='customized-dialog-title'>
        <div className='flex flex-row'>
          <Settings className='mr-1' />
          Settings
        </div>
      </DialogTitle>
      <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={theme => ({
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <Close />
      </IconButton>
      <DialogContent className='flex' dividers>
        <Stack
          direction='column'
          className='flex-none w-3/12 h-full'
          sx={theme => ({
            backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[100],
          })}
        >
          <List
            component='nav'
            aria-labelledby='nested-list-subheader'
            subheader={
              <ListSubheader
                sx={theme => ({
                  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[100],
                })}
                component='div'
                id='nested-list-subheader'
              >
                General
              </ListSubheader>
            }
          >
            <ListItemButton
              sx={theme => ({
                backgroundColor: location.pathname.endsWith('user-profile')
                  ? theme.palette.mode === 'dark'
                    ? theme.palette.grey[700]
                    : theme.palette.grey[300]
                  : undefined,
                '&:hover': {
                  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[300],
                },
              })}
              onClick={() => navigate('./user-profile', { relative: 'path', state: { user } })}
            >
              <ListItemIcon>
                <AccountCircle />
              </ListItemIcon>
              <ListItemText primary='User Profile' />
            </ListItemButton>
            <ListItemButton
              sx={theme => ({
                backgroundColor: location.pathname.endsWith('signature')
                  ? theme.palette.mode === 'dark'
                    ? theme.palette.grey[700]
                    : theme.palette.grey[300]
                  : undefined,
                '&:hover': {
                  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[300],
                },
              })}
              onClick={() => navigate('./signature', { relative: 'path', state: { user } })}
            >
              <ListItemIcon>
                <Draw />
              </ListItemIcon>
              <ListItemText primary='Signature' />
            </ListItemButton>
          </List>
        </Stack>
        <Box
          className='grow p-4 m-4 shadow'
          sx={theme => ({
            backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[100],
          })}
        >
          <Outlet />
        </Box>
      </DialogContent>
    </BootstrapDialog>
  );
};

export default UserSettings;
