import React from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';

const Layout = () => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const isSm = useMediaQuery(theme.breakpoints.down(1500));
  const isLg = useMediaQuery(theme.breakpoints.up(1944));

  return (
    <div>
      <Box display={'flex'} width='100%' height='100vh'>
        <Sidebar drawerWidth={isSm ? '12rem' : isLg ? '16rem' : '14rem'} />
        <Box
          flexGrow={1}
          sx={{
            marginLeft: isSm ? '12rem' : isLg ? '16rem' : '14rem',
            width: `calc(100% - ${isSm ? '12rem' : isLg ? '16rem' : '14rem'})`,
            backgroundColor: mode === 'dark' ? '#1F1F21' : '#d5d5d5',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              margin: '1rem 1rem 1rem 0',
              padding: '1rem',
              backgroundColor: mode === 'dark' ? '#161618' : '#eaeaea',
              borderRadius: '1rem',
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            <Outlet />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Layout;
