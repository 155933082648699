import React, { useEffect, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
  Box,
  Button,
  useTheme,
  TextField,
  Dialog,
  DialogTitle,
  Autocomplete,
  IconButton,
  Divider,
  DialogContent,
  InputAdornment,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
} from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { useGetAuditsQuery, useGetCustomersQuery, useGetVendorsQuery } from '../../state/api';
import { useGetSitesQuery } from '../../state/api';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { sec } from '../../auth/accessToken';
import { BaseDialogueProps, Site, VendorType } from 'models/index.model';
import { Booking, BookingRequest, HaulageType, TransportSchedule, TransportStatus } from 'models/booking.model';
import { getValueAndLabelFromSite, handleSnackbarClose } from 'utils/utils';
import AlertSnackbar from 'components/AlertSnackbar';
import GeneralDialogueActions from 'components/GeneralDialogueActions';
import { haulageTypes, transportStatuses } from 'dataAssets/constants';

interface UpdateTransportScheduleDialogueProps extends BaseDialogueProps {
  booking: Booking;
}
const UpdateTransportScheduleDialogue: React.FC<UpdateTransportScheduleDialogueProps> = ({
  handleClose,
  open,
  booking,
}) => {
  const theme = useTheme();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [hasInvalidContainers, setInvalidContainers] = useState(false);

  const [invalidSiteIndex, setInvalidSiteIndex] = useState<number>(-1);

  const { data: vendors, isLoading: vendorsIsLoading } = useGetVendorsQuery({});
  const { data: sites, isLoading: sitesIsLoading } = useGetSitesQuery({});
  const { data: customers } = useGetCustomersQuery({});
  const { refetch: auditLogsRefetch } = useGetAuditsQuery(['Booking', booking._id]);
  const [defaultValues, setDefaultValues] = useState<any>();
  // react hook form validation used over zod here

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    reset,
    formState: { errors },
  } = useForm<BookingRequest>({
    defaultValues: defaultValues,
  });

  useEffect(() => {
    const updatedDefaultValues = {
      transportSites: booking.transportSites?.map(transportSite => ({
        site: transportSite.site?._id,
        containers: transportSite.containers,
      })),
      transportSchedule: booking.transportSchedule,
      pickupDepot: booking.pickupDepot,
      dropoffDepot: booking.dropoffDepot,
      haulageType: booking.haulageType ?? haulageTypes[0],
    };
    setDefaultValues(updatedDefaultValues);
    reset(updatedDefaultValues);
  }, [booking, reset]);

  const getSiteIndex = (idx: number) => {
    return (
      (getValues().transportSites ?? []).slice(0, idx + 1).reduce((acc, curr) => {
        return acc + Number(curr.containers ?? 0);
      }, 0) - 1
    );
  };

  const {
    fields: transportSitesFields,
    append: transportSitesAppend,
    remove: transportSitesRemove,
  } = useFieldArray({
    control,
    name: 'transportSites',
  });

  const {
    insert: transportScheduleInsert,
    remove: transportScheduleRemove,
    replace: transportScheduleReplace,
  } = useFieldArray({
    control,
    name: 'transportSchedule',
  });

  const {
    fields: pickupDepotFields,
    append: pickupDepotAppend,
    remove: pickupDepotRemove,
  } = useFieldArray({
    control,
    name: 'pickupDepot',
  });

  const {
    fields: dropoffDepotFields,
    append: dropoffDepotAppend,
    remove: dropoffDepotRemove,
  } = useFieldArray({
    control,
    name: 'dropoffDepot',
  });

  useEffect(() => {
    if (booking.loadingTypeExport === 'CY') {
      let schedule = booking.transportSchedule || [];
      const currentLength = schedule.length;
      const requiredLength = booking.numberOfContainers;

      if (currentLength === 0) {
        schedule = Array.from({ length: requiredLength }, () => ({
          site: '',
          date: dayjs().toString(),
          time: dayjs().toString(),
          reference: '',
          hauler: '',
          containerNumber: '',
          sealNumber: '',
          weight: '',
          remarks: '',
          status: transportStatuses[0],
        }));
      } else if (currentLength < requiredLength) {
        const additionalRows = Array.from({ length: requiredLength - currentLength }, () => ({
          site: '',
          date: dayjs().toString(),
          time: dayjs().toString(),
          reference: '',
          hauler: '',
          containerNumber: '',
          sealNumber: '',
          weight: '',
          remarks: '',
          status: transportStatuses[0],
        }));
        schedule = [...schedule, ...additionalRows];
      }

      transportScheduleReplace(schedule);
    }
  }, [booking.loadingTypeExport, booking.numberOfContainers, booking.transportSchedule, transportScheduleReplace]);

  const onSubmit = async (data: BookingRequest) => {
    setSnackbarOpen(true);
    if (
      getTotalContainerNumber() === booking.numberOfContainers ||
      booking.loadingTypeExport === 'CY' ||
      booking.haulageType === HaulageType.Combined
    ) {
      setInvalidContainers(false);
      try {
        data.transportSchedule?.forEach((schedule, i) => {
          schedule.date = schedule.site ? dayjs(schedule.date).format() : undefined;
          schedule.time = schedule.site ? dayjs(schedule.time).format() : undefined;
        });

        const accessToken = await sec.getAccessTokenSilently()();

        await axios.patch(`${process.env.REACT_APP_BASE_URL}/api/v1/bookings/${booking._id}`, data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const bookingData = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/bookings/${booking._id}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        if (bookingData?.data?.generatedHblId !== undefined) {
          const hbl = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/v1/hbl/${bookingData?.data?.generatedHblId}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            },
          );

          const updatedCargoDetails = [];
          if (data?.transportSchedule) {
            for (let i = 0; i < data.transportSchedule.length; i++) {
              if (hbl.data.cargoDetails[i]) {
                hbl.data.cargoDetails[i] = {
                  marks: data.transportSchedule[i].containerNumber,
                  numbers: data.transportSchedule[i].sealNumber,
                  numberOfPackages: hbl.data.cargoDetails[i].numberOfPackages,
                  kindOfPackages: hbl.data.cargoDetails[i].kindOfPackages,
                  descriptionOfGoodsAndPackages: hbl.data.cargoDetails[i].descriptionOfGoodsAndPackages,
                  grossWeightCargo: data.transportSchedule[i].weight,
                  tareWeight: hbl.data.cargoDetails[i].tareWeight,
                  volume: hbl.data.cargoDetails[i].volume,
                };
              } else {
                hbl.data.cargoDetails[i] = {
                  marks: data.transportSchedule[i].containerNumber,
                  numbers: data.transportSchedule[i].sealNumber,
                  numberOfPackages: 1,
                  kindOfPackages: bookingData.data.containerType,
                  descriptionOfGoodsAndPackages: '',
                  grossWeightCargo: data.transportSchedule[i].weight,
                  tareWeight: 0,
                  volume: 0,
                };
              }
              updatedCargoDetails.push(hbl.data.cargoDetails[i]);
            }
          }
          if (hbl?.data?.hblApproval === false) {
            await axios.patch(
              `${process.env.REACT_APP_BASE_URL}/api/v1/hbl/${hbl?.data?._id}`,
              { cargoDetails: updatedCargoDetails },
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              },
            );
          }
        }

        handleClose();
        auditLogsRefetch();
      } catch (err) {
        console.log(err);
      }
    } else {
      setInvalidContainers(true);
    }
  };

  const getTotalContainerNumber = () =>
    getValues().transportSites?.reduce(
      (numberOfContainers, transportSite) => numberOfContainers + Number(transportSite.containers),
      0,
    );

  const totalNoOfContainerValidation =
    getTotalContainerNumber() === booking.numberOfContainers ||
    booking.loadingTypeExport === 'CY' ||
    booking.haulageType === HaulageType.Combined;

  useEffect(() => {
    if (open) {
      if (!booking.enquiryRef) return;
      if (getValues().transportSchedule?.length === getTotalContainerNumber()) return;
      if (booking?.transportSites?.length) {
        let count = 0;
        booking.transportSites?.forEach((transportSites, index) => {
          if (booking?.transportSites?.length) {
            const currentDateTime = dayjs();
            const params: TransportSchedule = {
              site: '',
              date: currentDateTime.toString(),
              time: currentDateTime.toDate().toString(),
              reference: '',
              hauler: '',
              containerNumber: '',
              sealNumber: '',
              weight: '',
              remarks: '',
              status: transportStatuses[0],
            };

            if (transportSites.site) {
              const site = sites?.masterData?.find((site: any) => site._id === transportSites?.site?._id);

              if (site) {
                params.site = site.city;
              }
            }

            for (let i = 0; i < booking?.transportSites?.[index]?.containers; i++) {
              transportScheduleInsert(count, params);
              count = count + 1;
            }
          }
        });
      }
    }
  }, [open]);

  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullScreen>
        <DialogTitle
          sx={{
            fontSize: '1.6rem',
          }}
        >
          {`Update ${booking.bookingType === 'Export' ? 'Loading' : 'Delivery'} Schedule`}
        </DialogTitle>
        <DialogContent>
          {booking.haulageType === HaulageType.Combined && (
            <Typography sx={{ marginBottom: '1rem' }}>
              The following transport schedule is combination of differernt haulage types. Therefore, there might be a
              mismatch between the total number of containers across transport sites, depots and the transport schedule.
            </Typography>
          )}
          <form>
            {booking.loadingTypeExport === 'SD' && (
              <Box mb='1rem'>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={'0.5rem'}>
                  <Button
                    variant='contained'
                    type='button'
                    disabled={
                      getValues().transportSites?.length === booking.numberOfContainers ||
                      getTotalContainerNumber() === booking.numberOfContainers
                    }
                    startIcon={<AddOutlinedIcon />}
                    onClick={() => {
                      transportSitesAppend({ site: '', containers: 0 });
                    }}
                    sx={{
                      bgcolor: theme.palette.secondary[400],
                      mb: '1rem',
                      '&:hover': {
                        backgroundColor: theme.palette.secondary[500],
                      },
                    }}
                  >
                    {`Add ${booking.bookingType === 'Export' ? 'Loading' : 'Delivery'} Site`}
                  </Button>
                  <FormControl component={'fieldset'}>
                    <FormLabel component={'legend'}>Haulage Type</FormLabel>
                    <Controller
                      name='haulageType'
                      control={control}
                      render={({ field }) => (
                        <RadioGroup row {...field}>
                          {haulageTypes.map(haulageType => (
                            <FormControlLabel
                              key={haulageType}
                              value={haulageType}
                              control={<Radio />}
                              label={haulageType}
                            />
                          ))}
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                </Box>
                {transportSitesFields.map((site, index) => {
                  return (
                    <Box
                      display='grid'
                      gridTemplateColumns='40fr 6fr 1fr '
                      alignItems='center'
                      id={`transport-site-row-${index}`}
                      gap='1rem'
                      mb='1rem'
                      key={`${site.id}`}
                    >
                      {sites?.masterData || sitesIsLoading ? (
                        <Autocomplete
                          defaultValue={
                            [
                              sites?.masterData?.find(site => site._id === getValues().transportSites?.[index].site),
                            ]?.map(site => {
                              if (!site) {
                                return null;
                              }
                              return getValueAndLabelFromSite(site);
                            })[0]
                          }
                          disablePortal
                          options={(sites?.masterData ?? []).map((site: Site) => getValueAndLabelFromSite(site))}
                          isOptionEqualToValue={(option, value) => option.value === value.value}
                          onChange={(_, value) => {
                            const newVal = value?.value ?? '';
                            if (invalidSiteIndex === index && newVal) {
                              setInvalidSiteIndex(-1);
                            }
                            setValue(`transportSites.${index}.site`, newVal);

                            const numberOfContainers = getValues().transportSites?.[index].containers;

                            if (!numberOfContainers) {
                              return;
                            }

                            const finalSiteIndex = getSiteIndex(index);

                            const newCity = sites?.masterData?.find(site => site._id === newVal)?.city ?? '';
                            for (
                              let siteIndex = finalSiteIndex;
                              siteIndex > finalSiteIndex - numberOfContainers;
                              siteIndex--
                            ) {
                              setValue(`transportSchedule.${siteIndex}.site`, newCity);
                            }
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label='Site *'
                              error={!!errors?.transportSites?.[index]?.site || index === invalidSiteIndex}
                              helperText={
                                errors?.transportSites?.[index]?.site?.message || index === invalidSiteIndex
                                  ? 'Select a valid Load Point'
                                  : null
                              }
                            />
                          )}
                        />
                      ) : null}

                      <TextField
                        label='Containers'
                        type='number'
                        autoComplete='off'
                        value={watch(`transportSites.${index}.containers`)}
                        onChange={val => {
                          if (hasInvalidContainers) {
                            setInvalidContainers(false);
                          }

                          let newVal = Number(val.target.value);
                          const transportSite = getValues().transportSites?.[index];
                          if (!transportSite?.site) {
                            setInvalidSiteIndex(index);
                            return;
                          }
                          const oldVal = transportSite?.containers ?? 0;
                          const maxContainersForLoadPoint =
                            booking.numberOfContainers -
                            (getValues().transportSites?.reduce((acc, transportSite, idx) => {
                              if (idx !== index && transportSite.containers) {
                                acc += transportSite.containers;
                              }
                              return acc;
                            }, 0) ?? 0);

                          if (newVal === oldVal) {
                            return;
                          } else if (newVal > maxContainersForLoadPoint) {
                            newVal = maxContainersForLoadPoint;
                          } else if (newVal < 0) {
                            newVal = 0;
                          }

                          setValue(`transportSites.${index}.containers`, newVal);

                          const transportSiteIndex = getSiteIndex(index);

                          if (newVal > oldVal) {
                            const currentDateTime = dayjs();
                            const params: TransportSchedule = {
                              site: '',
                              date: currentDateTime.toString(),
                              time: currentDateTime.toDate().toString(),
                              reference: '',
                              hauler: '',
                              containerNumber: '',
                              sealNumber: '',
                              weight: '',
                              remarks: '',
                              status: transportStatuses[0],
                            };

                            if (transportSite?.site) {
                              const site = sites?.masterData?.find(site => site._id === transportSite.site);
                              if (site) {
                                params.site = site.city;
                              }
                            }
                            for (let i = 0; i < newVal - oldVal; i++) {
                              transportScheduleInsert(transportSiteIndex + i, params);
                            }
                          } else {
                            for (let i = newVal; i < oldVal; i++) {
                              transportScheduleRemove(transportSiteIndex + 1);
                            }
                          }
                        }}
                        error={!!errors?.transportSites?.[index]?.containers || hasInvalidContainers}
                        helperText={errors?.transportSites?.[index]?.containers?.message}
                      />
                      <Box>
                        <IconButton
                          type='button'
                          onClick={() => {
                            const removalIndexEnd = getSiteIndex(index);
                            const numberOfContainers = getValues().transportSites?.[index]?.containers ?? 0;
                            if (numberOfContainers) {
                              for (
                                let removalIndex = removalIndexEnd;
                                removalIndex > removalIndexEnd - numberOfContainers;
                                removalIndex--
                              ) {
                                transportScheduleRemove(removalIndex);
                              }
                            }
                            transportSitesRemove(index);
                          }}
                          sx={{
                            bgcolor: theme.palette.secondary[400],
                            '&:hover': {
                              backgroundColor: theme.palette.secondary[500],
                            },
                          }}
                        >
                          <RemoveOutlinedIcon sx={{ color: 'white' }} />
                        </IconButton>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            )}
            <Divider
              sx={{
                mb: '1.5rem',
              }}
            />
            {[...(getValues().transportSchedule ?? [])].map((site, i) => {
              const uniqueKey = site._id ?? i;
              const statusValidation = (field: string) => (value: any) => {
                if (getValues(`transportSchedule.${i}.status`) === TransportStatus.Completed) {
                  return value !== '' || `${field} is required for completed status`;
                }
              };

              const duplicateValidation = (field: string, index: number) => (value: any) => {
                if (!value) return true;
                const allValues = getValues().transportSchedule ?? [];
                const fieldValues = allValues.map((item: any) => item[field]);
                const duplicateIndex = fieldValues.findIndex((v, idx) => v === value && idx !== index);
                return duplicateIndex === -1 || `Duplicate ${field} found`;
              };

              const currentSite = { ...site };
              return (
                <Box
                  key={uniqueKey}
                  display={'grid'}
                  gridTemplateColumns={
                    booking.loadingTypeExport === 'SD'
                      ? '20fr 20fr 20fr 20fr 20fr 20fr 20fr 20fr 20fr 20fr 1fr'
                      : '20fr 20fr 20fr 20fr 20fr 20fr 1fr'
                  }
                  id={`container-row-${i}`}
                  alignItems={'center'}
                  gap={'1rem'}
                  mb={'1rem'}
                >
                  {booking.loadingTypeExport === 'SD' && (
                    <>
                      <TextField
                        disabled
                        label={'Site *'}
                        autoComplete='off'
                        variant='outlined'
                        defaultValue={currentSite.site}
                        {...register(`transportSchedule.${i}.site`, {
                          required: booking.haulageType !== HaulageType.Combined,
                        })}
                        error={!!errors?.transportSchedule?.[i]?.site}
                        helperText={errors?.transportSchedule?.[i]?.site?.message}
                      />

                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Controller
                          name={`transportSchedule.${i}.date`}
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <Box>
                              <DatePicker
                                format='DD/MM/YYYY'
                                label={'Date'}
                                value={value ? dayjs(value) : null}
                                onChange={date => onChange(dayjs(date).format())}
                                minDate={
                                  booking.bookingType === 'Export'
                                    ? dayjs(booking.etd).subtract(14, 'days')
                                    : dayjs(booking.eta)
                                }
                                maxDate={
                                  booking.bookingType === 'Export'
                                    ? dayjs(booking.etd).subtract(1, 'days')
                                    : dayjs(booking.eta).add(14, 'days')
                                }
                              />
                            </Box>
                          )}
                        />
                        <Controller
                          name={`transportSchedule.${i}.time`}
                          control={control}
                          defaultValue={currentSite.time}
                          render={({ field: { value, onChange } }) => (
                            <TimePicker
                              ampm={false}
                              label='Time'
                              value={value ? dayjs(value) : null}
                              onChange={date => onChange(dayjs(date).format())}
                            />
                          )}
                        />
                      </LocalizationProvider>
                      <TextField
                        label={'Reference'}
                        autoComplete='off'
                        variant='outlined'
                        defaultValue={currentSite.reference}
                        {...register(`transportSchedule.${i}.reference`, {
                          validate: statusValidation('Reference'),
                        })}
                        error={!!errors?.transportSchedule?.[i]?.reference}
                        helperText={errors?.transportSchedule?.[i]?.reference?.message}
                      />
                    </>
                  )}
                  <TextField
                    label={'Container Number'}
                    autoComplete='off'
                    variant='outlined'
                    {...register(`transportSchedule.${i}.containerNumber`, {
                      validate: {
                        status: statusValidation('Container Number'),
                        unique: duplicateValidation('containerNumber', i),
                      },
                    })}
                    error={!!errors?.transportSchedule?.[i]?.containerNumber}
                    helperText={errors?.transportSchedule?.[i]?.containerNumber?.message}
                  />
                  <TextField
                    label={'Seal Number'}
                    autoComplete='off'
                    variant='outlined'
                    {...register(`transportSchedule.${i}.sealNumber`, {
                      validate: {
                        status: statusValidation('Seal Number'),
                        unique: duplicateValidation('sealNumber', i),
                      },
                    })}
                    error={!!errors?.transportSchedule?.[i]?.sealNumber}
                    helperText={errors?.transportSchedule?.[i]?.sealNumber?.message}
                  />
                  <TextField
                    label={'Weight'}
                    type='number'
                    autoComplete='off'
                    variant='outlined'
                    {...register(`transportSchedule.${i}.weight`, {
                      validate: statusValidation('Weight'),
                    })}
                    error={!!errors?.transportSchedule?.[i]?.weight}
                    helperText={errors?.transportSchedule?.[i]?.weight?.message}
                    InputProps={{
                      endAdornment: <InputAdornment position='end'>KG</InputAdornment>,
                    }}
                  />
                  <Box>
                    {vendors?.masterData || vendorsIsLoading ? (
                      <Controller
                        name={`transportSchedule.${i}.hauler`}
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { value, onChange } }) => (
                          <Autocomplete
                            disablePortal
                            value={
                              value
                                ? {
                                    label: value,
                                  }
                                : null
                            }
                            onChange={(_, value) => {
                              onChange(value?.label || '');
                            }}
                            options={
                              booking.loadingTypeExport === 'SD'
                                ? vendors?.masterData
                                    ?.filter(
                                      vendor =>
                                        vendor.vendorType === VendorType.Haulier ||
                                        vendor.vendorType === VendorType.Carrier,
                                    )
                                    .map(item => ({
                                      label: item.name,
                                    })) || []
                                : customers?.masterData
                                    ?.filter(customer =>
                                      booking.bookingType === 'Export'
                                        ? customer.name === booking.consignor?.name
                                        : customer.name === booking.consignee?.name,
                                    )
                                    .map(item => ({
                                      label: item.name,
                                    })) || []
                            }
                            isOptionEqualToValue={(option, value) => option.label === value.label}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label='Hauler *'
                                error={!!errors?.transportSchedule?.[i]?.hauler}
                                helperText={errors?.transportSchedule?.[i]?.hauler?.message}
                              />
                            )}
                          />
                        )}
                      />
                    ) : null}
                  </Box>
                  <TextField
                    label='Remarks'
                    autoComplete='off'
                    variant='outlined'
                    {...register(`transportSchedule.${i}.remarks`)}
                  />
                  <Controller
                    name={`transportSchedule.${i}.status`}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Autocomplete
                        disablePortal
                        value={value}
                        options={transportStatuses}
                        onChange={(_, newValue) => {
                          onChange(newValue);
                          const scheduleSite = getValues(`transportSchedule.${i}.site`);
                          const transportSites = getValues().transportSites;
                          if (transportSites) {
                            const transportSiteIndex = transportSites.findIndex(
                              (transportSite: any) =>
                                sites?.masterData?.find(site => site._id === transportSite.site)?.city === scheduleSite,
                            );
                            if (transportSiteIndex !== -1) {
                              let currentContainers = transportSites[transportSiteIndex]?.containers ?? 0;
                              if (newValue === TransportStatus.Cancelled && currentContainers > 0) {
                                currentContainers -= 1;
                              } else if (
                                value === TransportStatus.Cancelled &&
                                newValue !== TransportStatus.Cancelled
                              ) {
                                currentContainers += 1;
                              }
                              setValue(`transportSites.${transportSiteIndex}.containers`, currentContainers);
                            }
                          }
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label='Status'
                            error={!!errors?.transportSchedule?.[i]?.status}
                            helperText={errors?.transportSchedule?.[i]?.status?.message}
                          />
                        )}
                      />
                    )}
                  />
                  <>
                    <IconButton
                      type='button'
                      onClick={() => {
                        const currentSite = getValues(`transportSchedule.${i}.site`);
                        const currentStatus = getValues(`transportSchedule.${i}.status`);
                        const transportSites = getValues().transportSites;
                        if (transportSites) {
                          const transportSiteIndex = transportSites.findIndex(
                            (transportSite: any) =>
                              sites?.masterData?.find(site => site._id === transportSite.site)?.city === currentSite,
                          );
                          if (transportSiteIndex !== -1) {
                            let currentContainers = transportSites[transportSiteIndex]?.containers ?? 0;
                            if (currentContainers > 0 && currentStatus !== TransportStatus.Cancelled) {
                              currentContainers -= 1;
                              setValue(`transportSites.${transportSiteIndex}.containers`, currentContainers);
                            }
                          }
                        }

                        transportScheduleRemove(i);
                      }}
                      sx={{
                        bgcolor: theme.palette.secondary[400],
                        '&:hover': {
                          backgroundColor: theme.palette.secondary[500],
                        },
                      }}
                    >
                      <RemoveOutlinedIcon sx={{ color: 'white' }} />
                    </IconButton>
                  </>
                </Box>
              );
            })}
            <Divider
              sx={{
                mb: '1.5rem',
              }}
            />
            <Button
              variant='contained'
              type='button'
              onClick={() => {
                pickupDepotAppend({
                  site: undefined,
                  containers: booking.numberOfContainers,
                  reference: booking.carrierBookingNumber,
                });
              }}
              startIcon={<AddOutlinedIcon />}
              sx={{
                bgcolor: theme.palette.secondary[400],
                mb: '1rem',
                mr: '1rem',
                '&:hover': {
                  backgroundColor: theme.palette.secondary[500],
                },
              }}
            >
              Add Pickup Depot
            </Button>
            {pickupDepotFields.map((_, index) => {
              return (
                <Box
                  display='grid'
                  gridTemplateColumns='5fr 1fr 1fr 0.1fr'
                  alignItems='center'
                  gap='1rem'
                  mb='1rem'
                  key={`pickup-${index}`}
                >
                  <Controller
                    name={`pickupDepot.${index}.site`}
                    control={control}
                    render={({ field: { value } }) => (
                      <Autocomplete
                        disablePortal
                        value={value ? getValueAndLabelFromSite(value) : null}
                        options={(sites?.masterData ?? []).map((site: Site) => getValueAndLabelFromSite(site))}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        onChange={(_, value) => {
                          if (!value) {
                            return;
                          }
                          const newSite = sites?.masterData?.find(site => site._id === value.value);
                          if (newSite) {
                            setValue(`pickupDepot.${index}.site`, newSite);
                          }
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label='Pickup Depot'
                            error={!!errors?.pickupDepot?.[index]?.site}
                            helperText={errors?.pickupDepot?.[index]?.site?.message}
                          />
                        )}
                      />
                    )}
                  />
                  <TextField
                    label='Containers'
                    type='number'
                    autoComplete='off'
                    variant='outlined'
                    {...register(`pickupDepot.${index}.containers`, {
                      validate: () => {
                        if (booking.haulageType === HaulageType.Combined) {
                          return true;
                        }
                        const totalPickupContainers =
                          getValues().pickupDepot?.reduce((acc, curr) => acc + (Number(curr.containers) || 0), 0) ?? 0;
                        return (
                          totalPickupContainers === booking.numberOfContainers ||
                          `Total number of containers for pickup should be ${booking.numberOfContainers}`
                        );
                      },
                    })}
                    error={!!errors?.pickupDepot?.[index]?.containers}
                    helperText={errors?.pickupDepot?.[index]?.containers?.message}
                  />
                  <TextField
                    label='Reference'
                    autoComplete='off'
                    variant='outlined'
                    {...register(`pickupDepot.${index}.reference`)}
                  />
                  <Box>
                    <IconButton
                      type='button'
                      onClick={() => {
                        pickupDepotRemove(index);
                      }}
                      sx={{
                        bgcolor: theme.palette.secondary[400],
                        '&:hover': {
                          backgroundColor: theme.palette.secondary[500],
                        },
                      }}
                    >
                      <RemoveOutlinedIcon sx={{ color: 'white' }} />
                    </IconButton>
                  </Box>
                </Box>
              );
            })}
            <Button
              variant='contained'
              type='button'
              onClick={() => {
                dropoffDepotAppend({
                  site: undefined,
                  containers: booking.numberOfContainers,
                  reference: booking.carrierBookingNumber,
                });
              }}
              startIcon={<AddOutlinedIcon />}
              sx={{
                bgcolor: theme.palette.secondary[400],
                mb: '1rem',
                '&:hover': {
                  backgroundColor: theme.palette.secondary[500],
                },
              }}
            >
              Add Dropoff Depot
            </Button>
            {dropoffDepotFields.map((_, index) => {
              return (
                <Box
                  display='grid'
                  gridTemplateColumns='5fr 1fr 1fr 0.1fr'
                  alignItems='center'
                  gap='1rem'
                  mb='1rem'
                  key={`dropoff-${index}`}
                >
                  <Controller
                    name={`dropoffDepot.${index}.site`}
                    control={control}
                    render={({ field: { value } }) => (
                      <Autocomplete
                        disablePortal
                        value={value ? getValueAndLabelFromSite(value) : null}
                        options={(sites?.masterData ?? []).map((site: Site) => getValueAndLabelFromSite(site))}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        onChange={(_, value) => {
                          if (!value) {
                            return;
                          }
                          const newSite = sites?.masterData?.find(site => site._id === value.value);
                          if (newSite) {
                            setValue(`dropoffDepot.${index}.site`, newSite);
                          }
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label='Dropoff Depot'
                            error={!!errors?.dropoffDepot?.[index]?.site}
                            helperText={errors?.dropoffDepot?.[index]?.site?.message}
                          />
                        )}
                      />
                    )}
                  />
                  <TextField
                    label='Containers'
                    type='number'
                    autoComplete='off'
                    variant='outlined'
                    {...register(`dropoffDepot.${index}.containers`, {
                      validate: () => {
                        if (booking.haulageType === HaulageType.Combined) {
                          return true;
                        }
                        const totalDropoffContainers =
                          getValues().dropoffDepot?.reduce((acc, curr) => acc + (Number(curr.containers) || 0), 0) ?? 0;
                        return (
                          totalDropoffContainers === booking.numberOfContainers ||
                          `Total number of containers for dropoff should be ${booking.numberOfContainers}`
                        );
                      },
                    })}
                    error={!!errors?.dropoffDepot?.[index]?.containers}
                    helperText={errors?.dropoffDepot?.[index]?.containers?.message}
                  />
                  <TextField
                    label='Reference'
                    autoComplete='off'
                    variant='outlined'
                    {...register(`dropoffDepot.${index}.reference`)}
                  />
                  <Box>
                    <IconButton
                      type='button'
                      onClick={() => {
                        dropoffDepotRemove(index);
                      }}
                      sx={{
                        bgcolor: theme.palette.secondary[400],
                        '&:hover': {
                          backgroundColor: theme.palette.secondary[500],
                        },
                      }}
                    >
                      <RemoveOutlinedIcon sx={{ color: 'white' }} />
                    </IconButton>
                  </Box>
                </Box>
              );
            })}
          </form>
        </DialogContent>
        <GeneralDialogueActions onClick={handleSubmit(onSubmit)} handleClose={handleClose} submitText='Update' />
      </Dialog>
      <AlertSnackbar
        open={snackbarOpen}
        handleClose={(_: any, reason: string) => handleSnackbarClose(reason, setSnackbarOpen)}
        severity={totalNoOfContainerValidation ? 'success' : 'error'}
        message={
          totalNoOfContainerValidation
            ? `${booking.bookingType === 'Export' ? 'Loading' : 'Delivery'} Schedule Updated Successfully !`
            : `Invalid number of container: ${booking.numberOfContainers} containers are required for this booking.`
        }
      />
    </Box>
  );
};

export default UpdateTransportScheduleDialogue;
