import React, { useState, useCallback, useRef } from 'react';
import { Paper, InputBase, IconButton, MenuItem, FormControl, Select } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { debounce } from 'lodash';
import { useTheme } from '@mui/system';
import { GridColDef } from '@mui/x-data-grid';
import { FieldsToIncludeInSearchBar } from 'dataAssets/constants';

interface SearchBarProps {
  setSearchText: (value: React.SetStateAction<string>) => void;
  setSelectedField: (value: React.SetStateAction<string>) => void;
  setPage: (value: React.SetStateAction<number>) => void;
  debounceTime?: number;
  columns: GridColDef[];
}

const SearchBar: React.FC<SearchBarProps> = ({
  setSearchText,
  setSelectedField,
  setPage,
  debounceTime = 300,
  columns = [],
}) => {
  const [text, setText] = useState<string>('');
  const [isExpanded, setIsExpanded] = useState(false);
  const theme = useTheme();
  const includeFields = FieldsToIncludeInSearchBar;
  const searchableColumns = columns.filter(col => includeFields.includes(col.field));
  const [field, setField] = useState<string>(searchableColumns[0]?.field || '');
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const debouncedSearch = useCallback(
    debounce((text: string, field: string) => {
      setSearchText(text);
      setSelectedField(field);
      setPage(0);
    }, debounceTime),
    [setSearchText, setSelectedField, setPage, debounceTime],
  );

  const handleSearchIconClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsExpanded(true);
    inputRef.current?.focus();
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setText(newValue);
    debouncedSearch(newValue, field);
  };

  const handleFieldChange = (event: any) => {
    const newField = event.target.value;
    setField(newField);
    if (newField !== '' && text !== '') {
      debouncedSearch(text, newField);
    }

    setTimeout(() => {
      inputRef.current?.focus();
    }, 100);
  };

  const handleClear = (event: React.MouseEvent) => {
    event.stopPropagation();
    inputRef.current?.focus();
    setText('');
    setSearchText('');
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (field !== '' && text !== '') {
      debouncedSearch(text, field);
    }
  };

  const handleContainerClick = () => {
    if (!isExpanded) {
      setIsExpanded(true);
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
  };

  const handleSelectClose = () => {
    setIsSelectOpen(false);
    if (!text) {
      setIsExpanded(false);
    }
    setTimeout(() => {
      inputRef.current?.focus();
    }, 100);
  };

  const handleBlur = (event: React.FocusEvent) => {
    if (!isSelectOpen) {
      const searchContainer = document.getElementById('search-bar-container');
      const relatedTarget = event.relatedTarget as Node;

      if (
        !text &&
        searchContainer &&
        !searchContainer.contains(relatedTarget) &&
        !relatedTarget?.parentElement?.classList.contains('MuiMenu-list')
      ) {
        setIsExpanded(false);
      }
    }
  };

  return (
    <div
      id='search-bar-container'
      onClick={handleContainerClick}
      style={{
        width: 'fit-content',
        position: 'relative',
      }}
    >
      <Paper
        component='form'
        onSubmit={handleSubmit}
        sx={{
          backgroundColor: theme.palette.mode === 'light' ? '#dcdcdc' : 'inherit',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          width: isExpanded ? '350px' : '43px',
          maxWidth: '350px',
          transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
          overflow: 'hidden',
          borderRadius: '20px',
          boxShadow: isExpanded ? '0 2px 4px rgba(0,0,0,0.1)' : 'none',
          '&:hover': {
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
          },
          p: '2px 4px',
        }}
      >
        <IconButton
          type='submit'
          onClick={handleSearchIconClick}
          sx={{
            p: '8px',
            transition: 'opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
            opacity: isExpanded ? 1 : 0.7,
          }}
          aria-label='search'
        >
          <SearchIcon />
        </IconButton>

        <InputBase
          inputRef={inputRef}
          onBlur={handleBlur}
          sx={{
            ml: 1,
            flex: 1,
            opacity: isExpanded ? 1 : 0,
            transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
            transform: isExpanded ? 'translateX(0)' : 'translateX(-20px)',
            pointerEvents: isExpanded ? 'auto' : 'none',
          }}
          placeholder={'Search by'}
          value={text}
          onChange={handleSearchChange}
          inputProps={{ 'aria-label': 'search' }}
        />

        {text && (
          <IconButton
            type='button'
            onClick={handleClear}
            sx={{
              p: '8px',
              opacity: isExpanded ? 1 : 0,
              transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
              transform: isExpanded ? 'scale(1)' : 'scale(0.5)',
            }}
            aria-label='clear'
          >
            <ClearIcon />
          </IconButton>
        )}

        <FormControl
          onBlur={handleBlur}
          sx={{
            minWidth: 120,
            opacity: isExpanded ? 1 : 0,
            transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
          }}
        >
          <Select
            open={isSelectOpen}
            value={field}
            onChange={handleFieldChange}
            onClick={() => setIsExpanded(true)}
            onOpen={() => setIsSelectOpen(true)}
            onClose={handleSelectClose}
            sx={{
              borderRadius: '20px',
              height: '40px',
              '& .MuiSelect-select': {
                p: '5px 14px',
              },
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              PaperProps: {
                sx: {
                  maxHeight: '300px',
                },
              },
            }}
            displayEmpty
          >
            {searchableColumns.map(column => (
              <MenuItem key={column.field} value={column.field}>
                {column.headerName || column.field}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Paper>
    </div>
  );
};

export default SearchBar;
