import React, { useState } from 'react';
import DataGridCustomToolbar from '../DataGridCustomToolbar';
import { Box, useTheme } from '@mui/material';
import { DataGrid, GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import AlertSnackbar from 'components/AlertSnackbar';
import { AlertColor } from '@mui/material/Alert';

export interface CustomDatagridProps {
  data: any;
  columns: GridColDef[];
  isLoading: boolean;
  isFetching?: boolean;
  columnVisibilityModel?: GridColumnVisibilityModel;
  handleColumnVisibilityChange?: (model: GridColumnVisibilityModel) => void;
  showToolbar?: boolean;
  height?: string;
  onRowUpdate?: (newVal: any) => void;
  sortModel?: any[];
  page?: number;
  pageSize?: number;
  setPage?: (page: number) => void;
  setPageSize?: (pageSize: number) => void;
  totalRows?: number;
}

interface SnackbarState {
  open: boolean;
  color: AlertColor;
  message: string;
}

const CustomDataGrid: React.FC<CustomDatagridProps> = ({
  data,
  columns,
  isLoading,
  isFetching,
  columnVisibilityModel,
  handleColumnVisibilityChange,
  showToolbar = true,
  height = '85vh',
  onRowUpdate,
  sortModel = [],
  page,
  pageSize,
  setPage,
  setPageSize,
  totalRows,
}) => {
  const defaultSnackbarState: SnackbarState = {
    open: false,
    color: 'success',
    message: 'Unable to update row',
  };
  const theme = useTheme();
  const mode = theme.palette.mode;
  const [snackbarOpen, setSnackbarOpen] = useState<SnackbarState>(defaultSnackbarState);

  const getPageSizeOptions = () => {
    if (pageSize === undefined) return undefined;

    let options = [50];

    if (totalRows) {
      if (totalRows <= 50) options = [10, 20, 50];
      else options = [10, 20, 50, 100];
    }

    return [...new Set([...options, pageSize])].filter((option): option is number => option !== undefined);
  };

  return (
    <Box
      height={height}
      sx={{
        '& .MuiDataGrid-root': {
          border: 'none',
        },
        '& .MuiDataGrid-cell': {
          borderBottom: 'none',
        },
        '& .MuiDataGrid-columnHeaders': {
          backgroundColor: mode === 'dark' ? '#1C1C1E' : '#dcdcdc',
          // color: theme.palette.secondary[500],
          // borderBottom: 'none',
          borderRadius: '0 0 0 0',
          borderTop: '1px solid #f0f0f0',
        },
        '& .MuiDataGrid-virtualScroller': {
          backgroundColor: mode === 'dark' ? '#1A1B1D' : '#dcdcdc',
          // color: theme.palette.secondary[700],
        },
        // '& .MuiDataGrid-footerContainer': {
        //   backgroundColor: theme.palette.secondary[100],
        //   color: theme.palette.secondary[700],
        //   borderRadius: '0 0 5px 5px',
        //   borderTop: 'none',
        // },
        '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
          color:
            mode === 'dark'
              ? `${theme.palette.secondary[100]} !important`
              : `${theme.palette.secondary[900]} !important`,
        },
        '& .MuiDataGrid-toolbarContainer': {
          marginTop: 0,
        },
      }}
    >
      <DataGrid
        loading={isLoading || !data || isFetching}
        getRowId={row => row._id}
        rows={data ?? []}
        columns={columns}
        slots={showToolbar ? { toolbar: DataGridCustomToolbar } : {}}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={handleColumnVisibilityChange}
        processRowUpdate={(newVal, oldVal) => {
          if (typeof onRowUpdate === 'undefined') {
            setSnackbarOpen({
              open: true,
              message: 'onRowUpdate method is not implemented',
              color: 'error',
            });
            return oldVal;
          }
          try {
            onRowUpdate(newVal);
          } catch (e) {
            if (e instanceof Error) {
              setSnackbarOpen({
                open: true,
                message: e.message,
                color: 'error',
              });
            }
            return oldVal;
          }
          setSnackbarOpen({
            open: true,
            message: 'Successfully updated',
            color: 'success',
          });
          return newVal;
        }}
        onProcessRowUpdateError={err => {
          setSnackbarOpen({
            open: true,
            message: err.message,
            color: 'error',
          });
        }}
        initialState={{
          sorting: {
            sortModel,
          },
        }}
        density='compact'
        {...(totalRows !== undefined && {
          rowCount: totalRows,
          paginationMode: 'server',
          pagination: true,
          paginationModel: { page: page ?? 0, pageSize: pageSize ?? 50 },
          onPaginationModelChange: model => {
            setPage && setPage(model.page);
            setPageSize && setPageSize(model.pageSize);
          },
          pageSizeOptions: getPageSizeOptions(),
        })}
      />
      <AlertSnackbar
        open={snackbarOpen.open}
        handleClose={() => setSnackbarOpen(defaultSnackbarState)}
        severity={snackbarOpen.color}
        message={snackbarOpen.message}
      />
    </Box>
  );
};

export default CustomDataGrid;
