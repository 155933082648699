import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { User } from '@auth0/auth0-react';
import axios from 'axios';
import { sec } from '../../../auth/accessToken';
import { UserPreference } from 'models/index.model';
import { useForm } from 'react-hook-form';
import { useGetUserPreferenceDetailsQuery } from 'state/api';
import CircularProgress from '@mui/material/CircularProgress';
import Header from '../../../components/Header';
import LoadingButton from 'components/LoadingButton';
import { Box, TextField, Autocomplete, Divider, Typography } from '@mui/material';
import { countries } from '../../../dataAssets/constants';
import { CountryType } from 'models/index.model';
import ThemeSwitch from 'components/ThemeSwitch';
import { useDispatch, useSelector } from 'react-redux';
import { apiCall } from 'utils/serverFunctions';
import { setMode } from 'state';
import { useTheme } from '@mui/system';

const UserProfile = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const s_darkMode = useSelector((state: any) => state.global.mode);
  const [currentUser, setCurrentUser] = useState<User>({});
  const { data: userPref, refetch, isLoading, isSuccess } = useGetUserPreferenceDetailsQuery(currentUser.email ?? '');
  const [country, setCountry] = useState<CountryType | null>(null);
  const [darkMode, setDarkMode] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', darkMode ? 'dark' : 'light');
  }, [darkMode]);

  useEffect(() => {
    setDarkMode(s_darkMode === 'dark' ? true : false);
  }, [s_darkMode]);

  const handleModeChange = async (mode: boolean) => {
    setDarkMode(mode);
    const data = mode ? 'dark' : 'light';
    dispatch(setMode(data));
    await postModeChange(data);
  };

  const useFormReference = useForm<UserPreference>({
    defaultValues: { email: '', firstName: '', lastName: '', nickname: '' },
  });

  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useFormReference;

  React.useEffect(() => {
    if (location.state?.user) {
      setCurrentUser(location.state.user);
      setValue('email', location.state.user.email);
    }
    if (userPref) {
      setValue('firstName', userPref.firstName === undefined ? '' : `${userPref.firstName}`);
      setValue('lastName', userPref.lastName === undefined ? '' : `${userPref.lastName}`);
      setValue('nickname', userPref.nickname === undefined ? '' : `${userPref.nickname}`);

      if (userPref.phoneNumber) {
        const phoneNumber = Number(userPref.phoneNumber.toString().slice(userPref.phoneNumber.toString().length - 10));
        const countryCode = userPref.phoneNumber.toString().slice(0, userPref.phoneNumber.toString().length - 10);
        const country = countries.filter(country => country.phone.toString() === countryCode.toString());
        setCountry(country[0]);
        setValue('phoneNumber', phoneNumber);
      }
    }
  }, [location, userPref, countries]);

  const handleCountryChange = (value: CountryType | null) => {
    if (value) {
      setCountry(value);
    }
  };

  const postModeChange = async (mode: string) => {
    try {
      if (isSuccess) {
        await apiCall('/api/v1/userProfile', {
          method: 'PATCH',
          data: {
            mode: mode,
            email: currentUser.email,
          },
        });
      } else {
        await apiCall('/api/v1/userProfile', {
          method: 'POST',
          data: {
            mode: mode,
            email: currentUser.email,
          },
        });
      }
    } catch (error) {
      console.error('Error updating mode:', error);
    }
  };

  const onSubmit = async (data: UserPreference) => {
    const accessToken = await sec.getAccessTokenSilently()();
    const formattedPhoneNumber = data.phoneNumber ? `${country?.phone}${data.phoneNumber.toString()}` : undefined;
    const formData = { ...data, phoneNumber: formattedPhoneNumber };

    try {
      if (isSuccess) {
        await axios.patch(`${process.env.REACT_APP_BASE_URL}/api/v1/userProfile`, formData, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      } else {
        await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/userProfile`, formData, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      refetch();
    }
  };

  if (isLoading) {
    return (
      <Box height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box className='p-4'>
      <Header title='User Profile' />
      <Box className='h-full w-full flex flex-col gap-4 place-content-evenly mt-8'>
        <TextField
          autoComplete='off'
          type='string'
          label='First Name'
          {...register('firstName', {
            required: 'First name is required.',
          })}
          error={!!errors.firstName}
          helperText={errors.firstName?.message}
        />
        <TextField
          autoComplete='off'
          type='string'
          label='Last Name'
          {...register('lastName', {
            required: 'Last name is required.',
          })}
          error={!!errors.lastName}
          helperText={errors.lastName?.message}
        />
        <Box className='flex flex-row justify-between w-full'>
          <Autocomplete
            sx={{ width: '20%', marginRight: '1rem' }}
            value={country}
            options={countries}
            autoHighlight
            getOptionLabel={option => option.label}
            isOptionEqualToValue={(option, value) => option.label === value.label}
            onChange={(_, value) => {
              handleCountryChange(value);
            }}
            renderOption={(props, option) => {
              const { ...optionProps } = props;
              return (
                <Box key={option.id} component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...optionProps}>
                  <img
                    loading='lazy'
                    width='20'
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    alt=''
                  />
                  {option.label} ({option.code}) +{option.phone}
                </Box>
              );
            }}
            renderInput={params => (
              <TextField
                {...params}
                label='Country'
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'disable',
                }}
              />
            )}
          />
          <TextField
            sx={{ width: '80%' }}
            autoComplete='off'
            type='number'
            label='Phone Number'
            {...register('phoneNumber', {
              maxLength: { value: 10, message: 'Phone number must not exceed 10 digits.' },
              minLength: { value: 10, message: 'Phone number must be of 10 digits.' },
            })}
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber?.message}
            InputProps={{
              sx: {
                '& input[type=number]': {
                  MozAppearance: 'textfield', // Firefox
                },
                '& input[type=number]::-webkit-outer-spin-button': {
                  WebkitAppearance: 'none',
                  margin: 0,
                },
                '& input[type=number]::-webkit-inner-spin-button': {
                  WebkitAppearance: 'none',
                  margin: 0,
                },
              },
            }}
          />
        </Box>
        <TextField
          autoComplete='off'
          type='string'
          label='Nick Name'
          {...register('nickname')}
          error={!!errors.nickname}
          helperText={errors.nickname?.message}
        />
      </Box>
      <Box className='py-4'>
        <LoadingButton
          loadingText='Updating'
          text='Update'
          sx={theme => ({
            color: theme.palette.primary[700],
            bgcolor: theme.palette.secondary[200],
            '&:hover': { bgcolor: theme.palette.secondary[300] },
          })}
          onClick={handleSubmit(onSubmit)}
        />
      </Box>
      <Divider>Theme</Divider>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        gap={'1rem'}
        width={'100%'}
        border={theme.palette.mode === 'dark' ? '1px solid #656565' : '1px solid #bdbdbd'}
        borderRadius={1}
        py={1}
        px={2}
        mt={1}
        sx={{
          '&:hover': {
            border: theme.palette.mode === 'dark' ? '1px solid #ffffff' : '1px solid #202020',
          },
        }}
      >
        <Typography>Mode</Typography>
        <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'} gap={'1rem'}>
          <Typography>Light</Typography>
          <ThemeSwitch darkMode={darkMode} onChange={handleModeChange} />
          <Typography>Dark</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default UserProfile;
