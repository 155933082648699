import { Box } from '@mui/material';
import { styled, useTheme } from '@mui/system';

const theme = useTheme;

const AnalyticsCard = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.alt,
  borderRadius: '0.5rem',
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0.15rem 0.2rem 0.15rem 0.1rem rgba(0, 0, 0, .8)'
      : '0.15rem 0.2rem 0.35rem 0.1rem rgba(0, 0, 0, .5)',
}));

export default AnalyticsCard;
