import { Booking, Charge, DeadlineData, TransportSite } from './booking.model';

import dayjs, { Dayjs } from 'dayjs';
import { Invoice } from './booking.model';

export interface Contact {
  _id: string;
  name: string;
  email: string;
  phoneNumber?: string;
  role: string;
}
export interface EmailConfig {
  name: string;
  from: string[];
  to: string[];
  cc: string[];
}

interface DBUser {
  _id: string;
  name: string;
  address: string;
  city: string;
  postcode: string;
  country: string;
  creditPeriod: number;
  creditLimit: number;
  contacts: Contact[];
  emailConfig?: EmailConfig[];
}

export interface Vendor extends DBUser {
  vendorType: VendorType;
}

export interface UserPreference {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  nickname: string;
  phoneNumber: number;
  signature: string;
  bookingsColumnVisibilityModel: Record<string, boolean>;
  enquiriesColumnVisibilityModel: Record<string, boolean>;
  bookingsFilters: Record<string, string>;
  enquiriesFilters: Record<string, string>;
}

export interface Customer extends DBUser {
  EORI?: string;
}
export interface Site {
  _id: string;
  address: string;
  city: string;
  postcode: string;
  country: string;
  siteName?: string;
}

export interface Token {
  _id: string;
  refreshToken: string;
  accessToken: string;
  tokenType?: string;
  expiresIn: number;
}

export interface Oauth {
  _id: string;
  provider: string;
  clientId: string;
  clientSecret: string;
  redirectUrl: string;
  scopes: string[];
  state?: string;
  authUrl: string;
  tokenUrl: string;
  tokens: Token[];
}

export interface Port {
  _id: string;
  portName: string;
  country: string;
  portCode: string;
}

export interface BaseDialogueProps {
  handleClose: Function;
  open: boolean;
}

export type ValueAndLabel<T> = {
  value: T;
  label: string;
};

export enum VendorType {
  Carrier = 'Carrier',
  Haulier = 'Haulier',
  Agent = 'Agent',
}

export enum FilterType {
  Bookings = 'Bookings',
  Enquiries = 'Enquiries',
}

export interface Enquiry {
  _id: string;
  enquiryNumber: string;
  bookingType: String;
  bookingTeam: string;
  dateCreated: string;
  customer: Customer;
  volume: number;
  containerType: string;
  portOfLoading: Port;
  portOfDestination: Port;
  fpod: Port;
  carrier: Vendor;
  carrierBookingNumber: string;
  purchaseOrderNumber: string;
  agent: Vendor;
  enquiryStatus: string;
  pic: Contact;
  buyRates: Charge[];
  sellRates: Charge[];
  sites: TransportSite[];
  enquiryRemarks: enquiryRemarks[];
  cargoDescription: string;
  incoterm: string;
}

export interface enquiryRemarks {
  value: string;
}
export interface CountryType {
  id: number;
  code: string;
  label: string;
  phone: string;
  suggested?: boolean;
}

export interface ChargeName {
  _id?: string;
  chargeName: string;
  chargeType: string;
  defaultBase: string;
}

export interface InvoicesRefs {
  invoiceRef: Invoice;
  amount: number;
}

export interface IAdvancesRefs {
  advanceRef: IAdvPaymentsResponse;
  amount: number;
}

export interface IReceiptsAndPayments {
  _id: string;
  outstandingAmount: number;
  dateCreated: string;
  invoicesRefs: InvoicesRefs[];
  advancesRefs: IAdvancesRefs[];
  accounts: IBankAccounts;
  totalAmount: number;
  party: Customer | Vendor;
  type: string;
  remarks?: string;
}

export enum AccountsSettlementType {
  Receipt = 'receipt',
  Payment = 'payment',
}

export interface IBankAccounts {
  _id: string;
  bank: string;
  branch: string;
  address: string;
  accountNumber: string;
  currency: string;
  iban?: string;
  sortCode?: string;
  swiftBic?: string;
}

export interface IAdvpayments {
  _id: string;
  amount: number;
  dateCreated: string;
  currency: string;
  bankAccount: IBankAccounts;
  party: Customer | Vendor;
  remarks?: string;
}

export interface IAdvPaymentsResponse extends IAdvpayments {
  advPaymentNumber: string;
  outstandingAmount: number;
  settlementOutstanding: boolean;
}

export interface IGetAllBookingsResponse {
  filteredBookings: Booking[];
  totalBookings: number;
}

export interface IGetAllEnquiriesResponse {
  enquiries: Enquiry[];
  totalEnquiries: number;
}
export interface IGetAllBookingsWithDeadlinesResponse {
  formattedDeadlines: DeadlineData[];
  totalBookingsWithDeadlines: number;
}

export interface IGetAllBookingLoadingsResponse {
  bookings: Booking[];
  totalBookings: number;
}

export interface IGetMasterDataResponse {
  masterData: any[];
  totalRecords: number;
}

export interface IGetAllAccountingsResponse {
  invoices: any[];
  totalInvoices: number;
}

export interface IGetAllReceiptsAndPaymentsResponse {
  receiptsAndPayments: IReceiptsAndPayments[];
  totalReceiptsAndpayments: number;
}

export interface IGetAllAdvancesResponse {
  advancePayments: IAdvPaymentsResponse[];
  totalAdvancePayments: number;
}

export interface IGetUserPreferenceResponse {
  masterData: UserPreference[];
  totalRecords: number;
}
