import React, { useState } from 'react';

const ThemeSwitch: React.FC<{ darkMode: boolean; onChange: (mode: boolean) => void }> = ({ darkMode, onChange }) => {
  return (
    <label className='relative block w-14 h-8'>
      <input
        type='checkbox'
        className='sr-only'
        checked={darkMode}
        onChange={e => {
          onChange(e.target.checked);
        }}
      />
      <div
        className={`
          absolute cursor-pointer inset-0 
          rounded-full transition-colors duration-800 ease-in-out
          ${darkMode ? 'bg-[#616161]' : 'bg-[#e0e0e0]'}
        `}
      >
        <div
          className={`
            absolute w-6 h-6 rounded-full 
            top-1/2 -translate-y-1/2 transition-all duration-800 ease-in-out
            ${
              darkMode
                ? 'right-1 bg-[#616161] shadow-[inset_-3px_-2px_5px_-2px_#8983f7,inset_-10px_-4px_0_0_#a3dafb]'
                : 'left-1 bg-gradient-to-r from-[#ff0080] to-[#ff8c00]'
            }
          `}
        />
      </div>
    </label>
  );
};

export default ThemeSwitch;
