import AddIcon from '@mui/icons-material/Add';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { sec } from 'auth/accessToken';
import axios from 'axios';
import AlertSnackbar from 'components/AlertSnackbar';
import ConfirmationDialog from 'components/ConfirmationDialog';
import GeneralDialogueActions from 'components/GeneralDialogueActions';
import dayjs from 'dayjs';
import { Booking, Charge, Invoice, TeamType, TransportSite } from 'models/booking.model';
import { BaseDialogueProps, Customer, Enquiry, Port, Site, Vendor, VendorType } from 'models/index.model';
import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  useGetCustomersQuery,
  useGetPortsQuery,
  useGetSitesQuery,
  useGetUserPreferenceQuery,
  useGetVendorsQuery,
} from 'state/api';
import { BaseIsolatedReRender } from 'utils/renderBase';
import {
  getValueAndLabelFromCustomer,
  getValueAndLabelFromPort,
  getValueAndLabelFromSite,
  getValueAndLabelFromVendor,
} from 'utils/utils';
import {
  bases,
  bookingTypes,
  containerTypes,
  currencies,
  enquiryStatuses,
  exportBookingTeams,
  importBookingTeams,
  incoterms,
} from '../../dataAssets/constants';
import { generateMenuItemsFromArray } from '../../utils/generateMenuItemsFromArray';
import { setEnquiryDataToBook, setSnackbarSettings } from 'state';
import { useNavigate } from 'react-router-dom';
import ChargeNameAutocomplete from 'components/ChargeNameAutocomplete';
import { Hbl } from 'models/hbl.model';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

export interface EnquiryDialogueProps extends BaseDialogueProps {
  enquiryToUpdate?: Enquiry;
  useFormReference: UseFormReturn<Enquiry>;
  hasCreateExportEnquiryPermission: boolean;
  hasCreateImportEnquiryPermission: boolean;
  hasExportPermission: boolean;
  hasImportPermission: boolean;
  defaultEnquiryValues: any;
}

const CreateEnquiryDialogue: React.FC<EnquiryDialogueProps> = ({
  useFormReference,
  handleClose,
  open,
  enquiryToUpdate,
  hasCreateExportEnquiryPermission,
  hasCreateImportEnquiryPermission,
  hasExportPermission,
  hasImportPermission,
  defaultEnquiryValues,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isEditing = !!enquiryToUpdate?._id;
  const [inView, setInView] = useState(true);
  const [openConfirmDialogue, setOpenConfirmDialogue] = useState(false);
  const [updatedEnquiryStatus, setUpdatedEnquiryStatus] = useState<string>('');
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
    getValues,
  } = useFormReference;
  const { data: customers } = useGetCustomersQuery({});
  const { data: ports } = useGetPortsQuery({});
  const { data: vendors } = useGetVendorsQuery({});
  const { data: sites } = useGetSitesQuery({});

  const userEmail = useSelector((state: any) => state.global.userEmail);
  const { snackbarOpen, snackbarSeverity, message } = useSelector((state: any) => state.global.snackbarSettings);
  const watchedBookingType = watch('bookingType');
  const noOfContainers = watch('volume');
  const siteContainers = watch('sites');
  const { data: userPreferenceArr } = useGetUserPreferenceQuery();
  const availableEmails = React.useMemo(() => {
    return userPreferenceArr?.masterData?.map(pref => pref.email) || [];
  }, [userPreferenceArr]);

  useEffect(() => {
    if (enquiryToUpdate?._id) {
      reset(enquiryToUpdate);
    } else {
      reset(defaultEnquiryValues);
    }
  }, [enquiryToUpdate, reset]);

  useEffect(() => {
    setValue('bookingType', hasExportPermission || !hasImportPermission ? 'Export' : 'Import');
    setValue('bookingTeam', hasExportPermission || !hasImportPermission ? TeamType.Rockers : TeamType.Falcons);
  }, [hasExportPermission, hasImportPermission, setValue]);

  const {
    fields: sellFields,
    append: sellAppend,
    remove: sellRemove,
  } = useFieldArray({
    control,
    name: 'sellRates',
  });

  const {
    fields: buyFields,
    append: buyAppend,
    remove: buyRemove,
  } = useFieldArray({
    control,
    name: 'buyRates',
  });

  const {
    fields: remarkFields,
    append: remarkAppend,
    remove: remarkRemove,
  } = useFieldArray({
    control,
    name: 'enquiryRemarks',
  });

  const {
    fields: sitesFields,
    append: sitesAppend,
    remove: sitesRemove,
  } = useFieldArray({
    control,
    name: 'sites',
  });

  const handleSnackbarClose = (event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(setSnackbarSettings({ snackbarOpen: false }));
  };

  const handleCustomerChange = (value: { value: string; label: string } | null) => {
    if (!value) {
      return;
    }

    const newCustomer = customers?.masterData?.find(customer => customer._id === value.value);
    if (newCustomer) {
      setValue('customer', newCustomer);
    }
  };

  const handlePortChange = (portType: string, value: { value: string; label: string } | null) => {
    if (!value) {
      return;
    }

    const newPort = ports?.masterData?.find(port => port._id === value.value);
    if (newPort) {
      setValue(portType as keyof Enquiry, newPort);
    }
  };

  const handleCarrierChange = (vendorType: string, value: { value: string; label: string } | null) => {
    if (!value) {
      return;
    }

    const newVendor = vendors?.masterData?.find(vendor => vendor._id === value.value);
    if (newVendor) {
      setValue(vendorType as keyof Enquiry, newVendor);
    }
  };

  const onSubmit = async (data: Enquiry) => {
    if (!containerValidation()) {
      dispatch(
        setSnackbarSettings({
          snackbarOpen: true,
          snackbarSeverity: 'error',
          message: 'Mismatch between the number of containers and the total number of containers across sites.',
        }),
      );
      return;
    }
    try {
      const accessToken = await sec.getAccessTokenSilently()();
      let apiUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/enquiries/`;

      if (isEditing) {
        apiUrl += enquiryToUpdate._id;
        await axios.patch(apiUrl, data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      } else {
        await axios.post(apiUrl, data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      }
      dispatch(
        setSnackbarSettings({
          snackbarOpen: true,
          snackbarSeverity: 'success',
          message: isEditing ? 'Enquiry updated' : 'Enquiry created',
        }),
      );
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };
  const [updatedEnquiryData, setUpdatedEnquiryData] = useState<Enquiry>();

  const handleDataUpdate = (data: Enquiry) => {
    setUpdatedEnquiryData(data);
    setOpenConfirmDialogue(true);
  };

  const handleDataForwardToBooking = async (data: Enquiry | undefined) => {
    const accessToken = await sec.getAccessTokenSilently()();
    let apiUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/enquiries/`;

    if (data?._id) {
      apiUrl += data._id;
      await axios.patch(apiUrl, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    }
    dispatch(setEnquiryDataToBook(data));
    navigate('/bookings/create');
  };

  const containerValidation = () => {
    const totalContainerNumber = siteContainers?.reduce(
      (numberOfContainers, transportSite) => numberOfContainers + Number(transportSite.containers),
      0,
    );
    if (Number(totalContainerNumber) === Number(noOfContainers)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Box>
      <Dialog
        sx={{ visibility: inView ? 'visible' : 'hidden' }}
        open={open}
        onClose={() => handleClose()}
        fullWidth
        maxWidth='xl'
      >
        <DialogTitle
          sx={{
            fontSize: '1.6rem',
          }}
        >
          {isEditing ? `ENQUIRY DETAILS - ${enquiryToUpdate?.enquiryNumber}` : 'Create Enquiry'}
        </DialogTitle>
        <DialogContent>
          <form>
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} height={'5rem'}>
              <Box display={'flex'} alignItems={'center'} gap={'1rem'}>
                {hasCreateExportEnquiryPermission && hasCreateImportEnquiryPermission && (
                  <FormControl component='fieldset'>
                    <FormLabel component='legend'>Booking Type</FormLabel>
                    <Controller
                      name='bookingType'
                      control={control}
                      render={({ field }) => (
                        <RadioGroup row {...field}>
                          {bookingTypes.map(bookingType => (
                            <FormControlLabel
                              key={bookingType}
                              value={bookingType}
                              control={<Radio />}
                              label={bookingType}
                            />
                          ))}
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                )}

                <Controller
                  name='bookingTeam'
                  control={control}
                  render={({ field }) => (
                    <TextField {...field} sx={{ width: '10rem' }} variant='standard' select label='Team *'>
                      {generateMenuItemsFromArray(
                        watchedBookingType === 'Export' ? exportBookingTeams : importBookingTeams,
                      )}
                    </TextField>
                  )}
                />
              </Box>
              <Controller
                name='enquiryStatus'
                control={control}
                defaultValue={enquiryStatuses[0]}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    label='Status'
                    sx={{ width: '10rem' }}
                    autoComplete='off'
                    onChange={e => {
                      setUpdatedEnquiryStatus(e.target.value);
                      setValue('enquiryStatus', e.target.value);
                    }}
                  >
                    {generateMenuItemsFromArray(enquiryStatuses)}
                  </TextField>
                )}
              />
            </Box>
            <Divider>Enquiry Details</Divider>
            <Box display={'grid'} gridTemplateColumns='repeat(3, 1fr)' gap='1rem' m='1rem 0'>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  control={control}
                  name={'dateCreated'}
                  rules={{ required: 'Date of enquiry is required' }}
                  render={({ field: { onChange, value, ref } }) => (
                    <DatePicker
                      format='DD/MM/YYYY'
                      label={'Date of Enquiry *'}
                      value={value ? dayjs(value) : null}
                      inputRef={ref}
                      onChange={date => onChange(dayjs(date).format())}
                    />
                  )}
                />
              </LocalizationProvider>

              <Controller
                control={control}
                name={'customer'}
                rules={{ required: 'Customer is required' }}
                render={({ field: { value } }) => (
                  <Autocomplete
                    value={value ? getValueAndLabelFromCustomer(value) : null}
                    onChange={(_, value) => {
                      handleCustomerChange(value);
                    }}
                    disablePortal
                    options={(customers?.masterData ?? []).map((customer: Customer) => ({
                      value: customer._id,
                      label: customer.name,
                    }))}
                    isOptionEqualToValue={(option, value) => option.value === value?.value}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label='Customer *'
                        error={!!errors.customer}
                        helperText={errors.customer?.message}
                      />
                    )}
                  />
                )}
              />

              <Controller
                control={control}
                name={'agent'}
                render={({ field: { value } }) => (
                  <Autocomplete
                    value={value ? getValueAndLabelFromVendor(value) : null}
                    options={(vendors?.masterData ?? [])
                      .filter((agent: Vendor) => agent.vendorType === VendorType.Agent)
                      .map((agent: Vendor) => getValueAndLabelFromVendor(agent))}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    onChange={(_, value) => {
                      handleCarrierChange('agent', value);
                    }}
                    renderInput={params => <TextField {...params} label='Agent' />}
                  />
                )}
              />

              <Controller
                control={control}
                name={'portOfLoading'}
                rules={{ required: 'Port of Loading is required' }}
                render={({ field: { value } }) => (
                  <Autocomplete
                    value={value ? getValueAndLabelFromPort(value) : null}
                    onChange={(_, value) => {
                      handlePortChange('portOfLoading', value);
                    }}
                    options={(ports?.masterData ?? []).map((port: Port) => getValueAndLabelFromPort(port))}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label='POL *'
                        error={!!errors.portOfLoading}
                        helperText={errors.portOfLoading?.message}
                      />
                    )}
                  />
                )}
              />

              <Controller
                control={control}
                name={'portOfDestination'}
                rules={{ required: 'Port of Destination is required' }}
                render={({ field: { value } }) => (
                  <Autocomplete
                    value={value ? getValueAndLabelFromPort(value) : null}
                    options={(ports?.masterData ?? []).map((port: Port) => getValueAndLabelFromPort(port))}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    onChange={(_, value) => {
                      handlePortChange('portOfDestination', value);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label='POD *'
                        error={!!errors.portOfDestination}
                        helperText={errors.portOfDestination?.message}
                      />
                    )}
                  />
                )}
              />

              <Controller
                control={control}
                name={'fpod'}
                rules={{ required: 'Final Place of Delivery is required' }}
                render={({ field: { value } }) => (
                  <Autocomplete
                    value={value ? getValueAndLabelFromPort(value) : null}
                    options={(ports?.masterData ?? []).map((port: Port) => getValueAndLabelFromPort(port))}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    onChange={(_, value) => {
                      handlePortChange('fpod', value);
                    }}
                    renderInput={params => (
                      <TextField {...params} label='FPOD *' error={!!errors.fpod} helperText={errors.fpod?.message} />
                    )}
                  />
                )}
              />

              <Controller
                name='containerType'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    label='Container Type *'
                    autoComplete='off'
                    {...register('containerType', {
                      required: 'container type is required',
                    })}
                    error={!!errors.containerType}
                    helperText={errors.containerType?.message}
                  >
                    {generateMenuItemsFromArray(containerTypes)}
                  </TextField>
                )}
              />

              <TextField
                label='No. of Containers *'
                type='number'
                autoComplete='off'
                {...register('volume', {
                  required: 'No. of Containers is required',
                })}
                error={!!errors.volume}
                helperText={errors.volume?.message}
              />

              <Controller
                name='cargoDescription'
                defaultValue=''
                control={control}
                render={({ field }) => <TextField {...field} label='Cargo Description' autoComplete='off' fullWidth />}
              />

              <Controller
                control={control}
                name={'carrier'}
                render={({ field: { value } }) => (
                  <Autocomplete
                    value={value ? getValueAndLabelFromVendor(value) : null}
                    options={(vendors?.masterData ?? [])
                      .filter((carrier: Vendor) => carrier.vendorType === VendorType.Carrier)
                      .map((carrier: Vendor) => getValueAndLabelFromVendor(carrier))}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    onChange={(_, value) => {
                      handleCarrierChange('carrier', value);
                    }}
                    renderInput={params => <TextField {...params} label='Carrier' />}
                  />
                )}
              />

              <TextField label='Carrier Booking Number' autoComplete='off' {...register('carrierBookingNumber')} />

              <TextField label='PO Number' autoComplete='off' {...register('purchaseOrderNumber')} />

              <Controller
                name='pic'
                control={control}
                defaultValue={userEmail}
                render={({ field }) => (
                  <TextField {...field} select label='Person In Charge' autoComplete='off'>
                    {generateMenuItemsFromArray(availableEmails)}
                  </TextField>
                )}
              />
              <Controller
                name='incoterm'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <TextField {...field} select label='Incoterm' autoComplete='off'>
                    {generateMenuItemsFromArray(incoterms)}
                  </TextField>
                )}
              />
            </Box>
            <Divider>Quotation Details</Divider>
            <Box>
              <Box
                m={'1rem 0'}
                overflow={'auto'}
                padding={'1rem'}
                border={'0.5px solid rgba(255, 255, 255, 0.12)'}
                borderRadius={'11px'}
              >
                <Box display={'flex'} justifyContent={'space-between'}>
                  <Typography variant='h5'>Buy Charges</Typography>
                  <Button
                    variant='contained'
                    type='button'
                    onClick={() => buyAppend({} as Charge)}
                    sx={{
                      bgcolor: theme.palette.secondary[400],
                      '&:hover': {
                        backgroundColor: theme.palette.secondary[500],
                      },
                    }}
                  >
                    Add
                  </Button>
                </Box>
                <Box m={'1rem 0'}>
                  {buyFields.map((item, index) => {
                    return (
                      <Box
                        display='grid'
                        gridTemplateColumns={'40fr 30fr 10fr 10fr 5fr 5fr'}
                        alignItems={'center'}
                        gap={'1rem'}
                        mt={'1rem'}
                        key={item.id}
                      >
                        <ChargeNameAutocomplete
                          control={control}
                          name={`buyRates.${index}.chargeName`}
                          chargeType='Purchase'
                        />
                        <TextField
                          label='Additional Details'
                          autoComplete='off'
                          {...register(`buyRates.${index}.additionalDetails`)}
                        />
                        <Controller
                          name={`buyRates.${index}.currency`}
                          control={control}
                          defaultValue={currencies[0]}
                          render={({ field }) => (
                            <TextField {...field} select autoComplete='off' label='Currency *'>
                              {generateMenuItemsFromArray(currencies)}
                            </TextField>
                          )}
                        />
                        <TextField
                          autoComplete='off'
                          type='number'
                          label='Value *'
                          {...register(`buyRates.${index}.rate`, {
                            required: 'Value is required',
                          })}
                          error={!!errors?.buyRates?.[index]?.rate}
                          helperText={errors?.buyRates?.[index]?.rate?.message}
                        />
                        <BaseIsolatedReRender
                          form={
                            useFormReference as UseFormReturn<
                              Invoice | Booking | Enquiry | Hbl,
                              any,
                              Invoice | Booking | Enquiry | Hbl
                            >
                          }
                          index={index}
                          bases={bases}
                          formPath='buyRates'
                        />
                        <Button
                          variant='contained'
                          type='button'
                          onClick={() => buyRemove(index)}
                          sx={{
                            bgcolor: theme.palette.secondary[400],
                            '&:hover': {
                              backgroundColor: theme.palette.secondary[500],
                            },
                          }}
                        >
                          Delete
                        </Button>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
              <Box
                m={'1rem 0'}
                overflow={'auto'}
                padding={'1rem'}
                border={'0.5px solid rgba(255, 255, 255, 0.12)'}
                borderRadius={'11px'}
              >
                <Box display={'flex'} justifyContent={'space-between'}>
                  <Typography variant='h5'>Sale Charges</Typography>
                  <Button
                    variant='contained'
                    type='button'
                    onClick={() => sellAppend({} as Charge)}
                    sx={{
                      bgcolor: theme.palette.secondary[400],
                      '&:hover': {
                        backgroundColor: theme.palette.secondary[500],
                      },
                    }}
                  >
                    Add
                  </Button>
                </Box>
                <Box m={'1rem 0'}>
                  {sellFields.map((item, index) => {
                    return (
                      <Box
                        display='grid'
                        gridTemplateColumns={'40fr 30fr 10fr 10fr 5fr 5fr'}
                        alignItems={'center'}
                        gap={'1rem'}
                        mt={'1rem'}
                        key={item.id}
                      >
                        <ChargeNameAutocomplete
                          control={control}
                          name={`sellRates.${index}.chargeName`}
                          chargeType='Sales'
                        />
                        <TextField
                          label='Additional Details'
                          autoComplete='off'
                          {...register(`sellRates.${index}.additionalDetails`)}
                        />
                        <Controller
                          name={`sellRates.${index}.currency`}
                          control={control}
                          defaultValue={currencies[0]}
                          render={({ field }) => (
                            <TextField {...field} select autoComplete='off' label='Currency *'>
                              {generateMenuItemsFromArray(currencies)}
                            </TextField>
                          )}
                        />
                        <TextField
                          autoComplete='off'
                          type='number'
                          label='Value *'
                          {...register(`sellRates.${index}.rate`, {
                            required: 'Value is required',
                          })}
                          error={!!errors?.sellRates?.[index]?.rate}
                          helperText={errors?.sellRates?.[index]?.rate?.message}
                        />
                        <BaseIsolatedReRender
                          form={
                            useFormReference as UseFormReturn<
                              Invoice | Booking | Enquiry | Hbl,
                              any,
                              Invoice | Booking | Enquiry | Hbl
                            >
                          }
                          index={index}
                          bases={bases}
                          formPath='sellRates'
                        />
                        <Button
                          variant='contained'
                          type='button'
                          onClick={() => sellRemove(index)}
                          sx={{
                            bgcolor: theme.palette.secondary[400],
                            '&:hover': {
                              backgroundColor: theme.palette.secondary[500],
                            },
                          }}
                        >
                          Delete
                        </Button>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Box>
            <Divider>Transport Site Details</Divider>
            <Box
              m={'1rem 0'}
              overflow={'auto'}
              padding={'1rem'}
              border={'0.5px solid rgba(255, 255, 255, 0.12)'}
              borderRadius={'11px'}
            >
              <Box display={'flex'} justifyContent={'space-between'}>
                <Typography variant='h5'>Sites</Typography>
                <Button
                  id='add_site'
                  variant='contained'
                  type='button'
                  onClick={() => sitesAppend({} as TransportSite)}
                  sx={{
                    bgcolor: theme.palette.secondary[400],
                    '&:hover': {
                      backgroundColor: theme.palette.secondary[500],
                    },
                  }}
                >
                  Add
                </Button>
              </Box>
              <Box m={'1rem 0'}>
                {sitesFields.map((item, index) => {
                  return (
                    <Box
                      display='grid'
                      gridTemplateColumns={'75fr 20fr 5fr'}
                      alignItems={'center'}
                      gap={'1rem'}
                      mt={'1rem'}
                      key={item.id}
                    >
                      <Grid item xs={9}>
                        <Controller
                          name={`sites.${index}.site`}
                          control={control}
                          defaultValue={item.site}
                          render={({ field: { value, onChange } }) => (
                            <Autocomplete
                              value={value ? getValueAndLabelFromSite(value) : null}
                              options={(sites?.masterData ?? []).map((site: Site) => getValueAndLabelFromSite(site))}
                              isOptionEqualToValue={(option, value) => option.label === value.label}
                              onChange={(_, newValue) => {
                                const selectedSite = newValue
                                  ? sites?.masterData?.find(site => site._id === newValue.value)
                                  : null;
                                onChange(selectedSite);
                              }}
                              renderInput={params => <TextField {...params} label='Site *' />}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          id='noc'
                          autoComplete='off'
                          type='number'
                          label='No. of Containers *'
                          {...register(`sites.${index}.containers`, {
                            required: 'Value is required',
                          })}
                          sx={{ width: '100%' }}
                          error={!!errors?.sites?.[index]?.containers}
                          helperText={errors?.sites?.[index]?.containers?.message}
                        />
                      </Grid>
                      <Button
                        variant='contained'
                        type='button'
                        onClick={() => sitesRemove(index)}
                        sx={{
                          bgcolor: theme.palette.secondary[400],
                          '&:hover': {
                            backgroundColor: theme.palette.secondary[500],
                          },
                        }}
                      >
                        Delete
                      </Button>
                    </Box>
                  );
                })}
              </Box>
            </Box>

            <Divider>Additional Remarks</Divider>
            <Box
              m={'1rem 0'}
              overflow={'auto'}
              padding={'1rem'}
              border={'0.5px solid rgba(255, 255, 255, 0.12)'}
              borderRadius={'11px'}
            >
              <Box display={'flex'} justifyContent={'space-between'}>
                <Typography variant='h5'>Remarks</Typography>
                <Button
                  id='add_remarks'
                  variant='contained'
                  type='button'
                  onClick={() => remarkAppend({ value: '' })}
                  sx={{
                    bgcolor: theme.palette.secondary[400],
                    '&:hover': {
                      backgroundColor: theme.palette.secondary[500],
                    },
                  }}
                >
                  Add
                </Button>
              </Box>
              <Box m={'1rem 0'}>
                {remarkFields.map((item, index) => {
                  return (
                    <Box
                      display='grid'
                      gridTemplateColumns={'95fr 5fr'}
                      alignItems={'center'}
                      gap={'1rem'}
                      mt={'1rem'}
                      key={item.id}
                    >
                      <Grid item xs={11}>
                        <Controller
                          name={`enquiryRemarks.${index}.value`}
                          control={control}
                          defaultValue={item.value}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth
                              autoComplete='off'
                              label={`Remark ${index + 1}`}
                              variant='outlined'
                              sx={{
                                height: '100%',
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Button
                        variant='contained'
                        type='button'
                        onClick={() => remarkRemove(index)}
                        sx={{
                          bgcolor: theme.palette.secondary[400],
                          '&:hover': {
                            backgroundColor: theme.palette.secondary[500],
                          },
                        }}
                      >
                        Delete
                      </Button>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <GeneralDialogueActions
            onClick={handleSubmit(updatedEnquiryStatus === 'Completed' ? handleDataUpdate : onSubmit)}
            handleClose={() => {
              setUpdatedEnquiryStatus('');
              handleClose();
            }}
            submitText={isEditing ? (updatedEnquiryStatus === 'Completed' ? 'Create Booking' : 'Edit') : 'Create'}
          />
        </DialogActions>
        <ConfirmationDialog
          open={openConfirmDialogue}
          handleClose={() => setOpenConfirmDialogue(false)}
          title='Forward to Booking'
          content='Are you sure you want to mark this enquiry completed ?'
          onConfirm={() => {
            setOpenConfirmDialogue(false);
            handleDataForwardToBooking(updatedEnquiryData);
          }}
        />
      </Dialog>

      <AlertSnackbar
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        severity={snackbarSeverity}
        message={message}
      />
    </Box>
  );
};

export default CreateEnquiryDialogue;
