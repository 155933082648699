import React, { useState } from 'react';
import { useGetDeadlinesQuery } from '../../state/api';
import { GridColDef } from '@mui/x-data-grid';
import Header from '../../components/Header';
import { Box, IconButton, Tooltip, TextField, Autocomplete, Tabs, Tab, useTheme, Button } from '@mui/material';
import { useNavigate, generatePath } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { DeadlineData, DeadlineType } from 'models/booking.model';
import { handleExcelDownload, setBookingTeams, useUserPermissions } from 'utils/utils';
import CustomDataGrid from 'components/CustomDataGrid';
import { FileDownloadOutlined, RefreshOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { setDeadlinesFilters } from 'state';

const Deadlines = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatchDealinesState = useDispatch();

  const userPermissions = useUserPermissions();
  const hasExportPermission = userPermissions.includes('read:deadlines-export');
  const hasImportPermission = userPermissions.includes('read:deadlines-import');
  const teams = setBookingTeams(hasExportPermission, hasImportPermission);
  const [deadlineType, setDeadlineType] = useState<DeadlineType>(DeadlineType.SI);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [totalRows, setTotalRows] = useState(0);

  const { startDate, endDate, bookingTeam } = useSelector((state: any) => state.global.deadlinesFilters);

  const useGetDeadline = (deadlineType: DeadlineType) => {
    const { data, isLoading, refetch, isFetching } = useGetDeadlinesQuery(
      [
        deadlineType,
        [startDate, endDate],
        hasExportPermission,
        hasImportPermission,
        bookingTeam,
        (page + 1).toString(),
        pageSize.toString(),
      ],
      {
        refetchOnMountOrArgChange: true,
      },
    );

    return {
      deadlines: data,
      isLoading: isLoading,
      refetch: refetch,
      isFetching: isFetching,
    };
  };

  const deadlineData = useGetDeadline(deadlineType);

  React.useEffect(() => {
    setTotalRows(deadlineData.deadlines?.totalBookingsWithDeadlines ?? 0);
  }, [deadlineData.deadlines?.totalBookingsWithDeadlines]);

  const columns: GridColDef[] = [
    {
      field: 'bookingNumber',
      headerName: 'Booking Number',
      flex: 0.3,
    },
    {
      field: 'consignor',
      headerName: 'Consignor',
      flex: 0.5,
      valueGetter: params => params.row.consignor?.name,
    },
    {
      field: 'carrier',
      headerName: 'Carrier',
      flex: 0.3,
      valueGetter: params => params.row.carrier?.name,
    },
    {
      field: 'carrierBookingNumber',
      headerName: 'Carrier Booking No',
      flex: 0.3,
    },
    {
      field: 'deadlineDate',
      headerName: 'Date',
      flex: 0.2,
      valueGetter: params => dayjs(params.row.deadline).format('DD/MM/YYYY'),
    },
    {
      field: 'deadlineTime',
      headerName: 'Time',
      flex: 0.2,
      valueGetter: params => dayjs(params.row.deadline).format('HH:mm'),
    },
    {
      field: 'Details',
      renderCell: cellValues => (
        <Box>
          <Tooltip title='View Booking'>
            <IconButton
              sx={{ color: theme.palette.secondary[400] }}
              onClick={() =>
                navigate(
                  generatePath(`/bookings/${cellValues.row._id}`, {
                    id: cellValues.row._id,
                  }),
                )
              }
            >
              <InfoOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
      flex: 0.2,
    },
  ];

  const handleTabChange = (event: React.SyntheticEvent, newValue: DeadlineType) => {
    setDeadlineType(newValue);
  };

  const excelExport = () => {
    const data = deadlineData.deadlines?.formattedDeadlines?.map((deadline: DeadlineData) => {
      return {
        'Booking Number': deadline.bookingNumber,
        Consignor: deadline.consignor?.name,
        Carrier: deadline.carrier?.name,
        'Carrier Booking No': deadline.carrierBookingNumber,
        Date: dayjs(deadline.deadline).format('DD/MM/YYYY'),
        Time: dayjs(deadline.deadline).format('HH:mm'),
      };
    });

    if (data) {
      handleExcelDownload(data, `${deadlineType}-deadlines`);
    }
  };

  return (
    <Box m='1.5rem 2.5rem'>
      <Box display='flex' justifyContent='space-between' alignItems='center' mb={'0.5rem'}>
        <Header title='Deadlines' />
        <Box display={'flex'} gap={'1rem'}>
          <Tooltip title='Refresh'>
            <IconButton
              onClick={() => deadlineData.refetch()}
              sx={{
                color: theme.palette.secondary[400],
                border: `1px solid ${theme.palette.divider}`,
                padding: '0.5rem',
                borderRadius: '8px',
                transition: 'all 0.2s',
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                },
              }}
            >
              <RefreshOutlined />
            </IconButton>
          </Tooltip>
          <Button
            variant='contained'
            startIcon={<FileDownloadOutlined />}
            onClick={excelExport}
            sx={{
              textTransform: 'none',
              fontSize: '0.875rem',
              padding: '0.5rem 1rem',
              borderRadius: '8px',
              boxShadow: 'none',
              backgroundColor: theme.palette.secondary[400],
              '&:hover': {
                backgroundColor: theme.palette.secondary[500],
              },
            }}
          >
            Export to Excel
          </Button>
        </Box>
      </Box>
      <Box display='flex' justifyContent='space-between' alignItems='center' mb='1rem' width={'100%'}>
        <Box width={'50%'}>
          <Tabs
            value={deadlineType}
            onChange={handleTabChange}
            variant='scrollable'
            scrollButtons='auto'
            sx={{
              '& .MuiTabs-indicator': {
                backgroundColor: theme.palette.secondary[400],
                height: '3px',
                borderRadius: '2px',
              },
              '& .MuiTab-root': {
                textTransform: 'none',
                fontSize: '0.875rem',
                fontWeight: 500,
                color: theme.palette.text.secondary,
                padding: '0.5rem 1rem',
                minWidth: 'auto',
                '&.Mui-selected': {
                  color: theme.palette.text.primary,
                  fontWeight: 600,
                },
              },
            }}
          >
            <Tab label='SI Submission' value={DeadlineType.SI} />
            <Tab label='VGM Submission' value={DeadlineType.VGM} />
            <Tab label='UCR Submission' value={DeadlineType.UCR} />
            <Tab label='Earliest Release' value={DeadlineType.earliestRelease} />
            <Tab label='Earliest Gate In' value={DeadlineType.earliestGateIn} />
            <Tab label='Latest Gate In' value={DeadlineType.GateIn} />
          </Tabs>
        </Box>
        <Box display='flex' justifyContent={'flex-end'} alignItems='center' gap='1rem' width={'50%'}>
          <Autocomplete
            options={teams ?? []}
            isOptionEqualToValue={(option, value) => option === value}
            value={bookingTeam}
            onChange={(e, newValue) => {
              if (newValue) {
                dispatchDealinesState(setDeadlinesFilters({ bookingTeam: newValue }));
              }
            }}
            renderInput={params => (
              <TextField
                {...params}
                label='Team'
                variant='outlined'
                size='small'
                sx={{
                  minWidth: '7rem',
                  '& .MuiInputBase-root': {
                    borderRadius: '8px',
                  },
                }}
              />
            )}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label='Start Date'
              format='DD/MM/YYYY'
              value={dayjs(startDate)}
              onChange={(newDate: dayjs.Dayjs | null) => {
                if (newDate && newDate.isValid()) {
                  dispatchDealinesState(setDeadlinesFilters({ startDate: newDate.toISOString() }));
                }
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  minWidth: '7rem',
                  height: '40px',
                  borderRadius: '8px',
                },
              }}
            />
            <DatePicker
              label='End Date'
              format='DD/MM/YYYY'
              value={dayjs(endDate)}
              onChange={(newDate: dayjs.Dayjs | null) => {
                if (newDate && newDate.isValid()) {
                  dispatchDealinesState(setDeadlinesFilters({ endDate: newDate.toISOString() }));
                }
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  minWidth: '7rem',
                  height: '40px',
                  borderRadius: '8px',
                },
              }}
            />
          </LocalizationProvider>
        </Box>
      </Box>
      <Box mt='1rem'>
        <CustomDataGrid
          data={deadlineData.deadlines?.formattedDeadlines ?? []}
          columns={columns}
          isLoading={deadlineData.isLoading}
          isFetching={deadlineData.isFetching}
          showToolbar={false}
          height='75vh'
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
          totalRows={totalRows}
        />
      </Box>
    </Box>
  );
};

export default Deadlines;
