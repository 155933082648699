import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Controller, UseFormReturn } from 'react-hook-form';
import {
  Box,
  TextField,
  Autocomplete,
  Divider,
  InputAdornment,
  IconButton,
  Tooltip,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from '@mui/material';
import { useGetCustomersQuery, useGetSitesQuery } from '../../state/api';
import { useGetPortsQuery } from '../../state/api';
import { useGetVendorsQuery } from '../../state/api';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Hbl, BLType, PaymentType, HblSellRates } from 'models/hbl.model';
import { Enquiry, Site, Vendor, VendorType } from 'models/index.model';
import {
  getValueAndLabelFromPort,
  getValueAndLabelFromCustomer,
  getValueAndLabelFromVendor,
  getValueAndLabelFromSite,
} from 'utils/utils';
import { Customer, Port } from 'models/index.model';
import vesselFlags from 'dataAssets/vesselFlags';
import { RefreshOutlined } from '@mui/icons-material';
import countryList from 'dataAssets/countryList';
import { BaseIsolatedReRender } from 'utils/renderBase';
import ChargeNameAutocomplete from 'components/ChargeNameAutocomplete';
import { currencies, bases } from 'dataAssets/constants';
import { generateMenuItemsFromArray } from 'utils/generateMenuItemsFromArray';
import { Booking, Invoice } from 'models/booking.model';

export interface HblDocumentDialogueContentProps {
  useFormReference: UseFormReturn<Hbl>;
  hblDetails?: Hbl;
  defaultSellRates: HblSellRates[];
}

const HblDocumentDialogueContent: React.FC<HblDocumentDialogueContentProps> = ({
  useFormReference,
  hblDetails,
  defaultSellRates,
}) => {
  const {
    register,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormReference;

  const { data: customers, isLoading: customersLoading, refetch: refetchCustomers } = useGetCustomersQuery({});
  const { data: ports, refetch: refetchPorts } = useGetPortsQuery({});
  const { data: vendors, refetch: refetchVendors } = useGetVendorsQuery({});
  const { data: sites, isLoading: sitesIsLoading } = useGetSitesQuery({});
  const [includeSellRates, setIncludeSellRates] = useState<boolean>(false);
  const [hblSellRates, setHblSellRates] = useState<HblSellRates[] | undefined>(defaultSellRates);
  const [showDescriptionTextbox, setShowDescriptionTextbox] = useState(false);
  const [descriptionValue, setDescriptionValue] = useState('');
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null);

  const blTypeList = Object.values(BLType);
  const paymentTypeList = Object.values(PaymentType);

  const handlePortChange = (portType: string, value: { value: string; label: string } | null) => {
    if (!value) {
      return;
    }

    const newPort = ports?.masterData?.find(port => port._id === value.value);
    if (newPort) {
      setValue(portType as keyof Hbl, newPort);
    }
  };

  const handleCustomerChange = (customerType: string, value: { value: string; label: string } | null) => {
    if (!value) {
      return;
    }

    const newCustomer = customers?.masterData?.find(customer => customer._id === value.value);
    if (newCustomer) {
      setValue(customerType as keyof Hbl, newCustomer);
    }
  };

  const handleVendorChange = (vendorType: string, value: { value: string; label: string } | null) => {
    if (!value) {
      return;
    }

    const newVendor = vendors?.masterData?.find(vendor => vendor._id === value.value);
    if (newVendor) {
      setValue(vendorType as keyof Hbl, newVendor);
    }
  };

  const handleIncludeSellRatesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIncludeSellRates(event.target.value === 'true');
  };

  const onRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === 'yes') {
      setShowDescriptionTextbox(true);
    } else {
      setShowDescriptionTextbox(false);
      setDescriptionValue('');
    }
  };

  const handleTextboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescriptionValue(event.target.value);
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const timeout = setTimeout(() => {
      if (hblDetails?.cargoDetails) {
        for (let i = 0; i < hblDetails?.cargoDetails?.length; i++) {
          setValue(`cargoDetails.${i}.descriptionOfGoodsAndPackages`, event.target.value);
        }
      }
    }, 500);

    setDebounceTimeout(timeout);
  };

  useEffect(() => {
    if (!includeSellRates) {
      setValue(`sellRates`, []);
    } else if (hblDetails?.sellRates?.length === 0) {
      setHblSellRates(defaultSellRates);
      setValue(`sellRates`, defaultSellRates);
      if (defaultSellRates) {
        for (let i = 0; i < defaultSellRates.length; i++) {
          setValue(`sellRates.${i}.chargeName`, defaultSellRates?.[i]?.chargeName);
        }
      }
    } else {
      setHblSellRates(hblDetails?.sellRates);
      setValue(`sellRates`, hblDetails?.sellRates);
      if (hblDetails?.sellRates) {
        for (let i = 0; i < hblDetails?.sellRates.length; i++) {
          setValue(`sellRates.${i}.chargeName`, hblDetails?.sellRates?.[i]?.chargeName);
        }
      }
    }
  }, [includeSellRates, hblDetails, defaultSellRates, hblSellRates]);

  useEffect(() => {
    if (hblDetails?._id && hblDetails?.sellRates && hblDetails?.sellRates.length > 0) {
      setIncludeSellRates(true);
    }
  }, []);

  return (
    <Box padding={'1rem'}>
      <Box textAlign={'right'}>
        <Tooltip title='Refresh Master Data'>
          <IconButton
            onClick={() => {
              refetchCustomers();
              refetchPorts();
              refetchVendors();
            }}
          >
            <RefreshOutlined />
          </IconButton>
        </Tooltip>
      </Box>
      <Divider>Customer Details</Divider>
      {customers?.masterData || customersLoading ? (
        <Box display='grid' gridTemplateColumns='repeat(3, 1fr)' gap='1rem' m='1rem 0'>
          <Controller
            control={control}
            name={'shipper'}
            rules={{ required: 'Shipper is required' }}
            render={({ field: { value } }) => (
              <Autocomplete
                value={value ? getValueAndLabelFromCustomer(value) : null}
                onChange={(_, value) => {
                  handleCustomerChange('shipper', value);
                }}
                disablePortal
                options={(customers?.masterData ?? []).map((customer: Customer) =>
                  getValueAndLabelFromCustomer(customer),
                )}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderInput={params => (
                  <TextField
                    {...params}
                    label='Shipper *'
                    error={!!errors.shipper}
                    helperText={errors.shipper?.message}
                  />
                )}
              />
            )}
          />
          <Controller
            control={control}
            name={'consignee'}
            rules={{ required: 'Consignee is required' }}
            render={({ field: { value, onChange } }) => (
              <Autocomplete
                value={value ? getValueAndLabelFromCustomer(value) : null}
                onChange={(_, value) => {
                  if (value) {
                    handleCustomerChange('consignee', value);
                  } else {
                    onChange(undefined);
                  }
                }}
                disablePortal
                options={(customers?.masterData ?? []).map((customer: Customer) =>
                  getValueAndLabelFromCustomer(customer),
                )}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderInput={params => (
                  <TextField
                    {...params}
                    label='Consignee *'
                    error={!!errors?.consignee}
                    helperText={errors?.consignee?.message}
                  />
                )}
              />
            )}
          />
          <Controller
            control={control}
            name={'notifyParty'}
            rules={{ required: 'Notify Party is required' }}
            render={({ field: { value } }) => (
              <Autocomplete
                value={value ? getValueAndLabelFromCustomer(value) : null}
                onChange={(_, value) => {
                  handleCustomerChange('notifyParty', value);
                }}
                disablePortal
                options={(customers?.masterData ?? []).map((customer: Customer) =>
                  getValueAndLabelFromCustomer(customer),
                )}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderInput={params => (
                  <TextField
                    {...params}
                    label='Notify Party *'
                    error={!!errors?.notifyParty}
                    helperText={errors?.notifyParty?.message}
                  />
                )}
              />
            )}
          />
        </Box>
      ) : null}
      <FormControl>
        <FormLabel>Include sell rates?</FormLabel>
        <RadioGroup row value={includeSellRates.toString()} onChange={handleIncludeSellRatesChange}>
          <FormControlLabel value='true' control={<Radio />} label='Yes' />
          <FormControlLabel value='false' control={<Radio />} label='No' />
        </RadioGroup>
      </FormControl>
      {includeSellRates ? (
        <>
          <Divider>Sell Rates</Divider>
          {hblSellRates && hblSellRates?.length > 0 ? (
            <Box m={'1rem 0'}>
              {hblSellRates?.map((item, index) => {
                return (
                  <Box
                    display='grid'
                    gridTemplateColumns={'35fr 30fr 7fr 7fr 7fr 15fr'}
                    alignItems={'center'}
                    gap={'1rem'}
                    mt={'1rem'}
                    key={item._id}
                  >
                    <ChargeNameAutocomplete
                      control={control}
                      name={`sellRates.${index}.chargeName`}
                      chargeType='Sales'
                    />
                    <Controller
                      name={`sellRates.${index}.currency`}
                      control={control}
                      rules={{ required: 'Currency of sell rate is required' }}
                      defaultValue={currencies[0]}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          select
                          autoComplete='off'
                          label='Currency *'
                          error={!!errors?.sellRates?.[index]?.currency}
                          helperText={errors?.sellRates?.[index]?.message}
                        >
                          {generateMenuItemsFromArray(currencies)}
                        </TextField>
                      )}
                    />
                    <Controller
                      name={`sellRates.${index}.rate`}
                      control={control}
                      rules={{ required: 'Rate of sell rate is required' }}
                      defaultValue={0}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          autoComplete='off'
                          type='number'
                          label='Value'
                          error={!!errors?.sellRates?.[index]?.rate}
                          helperText={errors?.sellRates?.[index]?.rate?.message}
                        />
                      )}
                    />
                    <BaseIsolatedReRender
                      form={
                        useFormReference as UseFormReturn<
                          Invoice | Booking | Enquiry | Hbl,
                          any,
                          Invoice | Booking | Enquiry | Hbl
                        >
                      }
                      index={index}
                      bases={bases}
                      formPath='sellRates'
                    />
                    <Controller
                      name={`sellRates.${index}.exchangeRate`}
                      control={control}
                      defaultValue={''}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          autoComplete='off'
                          type='number'
                          label='Exchange Rate'
                          error={!!errors?.sellRates?.[index]?.exchangeRate}
                          helperText={errors?.sellRates?.[index]?.exchangeRate?.message}
                        />
                      )}
                    />
                    <Controller
                      name={`sellRates.${index}.paymentType`}
                      control={control}
                      rules={{ required: 'Payment type of sell rate is required' }}
                      defaultValue={PaymentType.Prepaid}
                      render={({ field: { value, onChange } }) => (
                        <Autocomplete
                          disablePortal
                          options={paymentTypeList}
                          value={value || ''}
                          onChange={(_, newValue) => {
                            onChange(newValue);
                          }}
                          isOptionEqualToValue={(option, value) => option === value}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label='Payment Type *'
                              autoComplete='off'
                              error={!!errors?.sellRates?.[index]?.paymentType}
                              helperText={errors?.sellRates?.[index]?.paymentType?.message}
                            />
                          )}
                        />
                      )}
                    />
                  </Box>
                );
              })}
            </Box>
          ) : (
            <Typography m={'1rem 0'} textAlign={'center'} color={'gray'}>
              No sell rates details available
            </Typography>
          )}
        </>
      ) : null}
      <Divider>Shipment Details</Divider>
      <Box display='grid' gridTemplateColumns='repeat(3, 1fr)' gap='1rem' m='1rem 0'>
        <Controller
          control={control}
          name={'deliveryContactDetails'}
          rules={{ required: 'Delivery Contact Details is required' }}
          render={({ field: { value, onChange } }) => (
            <Autocomplete
              disablePortal
              value={value ? getValueAndLabelFromVendor(value) : null}
              onChange={(_, value) => {
                if (value) {
                  handleVendorChange('deliveryContactDetails', value);
                } else {
                  onChange(undefined);
                }
              }}
              options={(vendors?.masterData ?? [])
                .filter((vendor: Vendor) => vendor.vendorType === VendorType.Agent)
                .map((vendor: Vendor) => getValueAndLabelFromVendor(vendor))}
              isOptionEqualToValue={(option, value) => option.label === value.label}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Delivery Contact Details *'
                  error={!!errors?.deliveryContactDetails}
                  helperText={errors?.deliveryContactDetails?.message}
                />
              )}
            />
          )}
        />
        {sites?.masterData || sitesIsLoading ? (
          <Controller
            control={control}
            name='placeOfReceipt'
            rules={{ required: 'Place Of Receipt is required' }}
            render={({ field: { value, onChange } }) => (
              <Autocomplete
                disablePortal
                options={(sites?.masterData ?? []).map((site: Site) => getValueAndLabelFromSite(site))}
                value={
                  value && (sites?.masterData ?? []).some(site => site._id === value?._id)
                    ? getValueAndLabelFromSite(value)
                    : null
                }
                isOptionEqualToValue={(option, value) => option.value === value?.value}
                onChange={(_, value) => {
                  const newVal = sites?.masterData?.filter(site => site._id === value?.value);
                  if (newVal) {
                    setValue(`placeOfReceipt`, newVal[0]);
                  }
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label='Place Of Receipt *'
                    error={!!errors?.placeOfReceipt}
                    helperText={errors?.placeOfReceipt?.message ? 'Select a valid Place Of Receipt' : null}
                  />
                )}
              />
            )}
          />
        ) : null}
        <TextField label='Pre Carriage By' autoComplete='off' {...register('preCarriageBy')} />
      </Box>
      <Box display='grid' gridTemplateColumns='repeat(3, 1fr)' gap='1rem' m='1rem 0'>
        <Controller
          control={control}
          name={'portOfLoading'}
          rules={{ required: 'Port of Loading is required' }}
          render={({ field: { value } }) => (
            <Autocomplete
              value={value ? getValueAndLabelFromPort(value) : null}
              onChange={(_, value) => {
                handlePortChange('portOfLoading', value);
              }}
              disablePortal
              options={(ports?.masterData ?? []).map((port: Port) => getValueAndLabelFromPort(port))}
              isOptionEqualToValue={(option, value) => option.label === value.label}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Port of Loading *'
                  error={!!errors.portOfLoading}
                  helperText={errors.portOfLoading?.message}
                />
              )}
            />
          )}
        />

        <Controller
          control={control}
          name={'portOfDischarge'}
          rules={{ required: 'Port of Discharge is required' }}
          render={({ field: { value } }) => (
            <Autocomplete
              value={value ? getValueAndLabelFromPort(value) : null}
              disablePortal
              options={(ports?.masterData ?? []).map((port: Port) => getValueAndLabelFromPort(port))}
              isOptionEqualToValue={(option, value) => option.label === value.label}
              onChange={(_, value) => {
                handlePortChange('portOfDischarge', value);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Port of Discharge *'
                  error={!!errors.portOfDischarge}
                  helperText={errors.portOfDischarge?.message}
                />
              )}
            />
          )}
        />

        <Controller
          control={control}
          name={'placeOfDelivery'}
          rules={{ required: 'Place of Delivery is required' }}
          render={({ field: { value } }) => (
            <Autocomplete
              value={value ? getValueAndLabelFromPort(value) : null}
              disablePortal
              options={(ports?.masterData ?? []).map((port: Port) => getValueAndLabelFromPort(port))}
              isOptionEqualToValue={(option, value) => option.label === value.label}
              onChange={(_, value) => {
                handlePortChange('placeOfDelivery', value);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Place of Delivery *'
                  error={!!errors.placeOfDelivery}
                  helperText={errors.placeOfDelivery?.message}
                />
              )}
            />
          )}
        />
      </Box>
      <Box display='grid' gridTemplateColumns='repeat(3, 1fr)' gap='1rem' m='1rem 0'>
        <TextField
          label='Vessel Name *'
          autoComplete='off'
          {...register('oceanVessel.vesselName', {
            required: 'Vessel Name is required',
          })}
          error={!!errors.oceanVessel?.vesselName}
          helperText={errors.oceanVessel?.vesselName?.message}
        />
        <TextField
          label='Voyage Number *'
          autoComplete='off'
          {...register('oceanVessel.voyageNumber', {
            required: 'Voyage Number is required',
          })}
          error={!!errors.oceanVessel?.voyageNumber}
          helperText={errors.oceanVessel?.voyageNumber?.message}
        />
        <Controller
          name='oceanVessel.vesselFlag'
          control={control}
          rules={{ required: 'Vessel Flag is required' }}
          render={({ field: { value, onChange } }) => (
            <Autocomplete
              disablePortal
              value={value ?? null}
              onChange={(_, value) => onChange(value)}
              options={vesselFlags}
              isOptionEqualToValue={(option, value) => option === value}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Vessel Flag *'
                  error={!!errors.oceanVessel?.vesselFlag}
                  helperText={errors.oceanVessel?.vesselFlag?.message}
                />
              )}
            />
          )}
        />
      </Box>
      <Divider
        sx={{
          mb: '1.5rem',
        }}
      >
        Cargo Details
      </Divider>
      {hblDetails?.cargoDetails && hblDetails?.cargoDetails?.length > 0 ? (
        <>
          <Box display={'flex'} flexDirection={'row'} gap={2} marginBottom={2}>
            <FormControl>
              <FormLabel>Same description for all cargos?</FormLabel>
              <RadioGroup row value={showDescriptionTextbox ? 'yes' : 'no'} onChange={onRadioChange}>
                <FormControlLabel value='yes' control={<Radio />} label='Yes' />
                <FormControlLabel value='no' control={<Radio />} label='No' />
              </RadioGroup>
            </FormControl>
            {showDescriptionTextbox && (
              <TextField
                label='Description'
                variant='outlined'
                value={descriptionValue}
                onChange={handleTextboxChange}
              />
            )}
          </Box>
          {hblDetails?.cargoDetails?.map((cargoDetail, i) => {
            const uniqueKey = i;
            const duplicateValidation = (field: string, index: number) => (value: any) => {
              if (!value) return true;
              const allValues = getValues().cargoDetails ?? [];
              const fieldValues = allValues.map((item: any) => item[field]);
              const duplicateIndex = fieldValues.findIndex((v, idx) => v === value && idx !== index);
              return duplicateIndex === -1 || `Duplicate ${field} found`;
            };

            const currentCargo = { ...cargoDetail };
            return (
              <Box
                key={uniqueKey}
                display={'grid'}
                gridTemplateColumns={'20fr 20fr 20fr 20fr 20fr 20fr 20fr 20fr'}
                id={`container-row-${i}`}
                alignItems={'center'}
                gap={'1rem'}
                mb={'1rem'}
              >
                <TextField
                  label={'Marks *'}
                  autoComplete='off'
                  variant='outlined'
                  defaultValue={currentCargo.marks}
                  {...register(`cargoDetails.${i}.marks`, {
                    validate: {
                      unique: duplicateValidation('marks', i),
                    },
                    required: 'Marks is required',
                  })}
                  error={!!errors?.cargoDetails?.[i]?.marks}
                  helperText={errors?.cargoDetails?.[i]?.marks?.message}
                />
                <TextField
                  label={'Numbers *'}
                  autoComplete='off'
                  variant='outlined'
                  defaultValue={currentCargo.numbers}
                  {...register(`cargoDetails.${i}.numbers`, {
                    validate: {
                      unique: duplicateValidation('numbers', i),
                    },
                    required: 'Numbers is required',
                  })}
                  error={!!errors?.cargoDetails?.[i]?.numbers}
                  helperText={errors?.cargoDetails?.[i]?.numbers?.message}
                />
                <TextField
                  label={'Number Of Packages *'}
                  type='number'
                  autoComplete='off'
                  variant='outlined'
                  {...register(`cargoDetails.${i}.numberOfPackages`, {
                    required: 'Number of Packages is required',
                  })}
                  error={!!errors?.cargoDetails?.[i]?.numberOfPackages}
                  helperText={errors?.cargoDetails?.[i]?.numberOfPackages?.message}
                />
                <TextField
                  label={'Kind Of Packages *'}
                  autoComplete='off'
                  variant='outlined'
                  {...register(`cargoDetails.${i}.kindOfPackages`, {
                    required: 'Kind of Packages is required',
                  })}
                  error={!!errors?.cargoDetails?.[i]?.kindOfPackages}
                  helperText={errors?.cargoDetails?.[i]?.kindOfPackages?.message}
                />
                <TextField
                  label='Desciption Of Goods and Packages *'
                  autoComplete='off'
                  variant='outlined'
                  {...register(`cargoDetails.${i}.descriptionOfGoodsAndPackages`, {
                    required: 'Description Of Goods and Packages is required',
                  })}
                  error={!!errors?.cargoDetails?.[i]?.descriptionOfGoodsAndPackages}
                  helperText={errors?.cargoDetails?.[i]?.descriptionOfGoodsAndPackages?.message}
                />
                <TextField
                  label={'Gross Weight Cargo *'}
                  autoComplete='off'
                  variant='outlined'
                  defaultValue={currentCargo.grossWeightCargo}
                  {...register(`cargoDetails.${i}.grossWeightCargo`, {
                    required: 'Gross Weight Cargo is required',
                  })}
                  error={!!errors?.cargoDetails?.[i]?.grossWeightCargo}
                  helperText={errors?.cargoDetails?.[i]?.grossWeightCargo?.message}
                  InputProps={{
                    endAdornment: <InputAdornment position='end'>KG</InputAdornment>,
                  }}
                />
                <TextField
                  label={'Tare Weight'}
                  autoComplete='off'
                  variant='outlined'
                  defaultValue={currentCargo.tareWeight}
                  {...register(`cargoDetails.${i}.tareWeight`)}
                  error={!!errors?.cargoDetails?.[i]?.tareWeight}
                  helperText={errors?.cargoDetails?.[i]?.tareWeight?.message}
                  InputProps={{
                    endAdornment: <InputAdornment position='end'>KG</InputAdornment>,
                  }}
                />
                <TextField
                  label={'Volume'}
                  autoComplete='off'
                  variant='outlined'
                  defaultValue={currentCargo.volume}
                  {...register(`cargoDetails.${i}.volume`)}
                  error={!!errors?.cargoDetails?.[i]?.volume}
                  helperText={errors?.cargoDetails?.[i]?.volume?.message}
                />
              </Box>
            );
          })}
        </>
      ) : (
        <Typography m={'1rem 0'} textAlign={'center'} color={'gray'}>
          No cargo details available
        </Typography>
      )}
      <Divider
        sx={{
          mb: '1.5rem',
        }}
      >
        BL Details
      </Divider>
      <Box display='grid' gridTemplateColumns='repeat(3, 1fr)' gap='1rem' m='1rem 0'>
        <TextField
          label='Number Of Original BL(s) *'
          autoComplete='off'
          {...register('numberOfOriginalBls', {
            required: 'Number Of Original BLs is required',
          })}
          error={!!errors?.numberOfOriginalBls}
          helperText={errors?.numberOfOriginalBls?.message}
        />
        <Controller
          name='placeAndDateOfIssue.place'
          control={control}
          rules={{ required: 'Place of Issue is required' }}
          render={({ field: { value, onChange } }) => (
            <Autocomplete
              disablePortal
              options={countryList}
              value={value}
              onChange={(_, value) => {
                onChange(value);
              }}
              isOptionEqualToValue={(option, value) => {
                return option.toLowerCase() === value.toLowerCase();
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Place Of Issue *'
                  autoComplete='off'
                  error={!!errors.placeAndDateOfIssue?.place}
                  helperText={errors.placeAndDateOfIssue?.place?.message}
                />
              )}
            />
          )}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Controller
            name={`placeAndDateOfIssue.date`}
            control={control}
            rules={{ required: 'Date of Issue is required' }}
            render={({ field: { value, onChange } }) => (
              <Box width={'100%'}>
                <DatePicker
                  format='DD/MM/YYYY'
                  label={'Date Of Issue *'}
                  value={value ? dayjs(value) : null}
                  onChange={date => onChange(dayjs(date).format())}
                  slotProps={{
                    textField: {
                      error: !!errors.placeAndDateOfIssue?.date,
                      helperText: errors.placeAndDateOfIssue?.date?.message || '',
                      sx: { width: '100%' },
                    },
                  }}
                />
              </Box>
            )}
          />
        </LocalizationProvider>
      </Box>
      <Box display='grid' gridTemplateColumns='repeat(3, 1fr)' gap='1rem' m='1rem 0'>
        <Controller
          name='payableAt'
          control={control}
          rules={{ required: 'Payable At is required' }}
          render={({ field: { value, onChange } }) => (
            <Autocomplete
              disablePortal
              options={countryList}
              value={value}
              onChange={(_, value) => {
                onChange(value);
              }}
              isOptionEqualToValue={(option, value) => {
                return option.toLowerCase() === value.toLowerCase();
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Payable At *'
                  autoComplete='off'
                  error={!!errors.payableAt}
                  helperText={errors.payableAt?.message}
                />
              )}
            />
          )}
        />
        <Controller
          name='blType'
          control={control}
          rules={{ required: 'BL Type is required' }}
          render={({ field: { value, onChange } }) => (
            <Autocomplete
              disablePortal
              options={blTypeList}
              value={value}
              onChange={(_, newValue) => {
                onChange(newValue);
              }}
              isOptionEqualToValue={(option, value) => option === value}
              renderInput={params => (
                <TextField
                  {...params}
                  label='BL Type *'
                  autoComplete='off'
                  error={!!errors.blType}
                  helperText={errors.blType?.message}
                />
              )}
            />
          )}
        />
      </Box>
      <Box m='1rem 0' width={'100%'} height={250}>
        <TextField
          fullWidth
          label='Additional Description'
          autoComplete='off'
          multiline
          rows={11}
          {...register('additionalDescription')}
          InputProps={{
            style: { height: 250 },
          }}
          sx={{
            '.MuiInputBase-root': {
              height: '100%',
              display: 'flex',
              alignItems: 'flex-start',
            },
          }}
          error={!!errors?.additionalDescription}
          helperText={errors?.additionalDescription?.message}
        />
      </Box>
    </Box>
  );
};

export default HblDocumentDialogueContent;
