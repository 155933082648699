import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogContent,
  Box,
  Tooltip,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { MonthlyCarrierVolumes } from 'models/analytics';
import { BaseDialogueProps } from 'models/index.model';
import GeneralDialogueActions from 'components/GeneralDialogueActions';
import { getAllMonthNames, getCurrentMonth, handleExcelDownload, truncateName } from 'utils/utils';
import { DownloadOutlined } from '@mui/icons-material';
import { useTheme } from '@mui/system';

interface MonthlyCarrierVolumeTableProps extends BaseDialogueProps {
  data: MonthlyCarrierVolumes[];
  bookingType: string;
  selectedYear: number;
  selectedMonth: number | null;
}

const MonthlyCarrierVolumeTable: React.FC<MonthlyCarrierVolumeTableProps> = ({
  data,
  handleClose,
  open,
  bookingType,
  selectedYear,
  selectedMonth,
}) => {
  const currentMonth = getCurrentMonth(selectedYear, selectedMonth);
  const allMonths = getAllMonthNames(currentMonth);
  const excelFileName = selectedMonth
    ? `Carrier-Volume-${bookingType}-Monthly-Report_${allMonths[selectedMonth - 1]}-${selectedYear.toString()}`
    : `Carrier-Volume-${bookingType}-Monthly-Report_${selectedYear.toString()}`;
  const [transformedData, setTransformedData] = useState<{ Carrier: string; [key: string]: string | number }[]>([]);
  const theme = useTheme();

  useEffect(() => {
    const s_transformedData = data.map(item => {
      const carrierVolumeColumns: { [key: string]: string } = {};

      allMonths.map((month, index) => {
        if (bookingType === 'Export') {
          carrierVolumeColumns[`${month} (Volume)`] = `${item.exportCarrierVolumes[index].volume}`;
        } else {
          carrierVolumeColumns[`${month} (Volume)`] = `${item.importCarrierVolumes[index].volume}`;
        }
      });

      return {
        Carrier: item.carrierName,
        ...carrierVolumeColumns,
        [`Total ${bookingType} Volume`]: bookingType === 'Export' ? item.totalExportVolume : item.totalImportVolume,
      };
    });

    setTransformedData(s_transformedData);
  }, [data, bookingType]);

  const [totalExportVolumes, totalImportVolumes] = allMonths
    .map(month => {
      return data.reduce(
        ([exportTotal, importTotal], carrier) => {
          const exportMonthData = carrier.exportCarrierVolumes.find(m => m.month === month);
          const importMonthData = carrier.importCarrierVolumes.find(m => m.month === month);

          return [exportTotal + (exportMonthData?.volume || 0), importTotal + (importMonthData?.volume || 0)];
        },
        [0, 0],
      );
    })
    .reduce(
      ([exportVolumes, importVolumes], [exportTotal, importTotal]) => {
        exportVolumes.push(exportTotal);
        importVolumes.push(importTotal);
        return [exportVolumes, importVolumes];
      },
      [[], []] as [number[], number[]],
    );

  const transformedTotalCarrierVolume: { [key: string]: string } = {};
  allMonths.forEach((month, index) => {
    if (bookingType === 'Export') {
      transformedTotalCarrierVolume[`${month} (Volume)`] = `${totalExportVolumes[index]}`;
    } else {
      transformedTotalCarrierVolume[`${month} (Volume)`] = `${totalImportVolumes[index]}`;
    }
  });

  const transformedDataWithTotalCarrierVolumes = [
    ...transformedData,
    {
      Carrier: 'YTD',
      ...transformedTotalCarrierVolume,
      [`Total ${bookingType} Volume`]:
        bookingType === 'Export'
          ? `${data.reduce((total, carrier) => total + carrier.totalExportVolume, 0)}`
          : `${data.reduce((total, carrier) => total + carrier.totalImportVolume, 0)}`,
    },
  ];

  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullWidth fullScreen maxWidth='xl'>
        <DialogTitle>
          <Box display={'flex'} alignItems={'center'} gap={1}>
            {bookingType} Monthly Carrier Volume Report
            <Tooltip title='Download as Excel' placement='top'>
              <IconButton onClick={() => handleExcelDownload(transformedDataWithTotalCarrierVolumes, excelFileName)}>
                <DownloadOutlined />
              </IconButton>
            </Tooltip>
          </Box>
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    align='center'
                    sx={{
                      borderRight: theme.palette.mode === 'dark' ? '1px solid #515151' : '1px solid #adadad',
                      backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : 'inherit',
                    }}
                  >
                    Carrier
                  </TableCell>
                  {allMonths.map(month => (
                    <React.Fragment key={month}>
                      <TableCell
                        align='center'
                        sx={{
                          borderRight: theme.palette.mode === 'dark' ? '1px solid #515151' : '1px solid #adadad',
                          backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : 'inherit',
                        }}
                      >
                        {month} (Volume)
                      </TableCell>
                    </React.Fragment>
                  ))}
                  <TableCell
                    align='center'
                    sx={{
                      backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : 'inherit',
                    }}
                  >
                    {bookingType === 'Import' ? 'Total Import Volume' : 'Total Export Volume'}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((carrier, index) => (
                  <TableRow
                    key={index}
                    sx={{ backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : '#292929' }}
                  >
                    <TableCell
                      align='left'
                      sx={{ borderRight: theme.palette.mode === 'dark' ? '1px solid #515151' : '1px solid #adadad' }}
                    >
                      <Tooltip title={carrier.carrierName} placement='top'>
                        <span>{truncateName(carrier.carrierName)}</span>
                      </Tooltip>
                    </TableCell>
                    {bookingType === 'Import'
                      ? allMonths.map(month => {
                          const monthData = carrier.importCarrierVolumes.find(m => m.month === month);
                          return (
                            <React.Fragment key={`${month}-${carrier.carrierId}`}>
                              <TableCell
                                align='center'
                                sx={{
                                  borderRight:
                                    theme.palette.mode === 'dark' ? '1px solid #515151' : '1px solid #adadad',
                                }}
                              >
                                {monthData?.volume || 0}
                              </TableCell>
                            </React.Fragment>
                          );
                        })
                      : allMonths.map(month => {
                          const monthData = carrier.exportCarrierVolumes.find(m => m.month === month);
                          return (
                            <React.Fragment key={`${month}-${carrier.carrierId}`}>
                              <TableCell
                                align='center'
                                sx={{
                                  borderRight:
                                    theme.palette.mode === 'dark' ? '1px solid #515151' : '1px solid #adadad',
                                }}
                              >
                                {monthData?.volume || 0}
                              </TableCell>
                            </React.Fragment>
                          );
                        })}
                    <TableCell align='center'>
                      {bookingType === 'Import'
                        ? (carrier.totalImportVolume ?? 'N/A')
                        : (carrier.totalExportVolume ?? 'N/A')}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell
                    sx={{
                      borderRight: theme.palette.mode === 'dark' ? '1px solid #515151' : '1px solid #adadad',
                      backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : 'inherit',
                    }}
                  >
                    YTD
                  </TableCell>
                  {bookingType === 'Import'
                    ? totalImportVolumes.map((totalImportPortVolume, index) => (
                        <React.Fragment key={index}>
                          <TableCell
                            align='center'
                            sx={{
                              borderRight: theme.palette.mode === 'dark' ? '1px solid #515151' : '1px solid #adadad',
                              backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : 'inherit',
                            }}
                          >
                            {totalImportPortVolume}
                          </TableCell>
                        </React.Fragment>
                      ))
                    : totalExportVolumes.map((totalExportPortVolume, index) => (
                        <React.Fragment key={index}>
                          <TableCell
                            align='center'
                            sx={{
                              borderRight: theme.palette.mode === 'dark' ? '1px solid #515151' : '1px solid #adadad',
                              backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : 'inherit',
                            }}
                          >
                            {totalExportPortVolume}
                          </TableCell>
                        </React.Fragment>
                      ))}
                  <TableCell
                    align='center'
                    sx={{
                      backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : 'inherit',
                    }}
                  >
                    {bookingType === 'Import'
                      ? `${data.reduce((total, carrier) => total + carrier.totalImportVolume, 0)}`
                      : `${data.reduce((total, carrier) => total + carrier.totalExportVolume, 0)}`}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <GeneralDialogueActions noSubmission handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default MonthlyCarrierVolumeTable;
