import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogContent,
  Box,
  Tooltip,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { MonthlyCommodityVolumeAndProfit } from 'models/analytics';
import { BaseDialogueProps } from 'models/index.model';
import GeneralDialogueActions from 'components/GeneralDialogueActions';
import { getAllMonthNames, getCurrentMonth, handleExcelDownload, truncateName } from 'utils/utils';
import { DownloadOutlined } from '@mui/icons-material';
import { useTheme } from '@mui/system';

interface MonthlyCommodityVolumeAndProfitTableProps extends BaseDialogueProps {
  data: MonthlyCommodityVolumeAndProfit[];
  bookingType: string;
  profitType: string;
  selectedYear: number;
  selectedMonth: number | null;
}

const MonthlyCommodityVolumeAndProfitTable: React.FC<MonthlyCommodityVolumeAndProfitTableProps> = ({
  data,
  bookingType,
  profitType,
  handleClose,
  open,
  selectedYear,
  selectedMonth,
}) => {
  const currentMonth = getCurrentMonth(selectedYear, selectedMonth);
  const allMonths = getAllMonthNames(currentMonth);
  const excelFileName = selectedMonth
    ? `Commodity-Volume-And-${profitType}-Profit-${bookingType}-Monthly-Report_${allMonths[selectedMonth - 1]}-${selectedYear.toString()}`
    : `Commodity-Volume-And-${profitType}-Profit-${bookingType}-Monthly-Report_${selectedYear.toString()}`;
  const [transformedData, setTransformedData] = useState<{ Commodity: string; [key: string]: string | number }[]>([]);
  const theme = useTheme();

  useEffect(() => {
    const s_transformedData = data.map(item => {
      const commodityVolumeAndProfitColumns: { [key: string]: string } = {};

      allMonths.map((month, index) => {
        commodityVolumeAndProfitColumns[`${month} (Volume)`] = `${item.monthlyCargoData[index].volume}`;
        commodityVolumeAndProfitColumns[`${month} (Profit)`] = `£${item.monthlyCargoData[index].profit}`;
      });

      return {
        Commodity: item.cargoDescription,
        ...commodityVolumeAndProfitColumns,
        'Total Volume': item.totalVolume,
        'Total Profit': `£${item.totalProfit}`,
      };
    });

    setTransformedData(s_transformedData);
  }, [data]);

  const [totalVolumes, totalProfits] = allMonths
    .map(month => {
      return data.reduce(
        ([volumeTotal, profitTotal], customer) => {
          const monthData = customer.monthlyCargoData.find(m => m.month === month);
          return [volumeTotal + (monthData?.volume || 0), profitTotal + (monthData?.profit || 0)];
        },
        [0, 0],
      );
    })
    .reduce(
      ([volumes, profits], [volumeTotal, profitTotal]) => {
        volumes.push(volumeTotal);
        profits.push(profitTotal);
        return [volumes, profits];
      },
      [[], []] as [number[], number[]],
    );

  const transformedTotalCarrierVolumesAndTotalProfits: { [key: string]: string } = {};
  allMonths.forEach((month, index) => {
    transformedTotalCarrierVolumesAndTotalProfits[`${month} (Volume)`] = `${totalVolumes[index]}`;
    transformedTotalCarrierVolumesAndTotalProfits[`${month} (Profit)`] = `£${totalProfits[index]}`;
  });

  const transformedDataWithTotalCarrierVolumesAndTotalProfits = [
    ...transformedData,
    {
      Commodity: 'YTD',
      ...transformedTotalCarrierVolumesAndTotalProfits,
      'Total Volume': data.reduce((total, cargoData) => total + cargoData.totalVolume, 0),
      'Total Profit': `£${data.reduce((total, cargoData) => total + cargoData.totalProfit, 0)}`,
    },
  ];

  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullWidth fullScreen maxWidth='xl'>
        <DialogTitle>
          <Box display={'flex'} alignItems={'center'} gap={1}>
            {bookingType} Monthly Commodity Volume And {profitType} Profit Report
            <Tooltip title='Download as Excel' placement='top'>
              <IconButton
                onClick={() =>
                  handleExcelDownload(transformedDataWithTotalCarrierVolumesAndTotalProfits, excelFileName)
                }
              >
                <DownloadOutlined />
              </IconButton>
            </Tooltip>
          </Box>
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    align='center'
                    sx={{
                      borderRight: theme.palette.mode === 'dark' ? '1px solid #515151' : '1px solid #adadad',
                      backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : 'inherit',
                    }}
                  >
                    Commodity
                  </TableCell>
                  {allMonths.map(month => (
                    <React.Fragment key={month}>
                      <TableCell
                        align='center'
                        sx={{
                          backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : 'inherit',
                        }}
                      >
                        {month} (Volume)
                      </TableCell>
                      <TableCell
                        align='center'
                        sx={{
                          borderRight: theme.palette.mode === 'dark' ? '1px solid #515151' : '1px solid #adadad',
                          backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : 'inherit',
                        }}
                      >
                        {month} (Profit)
                      </TableCell>
                    </React.Fragment>
                  ))}
                  <TableCell
                    align='center'
                    sx={{
                      backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : 'inherit',
                    }}
                  >
                    Total Volume
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{
                      backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : 'inherit',
                    }}
                  >
                    Total Profit
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((cargoData, index) => (
                  <TableRow
                    key={index}
                    sx={{ backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : '#292929' }}
                  >
                    <TableCell
                      align='left'
                      sx={{ borderRight: theme.palette.mode === 'dark' ? '1px solid #515151' : '1px solid #adadad' }}
                    >
                      <Tooltip title={cargoData.cargoDescription} placement='top'>
                        <span>{truncateName(cargoData.cargoDescription)}</span>
                      </Tooltip>
                    </TableCell>
                    {allMonths.map(month => {
                      const monthData = cargoData.monthlyCargoData.find(m => m.month === month);
                      return (
                        <React.Fragment key={`${month}-${cargoData.cargoDescription}`}>
                          <TableCell align='center'>{monthData?.volume || 0}</TableCell>
                          <TableCell
                            align='center'
                            sx={{
                              borderRight: theme.palette.mode === 'dark' ? '1px solid #515151' : '1px solid #adadad',
                            }}
                          >
                            £{monthData?.profit || 0}
                          </TableCell>
                        </React.Fragment>
                      );
                    })}
                    <TableCell align='center'>{cargoData.totalVolume}</TableCell>
                    <TableCell align='center'>£{cargoData.totalProfit}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell
                    sx={{
                      borderRight: theme.palette.mode === 'dark' ? '1px solid #515151' : '1px solid #adadad',
                      backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : 'inherit',
                    }}
                  >
                    YTD
                  </TableCell>
                  {totalVolumes.map((totalVolume, index) => (
                    <React.Fragment key={index}>
                      <TableCell
                        align='center'
                        sx={{
                          backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : 'inherit',
                        }}
                      >
                        {totalVolume}
                      </TableCell>
                      <TableCell
                        align='center'
                        sx={{
                          borderRight: theme.palette.mode === 'dark' ? '1px solid #515151' : '1px solid #adadad',
                          backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : 'inherit',
                        }}
                      >
                        £{totalProfits[index]}
                      </TableCell>
                    </React.Fragment>
                  ))}
                  <TableCell
                    align='center'
                    sx={{
                      backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : 'inherit',
                    }}
                  >
                    {data.reduce((total, cargoData) => total + cargoData.totalVolume, 0)}
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{
                      backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : 'inherit',
                    }}
                  >
                    £{data.reduce((total, cargoData) => total + cargoData.totalProfit, 0)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <GeneralDialogueActions noSubmission handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default MonthlyCommodityVolumeAndProfitTable;
