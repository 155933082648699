import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useTheme } from '@mui/system';
import React from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface ITabs {
  label: string;
  value: React.ReactNode;
}

interface HorizontalTabsProps {
  tabs: ITabs[];
}

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

interface StyledTabProps {
  label: string;
}

const StyledTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: '10px',
  color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.6)',
  '&.Mui-selected': {
    color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    backgroundColor: theme.palette.mode === 'dark' ? '#424242' : theme.palette.grey[400],
    borderRadius: '12px',
    fontWeight: theme.typography.fontWeightRegular,
  },
  '&.Mui-focusVisible': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(27, 27, 32, 0.32)' : 'rgba(225, 225, 225, 0.32)',
  },
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
    borderRadius: '12px',
  },
}));

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }} />
))({
  '& .MuiTabs-flexContainer': {
    gap: '1rem',
    marginBottom: '1rem',
    flexWrap: 'nowrap', // Prevent wrapping
    overflowX: 'auto', // Enable horizontal scrolling
  },
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 80,
    width: '100%',
  },
});

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box role='presentation'>{children}</Box>}
    </div>
  );
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const HorizontalTabs: React.FC<HorizontalTabsProps> = ({ tabs }) => {
  const [value, setValue] = React.useState(0);
  const theme = useTheme();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: '100%',
        bgcolor: theme.palette.mode === 'dark' ? '#212121' : theme.palette.grey[100],
        padding: '1rem',
        borderRadius: '12px',
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
        }}
      >
        <StyledTabs value={value} onChange={handleChange} aria-label='horizontal-tab'>
          {tabs.map((tab, index) => (
            <StyledTab
              key={index}
              label={tab.label}
              {...a11yProps(index)}
              sx={{
                flex: 1,
                fontSize: '1rem',
                maxWidth: 'fit-content',
                minHeight: '35px',
                lineHeight: '1',
                minWidth: 'max-content',
              }}
            />
          ))}
        </StyledTabs>
      </Box>
      <CustomTabPanel value={value} index={value}>
        {tabs[value].value}
      </CustomTabPanel>
    </Box>
  );
};

export default HorizontalTabs;
