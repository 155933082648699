import React, { useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { AlertColor, Backdrop, CircularProgress, IconButton } from '@mui/material';
import ActionDialogue from '../ActionDialogue';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { sec } from '../../auth/accessToken';
import { exportSteps, importSteps } from '../../dataAssets/actionSteps';
import { Booking, StepDbRef, StepDetails, StepStatus, StepType } from 'models/booking.model';
import AlertSnackbar from 'components/AlertSnackbar';
import ConfirmationDialog from 'components/ConfirmationDialog';
import ConfirmTable from 'components/ConfirmTable';
import { ContentCopy } from '@mui/icons-material';

type Tsnack = {
  open: boolean;
  message: string;
  severity?: AlertColor;
};

interface VerticalStepperProps {
  booking: Booking;
  hasCustomerCreditLimitExceeded: boolean;
  refetch: Function;
}
const VerticalStepper: React.FC<VerticalStepperProps> = ({ booking, hasCustomerCreditLimitExceeded, refetch }) => {
  const { bookingType, stepsCompleted, _id: id } = booking;
  const steps = bookingType === 'Export' ? exportSteps : importSteps;

  const [activeStep, setActiveStep] = useState(() => {
    const lastCompletedStepIndex = steps.findIndex(
      localStep =>
        stepsCompleted.findIndex(
          bookingStep => bookingStep.dbRef === localStep.dbRef && bookingStep.status === StepStatus.Completed,
        ) === -1,
    );
    return lastCompletedStepIndex === -1 ? steps.length - 1 : lastCompletedStepIndex;
  });

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState<{
    open: boolean;
    title: string;
    content: React.JSX.Element;
    onConfirm: () => Promise<void | undefined>;
  }>({
    open: false,
    title: '',
    content: <></>,
    onConfirm: async () => undefined,
  });
  const [buttonClicked, setButtonClicked] = useState(false);
  const [snackbar, setSnackbar] = useState<Tsnack>({
    open: false,
    message: '',
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmClose = () => {
    setConfirmDialog({
      open: false,
      title: '',
      content: <></>,
      onConfirm: async () => undefined,
    });
  };

  const updateStep = async (stepCompleted: StepDetails[], id: string) => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();

      const updatedData = {
        stepsCompleted: stepCompleted,
      };

      await axios.patch(`${process.env.REACT_APP_BASE_URL}/api/v1/bookings/${id}`, updatedData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      await refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const checkCompletionCriteria = async (): Promise<boolean> => {
    const checkFn = steps[activeStep]?.check?.bind(steps[activeStep]);
    if (typeof checkFn !== 'function') {
      return true;
    }
    const missingDep = await checkFn(booking);
    if (typeof missingDep === 'undefined' || missingDep.length <= 0) {
      return true;
    }
    const message = missingDep.reduce((msg, currentErr, idx) => {
      msg += `${idx + 1}. ${currentErr}\n`;
      return msg;
    }, 'Errors:\n');
    setSnackbar({
      open: true,
      message,
    });
    return false;
  };

  const handleStepCompleted = async (type: StepType): Promise<void> => {
    const newStepCompleted = [...stepsCompleted];
    const existingStepIdx = newStepCompleted.findIndex(step => step.dbRef === steps[activeStep].dbRef);
    if (existingStepIdx === -1) {
      newStepCompleted.push({
        dbRef: steps[activeStep].dbRef as StepDbRef,
        type,
        status: StepStatus.Completed,
      });
    } else {
      newStepCompleted[existingStepIdx] = {
        ...newStepCompleted[existingStepIdx],
        type,
        status: StepStatus.Completed,
      };
    }
    await updateStep(newStepCompleted, id);
    setSnackbar({
      open: true,
      message: 'Step marked as completed successfully',
      severity: 'success',
    });
    handleNext();
  };

  const isExport = (object: any): object is typeof exportSteps => {
    return bookingType === 'Export';
  };

  const completionHandler = (type: StepType) => async () => {
    if (!isExport(steps)) {
      return;
    }
    if (activeStep === exportSteps.length - 1 && bookingType === 'Export' && hasCustomerCreditLimitExceeded) {
      setSnackbar({
        open: true,
        message: 'Customer credit limit has been exceeded - Please wait till payment is collected',
      });
      return;
    }
    switch (type) {
      case StepType.Api:
        const confirmFn = async () => {
          setLoading(true);
          setConfirmDialog({
            open: false,
            title: '',
            content: <></>,
            onConfirm: async () => undefined,
          });
          try {
            const withApiFn = steps[activeStep]?.complete?.withApi?.bind(steps[activeStep]);
            if (typeof withApiFn === 'function') {
              await withApiFn(booking);
              setSnackbar({
                open: true,
                message: 'Successfully submitted data',
                severity: 'success',
              });
            } else {
              throw new Error();
            }
          } catch (e) {
            setSnackbar({
              open: true,
              message: 'Unable to submitted data',
              severity: 'error',
            });
          }
          setLoading(false);
          return refetch();
        };
        let dbValMap: Record<string, string> = {};
        const confirmMapfn = steps[activeStep]?.getConfirmMap?.bind(steps[activeStep]);
        if (typeof confirmMapfn === 'function') {
          dbValMap = await confirmMapfn(booking);
        }
        if (dbValMap && Object.keys(dbValMap).length > 0) {
          handleClose();
          setConfirmDialog({
            open: true,
            title: 'Confirm the values before proceeding',
            content: ConfirmTable(dbValMap),
            onConfirm: confirmFn,
          });
        } else {
          await confirmFn();
        }
        break;
      case StepType.Manual:
        const manuallyFn = steps[activeStep]?.complete?.manually?.bind(steps[activeStep]);
        if (typeof manuallyFn === 'function') {
          await manuallyFn();
        }
        await handleStepCompleted(type);
        handleClose();
        await refetch();
        break;
    }
  };

  const handleButtonClick = async (currentServerStep: StepDetails | undefined, step: (typeof steps)[0]) => {
    if (bookingType === 'Export' && typeof currentServerStep === 'undefined') {
      const hasNoDep = await checkCompletionCriteria();
      if (!hasNoDep) {
        return;
      }
      if (typeof steps[activeStep]?.complete?.withApi !== 'function') {
        return handleStepCompleted(StepType.Manual);
      }
      return handleClickOpen();
    }
    if (currentServerStep?.type !== StepType.Api) {
      return handleStepCompleted(StepType.Manual);
    }
    const newServerStep = step.fetchStatus ? await step.fetchStatus(booking) : undefined;
    setSnackbar({
      open: true,
      message: newServerStep?.status ?? 'Unknown status',
      severity: newServerStep?.status === StepStatus.Completed ? 'success' : 'error',
    });
    return refetch();
  };

  return (
    <Box>
      <Stepper
        activeStep={activeStep}
        orientation='vertical'
        sx={{
          mt: '1rem',
          '& .MuiStepConnector-line': {
            minHeight: '1.2rem',
          },
        }}
      >
        {steps.map((step, index) => {
          const currentServerStep = stepsCompleted.find(dbStep => dbStep.dbRef === step.dbRef);
          return (
            <Step key={step.label}>
              <StepLabel
                optional={
                  index === steps.length - 1 ? (
                    <Typography variant='caption' sx={{ color: '#c2d5dd' }}>
                      Last step
                    </Typography>
                  ) : null
                }
              >
                <Typography
                  sx={{
                    color: 'white',
                  }}
                >
                  {step.label}
                </Typography>
              </StepLabel>
              <StepContent>
                <Typography
                  sx={{
                    color: 'white',
                  }}
                >
                  {step.description}
                </Typography>
                <Box sx={{ mb: 2 }}>
                  <Box display={'flex'}>
                    <IconButton disabled={index === steps.length - 1} onClick={handleNext} sx={{ mt: 1, mr: 0.5 }}>
                      <KeyboardArrowDownOutlinedIcon />
                    </IconButton>

                    <Button
                      variant='contained'
                      onClick={async () => {
                        setButtonClicked(true);
                        try {
                          await handleButtonClick(currentServerStep, step);
                        } catch (e) {
                          setButtonClicked(false);
                          throw e;
                        }
                        setButtonClicked(false);
                      }}
                      sx={{ mt: 1, mr: 1 }}
                      disabled={
                        currentServerStep?.status === StepStatus.Completed ||
                        currentServerStep?.status === StepStatus.Failed ||
                        buttonClicked
                      }
                    >
                      {(() => {
                        if (buttonClicked) {
                          return 'Loading...';
                        }
                        if (currentServerStep?.status === StepStatus.Completed) {
                          return 'Completed';
                        }
                        if (currentServerStep?.type === StepType.Api) {
                          if (currentServerStep?.status === StepStatus.Failed) {
                            return 'API Failed';
                          }
                          return 'Check Status';
                        }
                        return 'Complete';
                      })()}
                    </Button>
                    {currentServerStep?.type === StepType.Api &&
                    currentServerStep?.status === StepStatus.Completed &&
                    step.dbRef === 'UCR' ? (
                      <Button
                        size='small'
                        disableElevation
                        sx={{ mt: 1, mr: 1 }}
                        variant='contained'
                        color='secondary'
                        endIcon={<ContentCopy />}
                        onClick={() => {
                          navigator.clipboard.writeText(currentServerStep.ref?.ducrId + '');
                          setSnackbar({
                            open: true,
                            message: 'copied to clipboard!',
                            severity: 'success',
                          });
                        }}
                      >
                        {currentServerStep.ref?.ducrId + ''}
                      </Button>
                    ) : null}
                    {currentServerStep?.status === StepStatus.Failed ? (
                      <Button
                        variant='contained'
                        onClick={() => completionHandler(StepType.Manual)()}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Completed Manually
                      </Button>
                    ) : null}
                    {index !== 0 ? (
                      <IconButton onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                        <KeyboardArrowUpOutlinedIcon />
                      </IconButton>
                    ) : null}
                  </Box>
                  <ActionDialogue
                    open={open}
                    handleClose={handleClose}
                    handleCompletedManually={completionHandler(StepType.Manual)}
                    apiHandler={
                      isExport(steps) && typeof steps[activeStep]?.complete?.withApi === 'function'
                        ? completionHandler(StepType.Api)
                        : undefined
                    }
                  />
                </Box>
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
      <ConfirmationDialog
        open={confirmDialog.open}
        handleClose={handleConfirmClose}
        onConfirm={confirmDialog.onConfirm}
        title={confirmDialog.title}
        content={confirmDialog.content}
      />
      <AlertSnackbar
        open={snackbar.open}
        handleClose={(_: any, reason: string) => setSnackbar({ open: false, message: '' })}
        severity={snackbar.severity ?? 'warning'}
        message={snackbar.message}
      />
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 100 }} open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </Box>
  );
};

export default VerticalStepper;
