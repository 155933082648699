import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AlertSnackbar from 'components/AlertSnackbar';
import ConfirmationDialog from 'components/ConfirmationDialog';
import GeneralDialogueActions from 'components/GeneralDialogueActions';
import dayjs from 'dayjs';
import { Invoice } from 'models/booking.model';
import {
  BaseDialogueProps,
  Customer,
  IAdvancesRefs,
  IAdvPaymentsResponse,
  IBankAccounts,
  InvoicesRefs,
  IReceiptsAndPayments,
  Vendor,
} from 'models/index.model';
import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbarSettings } from 'state';
import {
  useGetAccountingsQuery,
  useGetAdvancePaymentsQuery,
  useGetBankAccountsQuery,
  useGetCustomersQuery,
  useGetVendorsQuery,
} from 'state/api';
import { apiCall } from 'utils/serverFunctions';
import {
  getValueAndLabelFromAdvPayments,
  getValueAndLabelFromBankAccounts,
  getValueAndLabelFromCustomer,
  getValueAndLabelFromInvoices,
  totalInvoiceValue,
} from 'utils/utils';

interface CreateReceiptDialogProps extends BaseDialogueProps {
  entityToUpdate?: IReceiptsAndPayments;
  useFormReference: UseFormReturn<IReceiptsAndPayments>;
  defaultValues: any;
  type: 'Receipt' | 'Payment';
}

const CreateReceiptsAndPaymentsDialog: React.FC<CreateReceiptDialogProps> = ({
  handleClose,
  open,
  entityToUpdate,
  useFormReference,
  type,
  defaultValues,
}) => {
  const theme = useTheme();
  const isEditing = !!entityToUpdate?._id;
  const { data: customers } = useGetCustomersQuery({});
  const { data: vendors } = useGetVendorsQuery({});
  const { data: bankAccounts } = useGetBankAccountsQuery({});

  const { data: invoices, refetch: refetchInvoices } = useGetAccountingsQuery([
    type === 'Receipt' ? 'salesInvoices' : 'purchaseInvoices',
  ]);

  const { data: invoiceNotes, refetch: refetchInvoiceNotes } = useGetAccountingsQuery([
    type === 'Receipt' ? 'creditNotes' : 'debitNotes',
  ]);
  const { data: advancePayments, refetch: refetchAdvances } = useGetAdvancePaymentsQuery({
    parties: '',
  });

  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useFormReference;

  const {
    fields: invoiceRefFields,
    append: invoiceRefAppend,
    remove: invoiceRefRemove,
  } = useFieldArray({
    control,
    name: 'invoicesRefs',
  });

  const {
    fields: advanceRefFields,
    append: advanceRefAppend,
    remove: advanceRefRemove,
  } = useFieldArray({
    control,
    name: 'advancesRefs',
  });

  const dispatch = useDispatch();
  const selectedParty = watch('party');

  useEffect(() => {
    refetchInvoices();
    refetchInvoiceNotes();
    refetchAdvances();
  }, []);

  useEffect(() => {
    if (entityToUpdate?._id) {
      reset(entityToUpdate);
    } else {
      reset(defaultValues);
    }
  }, [reset, entityToUpdate]);

  const allInvoices = [...(invoices?.invoices || []), ...(invoiceNotes?.invoices || [])];
  const allParties = [...(customers?.masterData || []), ...(vendors?.masterData || [])];

  const selectedInvoiceRefs = watch('invoicesRefs');
  const selectedAdvancesRefs = watch('advancesRefs');
  const [confirmDialogueOpen, setConfirmDialogueOpen] = useState(false);
  const { snackbarOpen, snackbarSeverity, message } = useSelector((state: any) => state.global.snackbarSettings);
  const [partyToChange, setPartyToChange] = useState<Customer | Vendor | null>(null);

  const handlePartyChange = () => {
    setValue('invoicesRefs', [] as InvoicesRefs[]);
    setValue('advancesRefs', [] as IAdvancesRefs[]);
    setValue('party', partyToChange as Customer | Vendor);
    setPartyToChange(null);
  };

  const onSubmit = async (data: IReceiptsAndPayments) => {
    try {
      if (!amountValidator(Number(data.totalAmount), data.invoicesRefs, data.advancesRefs)) {
        dispatch(
          setSnackbarSettings({
            snackbarOpen: true,
            snackbarSeverity: 'error',
            message: 'Mismatch between the number of containers and the total number of containers across sites.',
          }),
        );
        return;
      }
      data['type'] = type;
      if (isEditing) {
        await apiCall(`/api/v1/receiptsAndPayments/${entityToUpdate._id}`, {
          method: 'PATCH',
          data,
        });
      } else {
        await apiCall(`/api/v1/receiptsAndPayments/`, {
          method: 'POST',
          data,
        });
      }
      reset(defaultValues);
      dispatch(
        setSnackbarSettings({
          snackbarOpen: true,
          snackbarSeverity: 'success',
          message: isEditing ? `${type} updated` : `${type} created`,
        }),
      );
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };

  const handleInvoiceRefChange = (index: number, newInvoiceRef: Invoice) => {
    let amount = 0;
    if (newInvoiceRef?.outstandingAmount) {
      amount = newInvoiceRef?.outstandingAmount;
    } else {
      amount = Number(totalInvoiceValue(newInvoiceRef?.charges || [], newInvoiceRef?.numberOfContainers || 0));
    }
    setValue(`invoicesRefs.${index}.amount`, Number(amount));
  };

  const amountValidator = (value: number, invoicesRefs: InvoicesRefs[], advancesRefs: IAdvancesRefs[]) => {
    const totalAmount = invoicesRefs.reduce((sum, ref) => {
      if (
        ref?.invoiceRef?.approvedInvoiceNumber?.includes('CN') ||
        ref?.invoiceRef?.approvedInvoiceNumber?.includes('DN')
      ) {
        return sum - Number(ref.amount);
      }
      return sum + Number(ref.amount);
    }, 0);

    const advAmount = advancesRefs.reduce((sum, ref) => {
      return sum + Number(ref.amount);
    }, 0);

    return value === totalAmount ? Number(totalAmount - advAmount) : Number(advAmount);
  };

  const accountOptions = ['USD', 'EUR', 'GBP'];

  const handleSnackbarClose = (event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(setSnackbarSettings({ snackbarOpen: false }));
  };
  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth='lg'>
        <DialogTitle
          sx={{
            fontSize: '1.6rem',
          }}
        >
          {isEditing ? `Edit ${type}` : `Create ${type}`}
        </DialogTitle>
        <DialogContent>
          <form>
            <Box display={'grid'} gridTemplateColumns={'1fr 1fr 1fr'} gap={'1rem'} sx={{ paddingTop: '10px' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  control={control}
                  name={'dateCreated'}
                  rules={{ required: `Date of ${type} is required` }}
                  render={({ field: { onChange, value, ref } }) => (
                    <DatePicker
                      format='DD/MM/YYYY'
                      label={`Date of ${type} *`}
                      value={value ? dayjs(value) : null}
                      onChange={date => onChange(dayjs(date).format())}
                      inputRef={ref}
                    />
                  )}
                />
              </LocalizationProvider>
              <Controller
                control={control}
                name={'party'}
                rules={{ required: `${type} party is required` }}
                render={({ field: { value, onChange } }) => (
                  <Autocomplete
                    value={value ? getValueAndLabelFromCustomer(value) : null}
                    onChange={(_, value) => {
                      const newParty = allParties?.find(allParty => allParty._id === value?.value);
                      if (newParty) {
                        setPartyToChange(newParty);
                        invoiceRefFields?.length > 0 ? setConfirmDialogueOpen(true) : onChange(newParty);
                      }
                    }}
                    options={(allParties ?? []).map((allParty: Customer | Vendor) =>
                      getValueAndLabelFromCustomer(allParty),
                    )}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={`${type} Party *`}
                        error={!!errors.party}
                        helperText={errors.party?.message}
                      />
                    )}
                  />
                )}
              />
              <Controller
                control={control}
                name={`totalAmount`}
                rules={{ required: 'Total Amount is required' }}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    autoComplete='off'
                    type='number'
                    label='Total amount *'
                    value={value || ''}
                    onChange={onChange}
                    error={!!errors.totalAmount}
                    helperText={errors.totalAmount?.message}
                  />
                )}
              />
            </Box>
            <Box
              m={'1rem 0'}
              overflow={'auto'}
              padding={'1rem'}
              border={'0.5px solid rgba(255, 255, 255, 0.12)'}
              borderRadius={'11px'}
            >
              <Box display={'flex'} justifyContent={'space-between'}>
                <Typography variant='h5'>Add Adjustments</Typography>
                <Tooltip title={!selectedParty && 'Select party to add'} placement='top'>
                  <span>
                    <Button
                      variant='contained'
                      type='button'
                      onClick={() => invoiceRefAppend({} as InvoicesRefs)}
                      sx={{
                        bgcolor: theme.palette.secondary[400],
                        '&:hover': {
                          backgroundColor: theme.palette.secondary[500],
                        },
                      }}
                      disabled={!selectedParty}
                    >
                      Add
                    </Button>
                  </span>
                </Tooltip>
              </Box>
              <Box m={'1rem 0'}>
                {invoiceRefFields?.map((item, index) => {
                  return (
                    <Box
                      display='grid'
                      gridTemplateColumns={'50fr 30fr  3fr'}
                      alignItems={'center'}
                      gap={'1rem'}
                      mt={'1rem'}
                      key={item.id || index}
                    >
                      <Controller
                        control={control}
                        name={`invoicesRefs.${index}.invoiceRef`}
                        rules={{ required: 'invoice ref is required' }}
                        render={({ field: { value, onChange } }) => (
                          <Autocomplete
                            value={value ? getValueAndLabelFromInvoices(value) : null}
                            options={(allInvoices ?? [])
                              .filter((invoice: Invoice) => {
                                const isSelected = selectedInvoiceRefs.find(
                                  ref => ref?.invoiceRef?._id === invoice?._id,
                                );
                                return (
                                  !invoice.approvalPending &&
                                  invoice.invoiceOutstanding &&
                                  (invoice?.customer?._id === selectedParty?._id ||
                                    invoice?.vendor?._id === selectedParty?._id) &&
                                  !isSelected
                                );
                              })
                              .map((invoice: Invoice) => getValueAndLabelFromInvoices(invoice))}
                            onChange={(_, value) => {
                              const newInvoiceRef = allInvoices?.find(invoice => invoice._id === value?.value);
                              onChange(newInvoiceRef);
                              newInvoiceRef && handleInvoiceRefChange(index, newInvoiceRef);
                            }}
                            isOptionEqualToValue={(option, value) => option.label === value.label}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label='Reference No.*'
                                error={!!errors.invoicesRefs?.[index]?.invoiceRef}
                                helperText={errors.invoicesRefs?.[index]?.invoiceRef?.message}
                              />
                            )}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name={`invoicesRefs.${index}.amount`}
                        rules={{ required: 'Amount is required' }}
                        render={({ field: { value, onChange } }) => (
                          <TextField
                            autoComplete='off'
                            type='number'
                            label='Amount *'
                            value={value || ''}
                            onChange={onChange}
                            error={!!errors.invoicesRefs?.[index]?.amount}
                            helperText={errors.invoicesRefs?.[index]?.amount?.message}
                          />
                        )}
                      />
                      <Button
                        variant='contained'
                        type='button'
                        onClick={() => invoiceRefRemove(index)}
                        sx={{
                          bgcolor: theme.palette.secondary[400],
                          '&:hover': {
                            backgroundColor: theme.palette.secondary[500],
                          },
                        }}
                      >
                        Delete
                      </Button>
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box
              m={'1rem 0'}
              overflow={'auto'}
              padding={'1rem'}
              border={'0.5px solid rgba(255, 255, 255, 0.12)'}
              borderRadius={'11px'}
            >
              <Box display={'flex'} justifyContent={'space-between'}>
                <Typography variant='h5'>Add Advances</Typography>
                <Tooltip title={!selectedParty && 'Select party to add'} placement='top'>
                  <span>
                    <Button
                      variant='contained'
                      type='button'
                      onClick={() => advanceRefAppend({} as IAdvancesRefs)}
                      sx={{
                        bgcolor: theme.palette.secondary[400],
                        '&:hover': {
                          backgroundColor: theme.palette.secondary[500],
                        },
                      }}
                      disabled={!selectedParty}
                    >
                      Add
                    </Button>
                  </span>
                </Tooltip>
              </Box>
              <Box m={'1rem 0'}>
                {advanceRefFields?.map((item, index) => {
                  return (
                    <Box
                      display='grid'
                      gridTemplateColumns={'50fr 30fr  3fr'}
                      alignItems={'center'}
                      gap={'1rem'}
                      mt={'1rem'}
                      key={item.id || index}
                    >
                      <Controller
                        control={control}
                        name={`advancesRefs.${index}.advanceRef`}
                        rules={{ required: 'advance ref is required' }}
                        render={({ field: { value, onChange } }) => (
                          <Autocomplete
                            value={value ? getValueAndLabelFromAdvPayments(value) : null}
                            options={(advancePayments?.advancePayments ?? [])
                              .filter((advPayment: IAdvPaymentsResponse) => {
                                const isSelected = selectedAdvancesRefs?.find(
                                  ref => ref?.advanceRef?._id === advPayment?._id,
                                );
                                return (
                                  advPayment.settlementOutstanding &&
                                  advPayment?.party?._id === selectedParty?._id &&
                                  !isSelected
                                );
                              })
                              .map((advPayment: IAdvPaymentsResponse) => getValueAndLabelFromAdvPayments(advPayment))}
                            onChange={(_, value) => {
                              const newAdvanceRef = advancePayments?.advancePayments?.find(
                                advPayment => advPayment._id === value?.value,
                              );
                              onChange(newAdvanceRef);
                              newAdvanceRef &&
                                setValue(`advancesRefs.${index}.amount`, newAdvanceRef.outstandingAmount);
                            }}
                            isOptionEqualToValue={(option, value) => option.label === value.label}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label='Reference No.*'
                                error={!!errors.advancesRefs?.[index]?.advanceRef}
                                helperText={errors.advancesRefs?.[index]?.advanceRef?.message}
                              />
                            )}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name={`advancesRefs.${index}.amount`}
                        rules={{ required: 'Amount is required' }}
                        render={({ field: { value, onChange } }) => (
                          <TextField
                            autoComplete='off'
                            type='number'
                            label='Amount *'
                            value={value || ''}
                            onChange={onChange}
                            error={!!errors.advancesRefs?.[index]?.amount}
                            helperText={errors.advancesRefs?.[index]?.amount?.message}
                          />
                        )}
                      />
                      <Button
                        variant='contained'
                        type='button'
                        onClick={() => advanceRefRemove(index)}
                        sx={{
                          bgcolor: theme.palette.secondary[400],
                          '&:hover': {
                            backgroundColor: theme.palette.secondary[500],
                          },
                        }}
                      >
                        Delete
                      </Button>
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box display={'grid'} gridTemplateColumns={'1fr 1fr'} gap={'1rem'} sx={{ paddingTop: '10px' }}>
              <Controller
                control={control}
                name={'accounts'}
                rules={{ required: `Accounts is required` }}
                render={({ field: { value, onChange } }) => (
                  <Autocomplete
                    value={value ? getValueAndLabelFromBankAccounts(value) : null}
                    onChange={(_, value) => {
                      const newAcc = bankAccounts?.masterData?.find(bankAccount => bankAccount._id === value?.value);
                      if (newAcc) {
                        onChange(newAcc);
                      }
                    }}
                    options={(bankAccounts?.masterData ?? []).map((bankAccount: IBankAccounts) =>
                      getValueAndLabelFromBankAccounts(bankAccount),
                    )}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={`A/C No. *`}
                        error={!!errors.accounts}
                        helperText={errors.accounts?.message}
                      />
                    )}
                  />
                )}
              />
              <Controller
                control={control}
                name={`remarks`}
                render={({ field: { value, onChange } }) => (
                  <TextField autoComplete='off' type='text' label='Remarks' value={value || ''} onChange={onChange} />
                )}
              />
            </Box>
          </form>
        </DialogContent>
        <GeneralDialogueActions
          onClick={handleSubmit(onSubmit)}
          handleClose={handleClose}
          submitText={isEditing ? 'Edit' : 'Create'}
        />
      </Dialog>
      <ConfirmationDialog
        open={confirmDialogueOpen}
        handleClose={() => setConfirmDialogueOpen(false)}
        title={`Confirm party change from ${selectedParty?.name} to ${partyToChange?.name}`}
        content='Changing the party will remove all the selected adjustments. Are you sure you want to proceed?'
        onConfirm={() => {
          setConfirmDialogueOpen(false);
          handlePartyChange();
        }}
      />

      <AlertSnackbar
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        severity={snackbarSeverity}
        message={message}
      />
    </Box>
  );
};

export default CreateReceiptsAndPaymentsDialog;
