import React, { useReducer, useState } from 'react';
import { useGetAuditsQuery, useGetSitesQuery } from '../../state/api';
import { AddOutlined } from '@mui/icons-material';
import { GridColDef } from '@mui/x-data-grid';
import Header from '../../components/Header';
import CreateSiteDialogue from '../../components/CreateSiteDialogue';
import { Box, Button, useTheme } from '@mui/material';
import { Site } from 'models/index.model';
import { initialState, reducer } from '../../utils/dialogState';
import { useUserPermissions } from 'utils/utils';
import { generateActionsColumn } from 'utils/generateActionsColumn';
import { sec } from 'auth/accessToken';
import axios from 'axios';
import CustomDataGrid from 'components/CustomDataGrid';
import AuditLogsDialogue from 'components/AuditLogsDialogue';
import SearchBar from 'components/SearchBar';

const Sites = () => {
  const theme = useTheme();

  const userPermissions = useUserPermissions();
  const hasCreateMasterDataPermission = userPermissions.includes('create:masterdata');
  const hasUpdateMasterDataPermission = userPermissions.includes('update:masterdata');
  const hasDeleteMasterDataPermission = userPermissions.includes('delete:masterdata');

  const [{ open, entityToUpdate }, dispatch] = useReducer(reducer<Site>, initialState<Site>());

  const handleClickOpenCreate = () => {
    dispatch({ type: 'OPEN_DIALOG', payload: {} as Site });
  };

  const handleClickOpenEdit = (site: Site) => {
    dispatch({ type: 'OPEN_DIALOG', payload: site });
  };

  const handleClose = () => {
    dispatch({ type: 'CLOSE_DIALOG' });
    refetch();
  };

  const [openConfirmDialogue, setOpenConfirmDialogue] = useState(false);
  const [openAuditDialogue, setOpenAuditDialogue] = useState(false);
  const [currentMasterDataId, setCurrentMasterDataId] = useState<string>('');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [totalRows, setTotalRows] = useState(0);
  const [searchText, setSearchText] = useState<string>('');
  const [selectedField, setSelectedField] = useState<string>('');

  const {
    data: sites,
    isLoading,
    refetch,
  } = useGetSitesQuery({ page: (page + 1).toString(), limit: pageSize.toString(), searchText, selectedField });

  React.useEffect(() => {
    setTotalRows(sites?.totalRecords ?? 0);
  }, [sites?.totalRecords]);

  const { data: auditLogs } = useGetAuditsQuery(['Site', currentMasterDataId]);

  const deleteSite = async (siteId: string) => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();
      await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/v1/sites/${siteId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'siteName',
      headerName: 'Site Name',
      flex: 0.5,
    },
    {
      field: 'address',
      headerName: 'Address',
      flex: 1.2,
    },
    {
      field: 'city',
      headerName: 'City',
      flex: 0.3,
    },
    {
      field: 'postcode',
      headerName: 'Postcode',
      flex: 0.2,
    },
    {
      field: 'country',
      headerName: 'Country',
      flex: 0.3,
    },
  ];

  if (hasUpdateMasterDataPermission || hasDeleteMasterDataPermission) {
    columns.push(
      generateActionsColumn<Site>(
        hasUpdateMasterDataPermission,
        hasDeleteMasterDataPermission,
        handleClickOpenEdit,
        setOpenAuditDialogue,
        deleteSite,
        openConfirmDialogue,
        setOpenConfirmDialogue,
        currentMasterDataId,
        setCurrentMasterDataId,
      ),
    );
  }

  return (
    <Box m='1.5rem 2.5rem'>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Box display={'flex'} gap={2} justifyContent={'center'} alignItems={'center'}>
          <Header title='Sites' />
          <SearchBar
            setSearchText={setSearchText}
            setSelectedField={setSelectedField}
            setPage={setPage}
            columns={columns}
          />
        </Box>
        <Box>
          {hasCreateMasterDataPermission && (
            <Button
              variant='contained'
              startIcon={<AddOutlined />}
              onClick={handleClickOpenCreate}
              sx={{
                bgcolor: theme.palette.secondary[400],
                '&:hover': {
                  backgroundColor: theme.palette.secondary[500],
                },
              }}
            >
              Create Site
            </Button>
          )}
          <CreateSiteDialogue handleClose={handleClose} open={open} siteToUpdate={entityToUpdate} />
        </Box>
      </Box>
      <CustomDataGrid
        data={sites?.masterData as Site[]}
        columns={columns}
        isLoading={isLoading}
        sortModel={[{ field: 'siteName', sort: 'asc' }]}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
        totalRows={totalRows}
      />
      <AuditLogsDialogue
        open={openAuditDialogue}
        handleClose={() => setOpenAuditDialogue(false)}
        auditLogs={auditLogs}
      />
    </Box>
  );
};

export default Sites;
