import React from 'react';
import axios from 'axios';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { Box, Button, useTheme, TextField, Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import { AddOutlined, RemoveOutlined } from '@mui/icons-material';
import { sec } from '../../auth/accessToken';
import { BaseDialogueProps } from 'models/index.model';
import { Booking } from 'models/booking.model';
import GeneralDialogueActions from 'components/GeneralDialogueActions';
import { useGetAuditsQuery } from 'state/api';
import { generateMenuItemsFromArray } from 'utils/generateMenuItemsFromArray';
import { blStatuses } from 'dataAssets/constants';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

interface UpdateBLHistoryDialogueProps extends BaseDialogueProps {
  booking: Booking;
}

const UpdateBLHistoryDialogue: React.FC<UpdateBLHistoryDialogueProps> = ({ handleClose, open, booking }) => {
  const theme = useTheme();

  const { handleSubmit, control } = useForm({
    defaultValues: {
      blStatusHistory: booking.blStatusHistory ?? [],
      blRemarks: booking.blRemarks ?? '',
    },
  });

  const {
    fields: blStatusHistoryFields,
    append: blStatusHistoryAppend,
    remove: blStatusHistoryRemove,
  } = useFieldArray({
    control,
    name: 'blStatusHistory',
  });

  const { refetch: auditLogsRefetch } = useGetAuditsQuery(['Booking', booking._id]);

  const onSubmit = async (data: any) => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();

      await axios.patch(`${process.env.REACT_APP_BASE_URL}/api/v1/bookings/${booking._id}`, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      handleClose();
      auditLogsRefetch();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth='lg'>
        <DialogTitle
          sx={{
            fontSize: '1.6rem',
          }}
        >
          Update BL History
        </DialogTitle>
        <DialogContent>
          <form>
            <Box mb='1rem'>
              <Button
                variant='contained'
                type='button'
                startIcon={<AddOutlined />}
                onClick={() =>
                  blStatusHistoryAppend({
                    status: '',
                    statusDate: '',
                    followUp: '',
                    followUpDate: '',
                  })
                }
                sx={{
                  bgcolor: theme.palette.secondary[400],
                  mb: '1rem',
                  '&:hover': {
                    backgroundColor: theme.palette.secondary[500],
                  },
                }}
              >
                Add BL Status
              </Button>
            </Box>
            {blStatusHistoryFields.map((item, index) => {
              return (
                <Box
                  key={index}
                  display={'grid'}
                  gridTemplateColumns={'5fr 5fr 5fr 5fr 0.1fr'}
                  alignItems={'center'}
                  gap={'1rem'}
                  mb={'1rem'}
                >
                  <Controller
                    name={`blStatusHistory.${index}.status`}
                    control={control}
                    render={({ field }) => (
                      <TextField {...field} label='Status' variant='outlined' fullWidth select>
                        {generateMenuItemsFromArray(blStatuses)}
                      </TextField>
                    )}
                  />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                      name={`blStatusHistory.${index}.statusDate`}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <DatePicker
                          format='DD/MM/YYYY'
                          label={'Date'}
                          value={value ? dayjs(value) : null}
                          onChange={date => onChange(dayjs(date).format())}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <Controller
                    name={`blStatusHistory.${index}.followUp`}
                    control={control}
                    render={({ field }) => <TextField {...field} label='Follow Up' variant='outlined' fullWidth />}
                  />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                      name={`blStatusHistory.${index}.followUpDate`}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <DatePicker
                          format='DD/MM/YYYY'
                          label={'Follow Up Date'}
                          value={value ? dayjs(value) : null}
                          onChange={date => onChange(dayjs(date).format())}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <>
                    <IconButton
                      type='button'
                      onClick={() => blStatusHistoryRemove(index)}
                      sx={{
                        bgcolor: theme.palette.secondary[400],
                        '&:hover': {
                          backgroundColor: theme.palette.secondary[500],
                        },
                      }}
                    >
                      <RemoveOutlined sx={{ color: 'white' }} />
                    </IconButton>
                  </>
                </Box>
              );
            })}
            <Box>
              <TextField label='Remarks' variant='outlined' fullWidth rows={4} {...control.register('blRemarks')} />
            </Box>
          </form>
        </DialogContent>
        <GeneralDialogueActions onClick={handleSubmit(onSubmit)} handleClose={handleClose} submitText='Update' />
      </Dialog>
    </Box>
  );
};

export default UpdateBLHistoryDialogue;
