import { AddOutlined, FilterAltOutlined, RefreshOutlined, SaveOutlined } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { AlertColor, Box, IconButton, Tooltip, useTheme } from '@mui/material';
import { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import AlertSnackbar from 'components/AlertSnackbar';
import BookingsFilterSideBar from 'components/BookingsFilterSideBar';
import CustomDataGrid from 'components/CustomDataGrid';
import dayjs from 'dayjs';
import { TransportSchedule, TransportSite } from 'models/booking.model';
import { Customer, FilterType, Port, Vendor } from 'models/index.model';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';
import { setBookingsColumnVisibilityModel } from 'state';
import { handleSnackbarClose, setBookingTeams, useUserPermissions } from 'utils/utils';
import Header from '../../components/Header';
import { useGetBookingsQuery } from '../../state/api';
import { saveUserPreferences } from 'utils/serverFunctions';
import SearchBar from 'components/SearchBar';

const Bookings = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('success');
  const userPermissions = useUserPermissions();
  const hasExportPermission = userPermissions.includes('read:bookings-export');
  const hasImportPermission = userPermissions.includes('read:bookings-import');
  const teams = setBookingTeams(hasExportPermission, hasImportPermission);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [totalRows, setTotalRows] = useState(0);
  const [searchText, setSearchText] = useState<string>('');
  const [selectedField, setSelectedField] = useState<string>('');

  const {
    startDate,
    endDate,
    bookingStatus,
    bookingTeam,
    consignor,
    consignee,
    carrier,
    portOfLoading,
    portOfDestination,
    containerType,
    salesInvoiceCreated,
    purchaseInvoiceCreated,
    bookingConfirmationSent,
    profitsMatching,
    allStepsCompleted,
    blStatus,
    outstandingAdditionalCharges,
    pic,
  } = useSelector((state: any) => state.global.bookingsFilters);

  const {
    data: bookings,
    isLoading,
    refetch,
    isFetching,
  } = useGetBookingsQuery({
    hasExportPermission,
    hasImportPermission,
    dateRange: [startDate, endDate],
    bookingStatus,
    bookingTeam,
    consignor: consignor?.map((item: Customer) => item?._id).join(','),
    consignee: consignee?.map((item: Customer) => item?._id).join(','),
    carrier: carrier?.map((item: Vendor) => item?._id).join(','),
    portOfLoading: portOfLoading?.map((item: Port) => item?._id).join(','),
    portOfDestination: portOfDestination?.map((item: Port) => item?._id).join(','),
    containerType,
    salesInvoiceCreated,
    purchaseInvoiceCreated,
    bookingConfirmationSent,
    profitsMatching,
    allStepsCompleted,
    blStatus,
    outstandingAdditionalCharges,
    pic,
    page: (page + 1).toString(),
    limit: pageSize.toString(),
    searchText,
    selectedField,
  });

  React.useEffect(() => {
    setTotalRows(bookings?.totalBookings ?? 0);
  }, [bookings?.totalBookings]);

  const columns: GridColDef[] = [
    {
      field: 'bookingNumber',
      headerName: 'Booking Number',
      flex: 0.3,
    },
    {
      field: 'consignor',
      headerName: 'Shipper',
      flex: 0.6,
      valueGetter: params => {
        return params.row.consignor?.name;
      },
    },
    {
      field: 'consignee',
      headerName: 'Consignee',
      flex: 0.6,
      valueGetter: params => {
        return params.row.consignee?.name;
      },
    },
    {
      field: 'notifyParty',
      headerName: 'Notify Party',
      flex: 0.6,
      valueGetter: params => {
        return params.row.notifyParty?.name;
      },
    },
    {
      field: 'carrier',
      headerName: 'Carrier',
      flex: 0.3,
      valueGetter: params => {
        return params.row.carrier?.name;
      },
    },
    {
      field: 'carrierBookingNumber',
      headerName: 'Carrier Booking Number',
      flex: 0.3,
    },
    {
      field: 'carrierQuotationReference',
      headerName: 'Carrier Quote Ref',
      flex: 0.2,
    },
    {
      field: 'purchaseOrderNumber',
      headerName: 'PO Number',
      flex: 0.3,
    },
    {
      field: 'mblNumber',
      headerName: 'MBL',
      flex: 0.3,
    },
    {
      field: 'hblNumber',
      headerName: 'HBL',
      flex: 0.3,
    },
    { field: 'blRemarks', headerName: 'BL Remarks', flex: 0.3 },
    {
      field: 'blStatus',
      headerName: 'BL Status',
      flex: 0.4,
      valueGetter: params => {
        if (params.row.blStatusHistory.length > 0) {
          return `${params.row.blStatusHistory.slice(-1).pop()?.status} `;
        }
      },
    },
    {
      field: 'blStatusDate',
      headerName: 'BL Status Date',
      flex: 0.3,
      valueGetter: params => {
        if (params.row.blStatusHistory.slice(-1).pop()?.statusDate) {
          return dayjs(params.row.blStatusHistory.slice(-1).pop()?.statusDate).format('DD/MM/YY');
        }
      },
    },
    {
      field: 'followUp',
      headerName: 'Follow Up',
      flex: 0.3,
      valueGetter: params => {
        if (params.row.blStatusHistory.length > 0) {
          return params.row.blStatusHistory.slice(-1).pop()?.followUp;
        }
      },
    },
    {
      field: 'followUpDate',
      headerName: 'Follow Up Date',
      flex: 0.3,
      valueGetter: params => {
        if (params.row.blStatusHistory.slice(-1).pop()?.followUpDate) {
          return dayjs(params.row.blStatusHistory.slice(-1).pop()?.followUpDate).format('DD/MM/YY');
        }
      },
    },
    {
      field: 'agent',
      headerName: 'Agent',
      flex: 0.4,
      valueGetter: params => {
        return params.row.agent?.name;
      },
    },
    {
      field: 'haulers',
      headerName: 'Haulers',
      flex: 0.5,
      valueGetter: params => {
        const uniqueHaulers = (params.row.transportSchedule as TransportSchedule[]).reduce((acc, transportSchedule) => {
          const hauler = transportSchedule.hauler ?? 'N/A';
          if (!acc.includes(hauler)) {
            acc.push(hauler);
          }
          return acc;
        }, [] as string[]);

        return uniqueHaulers.join(', ');
      },
    },
    {
      field: 'sites',
      headerName: 'Sites',
      flex: 0.3,
      valueGetter: params => {
        return (params.row.transportSites as TransportSite[])
          .map(transportSite => transportSite.site?.city ?? 'N/A')
          .join(', ');
      },
    },
    {
      field: 'portOfLoading',
      headerName: 'POL',
      flex: 0.3,
      valueGetter: params => {
        return params.row.portOfLoading?.portName;
      },
    },
    {
      field: 'portOfDestination',
      headerName: 'POD',
      flex: 0.3,
      valueGetter: params => {
        return params.row.portOfDestination?.portName;
      },
    },
    {
      field: 'placeOfDelivery',
      headerName: 'FPOD',
      flex: 0.3,
      valueGetter: params => {
        return params.row.placeOfDelivery?.portName;
      },
    },

    {
      field: 'etaPOL',
      headerName: 'ETA (POL)',
      flex: 0.2,
      valueGetter: params => {
        return params.row.etaPOL && dayjs(params.row.etaPOL).format('DD/MM/YY');
      },
    },
    {
      field: 'actualEtaPOL',
      headerName: 'Actual ETA (POL)',
      flex: 0.2,
      valueGetter: params => {
        return params.row.actualEtaPOL && dayjs(params.row.actualEtaPOL).format('DD/MM/YY');
      },
    },
    {
      field: 'etd',
      headerName: 'ETD',
      flex: 0.2,
      valueGetter: params => {
        return params.row.etd && dayjs(params.row.etd).format('DD/MM/YY');
      },
    },

    {
      field: 'actualEtd',
      headerName: 'Actual ETD',
      flex: 0.2,
      valueGetter: params => {
        return params.row.actualEtd && dayjs(params.row.actualEtd).format('DD/MM/YY');
      },
    },
    {
      field: 'eta',
      headerName: 'ETA (POD)',
      flex: 0.2,
      valueGetter: params => {
        return params.row.eta && dayjs(params.row.eta).format('DD/MM/YY');
      },
    },
    {
      field: 'actualEtaPOD',
      headerName: 'Actual ETA (POD)',
      flex: 0.2,
      valueGetter: params => {
        return params.row.actualEtaPOD && dayjs(params.row.actualEtaPOD).format('DD/MM/YY');
      },
    },
    {
      field: 'quotationApproval',
      headerName: 'Quote Approval',
      flex: 0.3,
      valueGetter: params => {
        return params.row.quotationApproval ? 'Approved' : 'Pending';
      },
    },
    {
      field: 'bookingConfirmationSent',
      headerName: 'BC Sent',
      flex: 0.2,
      valueGetter: params => {
        return params.row.bookingConfirmationSent ? 'Yes' : 'No';
      },
    },
    {
      field: 'numberOfContainers',
      headerName: 'CNTS',
      flex: 0.1,
    },
    { field: 'packages', headerName: 'Packages', flex: 0.2 },
    { field: 'approximateWeight', headerName: 'Weight', flex: 0.2 },
    { field: 'containerType', headerName: 'Container Type', flex: 0.3 },
    { field: 'doorFacing', headerName: 'Door Facing', flex: 0.2 },
    {
      field: 'releasePin',
      headerName: 'Release Pin',
      flex: 0.3,
      valueGetter: params => {
        return params.row.bookingType === 'Import' ? params.value : '-';
      },
    },
    {
      field: 'loadingTypeExport',
      headerName: 'Export Loading Type',
      flex: 0.2,
    },
    {
      field: 'loadingTypeImport',
      headerName: 'Import Loading Type',
      flex: 0.2,
    },
    {
      field: 'cargoDescription',
      headerName: 'Cargo',
      flex: 0.3,
    },
    {
      field: 'HSCode',
      headerName: 'HS Code',
      flex: 0.2,
    },
    {
      field: 'incoterm',
      headerName: 'Incoterm',
      flex: 0.2,
    },
    {
      field: 'cargoValue',
      headerName: 'Cargo Value',
      flex: 0.2,
      valueGetter: params => {
        return `${params.row.cargoValue?.currency}  ${params.row.cargoValue?.value}`;
      },
    },
    {
      field: 'vesselVoyage',
      headerName: 'Vessel',
      flex: 0.4,
      valueGetter: params => {
        return `${params.row.vesselVoyage?.vesselName} ${params.row.vesselVoyage?.voyageNumber}`;
      },
    },
    {
      field: 'dateCreated',
      headerName: 'Date Created',
      flex: 0.3,
      valueGetter: params => {
        return dayjs(params.row.dateCreated).format('DD/MM/YYYY');
      },
    },
    {
      field: 'bookingTeam',
      headerName: 'Team',
      flex: 0.2,
    },
    {
      field: 'pic',
      headerName: 'PIC',
      flex: 0.4,
    },
    { field: 'bookingType', headerName: 'Type', flex: 0.2 },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: cellValues => {
        return (
          <Box display={'flex'} justifyContent={'space-evenly'}>
            <Tooltip title='View Booking'>
              <IconButton
                sx={{ color: theme.palette.secondary[400] }}
                onClick={() => {
                  navigate(
                    generatePath(`/bookings/${cellValues.row._id}`, {
                      id: cellValues.row._id,
                    }),
                  );
                }}
              >
                <InfoOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
      flex: 0.2,
    },
  ];

  const bookingsColumnVisibilityModel = useSelector((state: any) => state.global.bookingsColumnVisibilityModel);

  const bookingsFilters = useSelector((state: any) => state.global.bookingsFilters);
  const userEmail = useSelector((state: any) => state.global.userEmail);

  const handleColumnVisibilityChange = (newModel: GridColumnVisibilityModel) => {
    dispatch(setBookingsColumnVisibilityModel(newModel));
  };

  const handleSaveUserPreferences = async (userEmail: string) => {
    const { startDate, endDate, ...modifiedBookingsFilters } = bookingsFilters;
    const data = { bookingsColumnVisibilityModel, bookingsFilters: modifiedBookingsFilters };
    const saveUpResponse = (await saveUserPreferences(userEmail, data, 'bookings')) ?? 'error';
    setSnackbarOpen(true);
    setSnackbarSeverity(saveUpResponse);
  };

  const iconButtonStyling = {
    color: theme.palette.secondary[400],
    '&:hover': {
      color: theme.palette.secondary[500],
    },
  };

  return (
    <Box m='1.5rem 2.5rem'>
      <Box>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Box display={'flex'} gap={2} justifyContent={'center'} alignItems={'center'}>
            <Header title='Bookings' />
            <SearchBar
              setSearchText={setSearchText}
              setSelectedField={setSelectedField}
              setPage={setPage}
              columns={columns}
            />
          </Box>
          <Box>
            <Tooltip title='Create Booking'>
              <IconButton
                sx={iconButtonStyling}
                onClick={() => {
                  navigate(generatePath(`/bookings/create`));
                }}
              >
                <AddOutlined />
              </IconButton>
            </Tooltip>
            <Tooltip title='Save Preferences'>
              <IconButton onClick={() => handleSaveUserPreferences(userEmail)} sx={iconButtonStyling}>
                <SaveOutlined />
              </IconButton>
            </Tooltip>
            <Tooltip title='Refresh'>
              <IconButton onClick={() => refetch()} sx={iconButtonStyling}>
                <RefreshOutlined />
              </IconButton>
            </Tooltip>
            <Tooltip title='Filter'>
              <IconButton onClick={() => setDrawerOpen(true)} sx={iconButtonStyling}>
                <FilterAltOutlined />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <CustomDataGrid
        data={bookings?.filteredBookings || []}
        columns={columns}
        isLoading={isLoading}
        isFetching={isFetching}
        columnVisibilityModel={bookingsColumnVisibilityModel}
        handleColumnVisibilityChange={handleColumnVisibilityChange}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
        totalRows={totalRows}
      />
      <BookingsFilterSideBar
        type={FilterType.Bookings}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        teams={teams}
      />
      <AlertSnackbar
        open={snackbarOpen}
        handleClose={(_: any, reason: string) => handleSnackbarClose(reason, setSnackbarOpen)}
        severity={snackbarSeverity}
        message={snackbarSeverity === 'success' ? 'Preferences saved successfully' : 'Error saving user preference'}
      />
    </Box>
  );
};

export default Bookings;
