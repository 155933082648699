import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogContent,
  Box,
  Tooltip,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { MonthlyHaulerStats } from 'models/analytics';
import { BaseDialogueProps } from 'models/index.model';
import GeneralDialogueActions from 'components/GeneralDialogueActions';
import { getAllMonthNames, getCurrentMonth, handleExcelDownload, truncateName } from 'utils/utils';
import { DownloadOutlined } from '@mui/icons-material';
import { useTheme } from '@mui/system';

interface MonthlyHaulerStatsTableProps extends BaseDialogueProps {
  data: MonthlyHaulerStats[];
  bookingType: string;
  selectedYear: number;
  selectedMonth: number | null;
}

const MonthlyHaulerStatsTable: React.FC<MonthlyHaulerStatsTableProps> = ({
  data,
  bookingType,
  handleClose,
  open,
  selectedYear,
  selectedMonth,
}) => {
  const currentMonth = getCurrentMonth(selectedYear, selectedMonth);
  const allMonths = getAllMonthNames(currentMonth);
  const excelFileName = selectedMonth
    ? `Container-Type-Monthly-${bookingType}-Report_${allMonths[selectedMonth - 1]}-${selectedYear.toString()}`
    : `Container-Type-Monthly-${bookingType}-Report_${selectedYear.toString()}`;
  const [transformedData, setTransformedData] = useState<{ Hauler: string; [key: string]: string | number }[]>([]);
  const theme = useTheme();

  useEffect(() => {
    const s_transformedData = data.map(item => {
      const haulerStatsColumns: { [key: string]: string } = {};

      allMonths.map((month, index) => {
        haulerStatsColumns[`${month} (Completed)`] = `${item.monthlyHaulerStats[index].completedLoadings}`;
        haulerStatsColumns[`${month} (Cancelled)`] = `${item.monthlyHaulerStats[index].canceledLoadings}`;
      });

      return {
        Hauler: item.haulerName,
        ...haulerStatsColumns,
        'Total Loadings': item.totalLoadings,
      };
    });

    setTransformedData(s_transformedData);
  }, [data]);

  const [totalCompletedLoadings, totalCanceledLoadings] = allMonths
    .map(month => {
      return data.reduce(
        ([completedTotal, canceledTotal], haulerName) => {
          const monthData = haulerName.monthlyHaulerStats.find(m => m.month === month);
          return [
            completedTotal + (monthData?.completedLoadings || 0),
            canceledTotal + (monthData?.canceledLoadings || 0),
          ];
        },
        [0, 0],
      );
    })
    .reduce(
      ([completedLoadings, canceledLoadings], [completedTotal, canceledTotal]) => {
        completedLoadings.push(completedTotal);
        canceledLoadings.push(canceledTotal);
        return [completedLoadings, canceledLoadings];
      },
      [[], []] as [number[], number[]],
    );

  const transformedHaulerStats: { [key: string]: string } = {};
  allMonths.forEach((month, index) => {
    transformedHaulerStats[`${month} (Completed)`] = `${totalCompletedLoadings[index]}`;
    transformedHaulerStats[`${month} (Cancelled)`] = `${totalCanceledLoadings[index]}`;
  });

  const transformedDataWithTotalLoadings = [
    ...transformedData,
    {
      Hauler: 'YTD',
      ...transformedHaulerStats,
      'Total Loadings': data.reduce((total, hauler) => total + hauler.totalLoadings, 0),
    },
  ];

  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullWidth fullScreen maxWidth='xl'>
        <DialogTitle>
          <Box display={'flex'} alignItems={'center'} gap={1}>
            {bookingType} Monthly Hauler Report
            <Tooltip title='Download as Excel' placement='top'>
              <IconButton onClick={() => handleExcelDownload(transformedDataWithTotalLoadings, excelFileName)}>
                <DownloadOutlined />
              </IconButton>
            </Tooltip>
          </Box>
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    align='center'
                    sx={{
                      borderRight: theme.palette.mode === 'dark' ? '1px solid #515151' : '1px solid #adadad',
                      backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : 'inherit',
                    }}
                  >
                    Hauler
                  </TableCell>
                  {allMonths.map(month => (
                    <React.Fragment key={month}>
                      <TableCell
                        align='center'
                        sx={{
                          backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : 'inherit',
                        }}
                      >
                        {month} (Completed)
                      </TableCell>
                      <TableCell
                        align='center'
                        sx={{
                          borderRight: theme.palette.mode === 'dark' ? '1px solid #515151' : '1px solid #adadad',
                          backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : 'inherit',
                        }}
                      >
                        {month} (Cancelled)
                      </TableCell>
                    </React.Fragment>
                  ))}
                  <TableCell
                    align='center'
                    sx={{
                      backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : 'inherit',
                    }}
                  >
                    Total Loadings
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((hauler, index) => (
                  <TableRow
                    key={index}
                    sx={{ backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : '#292929' }}
                  >
                    <TableCell
                      align='left'
                      sx={{
                        borderRight: theme.palette.mode === 'dark' ? '1px solid #515151' : '1px solid #adadad',
                      }}
                    >
                      <Tooltip title={hauler.haulerName} placement='top'>
                        <span>{truncateName(hauler.haulerName)}</span>
                      </Tooltip>
                    </TableCell>
                    {allMonths.map(month => {
                      const monthData = hauler.monthlyHaulerStats.find(m => m.month === month);
                      return (
                        <React.Fragment key={`${month}-${hauler.haulerName}`}>
                          <TableCell align='center'>{monthData?.completedLoadings || 0}</TableCell>
                          <TableCell
                            align='center'
                            sx={{
                              borderRight: theme.palette.mode === 'dark' ? '1px solid #515151' : '1px solid #adadad',
                            }}
                          >
                            {monthData?.canceledLoadings || 0}
                          </TableCell>
                        </React.Fragment>
                      );
                    })}
                    <TableCell align='center'>{hauler.totalLoadings}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell
                    sx={{
                      borderRight: theme.palette.mode === 'dark' ? '1px solid #515151' : '1px solid #adadad',
                      backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : 'inherit',
                    }}
                  >
                    YTD
                  </TableCell>
                  {totalCompletedLoadings.map((totalCompletedLoading, index) => (
                    <React.Fragment key={index}>
                      <TableCell
                        align='center'
                        sx={{
                          backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : 'inherit',
                        }}
                      >
                        {totalCompletedLoading}
                      </TableCell>
                      <TableCell
                        align='center'
                        sx={{
                          borderRight: theme.palette.mode === 'dark' ? '1px solid #515151' : '1px solid #adadad',
                          backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : 'inherit',
                        }}
                      >
                        {totalCanceledLoadings[index]}
                      </TableCell>
                    </React.Fragment>
                  ))}
                  <TableCell
                    align='center'
                    sx={{
                      backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : 'inherit',
                    }}
                  >
                    {data.reduce((total, hauler) => total + hauler.totalLoadings, 0)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <GeneralDialogueActions noSubmission handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default MonthlyHaulerStatsTable;
