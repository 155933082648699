import React from 'react';
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';
import dayjs from 'dayjs';
// @ts-expect-error: TODO: Fix typing issue
import FontRubikRegular from '../../assets/fonts/Rubik-Regular.ttf';
// @ts-expect-error: TODO: Fix typing issue
import FontRubikMedium from '../../assets/fonts/Rubik-Medium.ttf';
// @ts-expect-error: TODO: Fix typing issue
import FontRubikSemiBold from '../../assets/fonts/Rubik-SemiBold.ttf';
// @ts-expect-error: TODO: Fix typing issue
import FontRubikBold from '../../assets/fonts/Rubik-Bold.ttf';
// @ts-expect-error: TODO: Fix typing issue
import ReudanLogo from '../../assets/REUDAN LOGO.png';
import { Booking, StepStatus } from 'models/booking.model';
import { extractDeadlineTime, formatDeadline } from 'utils/utils';
import { reudanAddress } from 'dataAssets/constants';
import { exportSteps } from 'dataAssets/actionSteps';
import { additionalQuotationTermsAndConditions } from 'dataAssets/additionalQuotationTermsAndConditions';

Font.register({
  family: 'Rubik',
  fonts: [
    { src: FontRubikRegular },
    { src: FontRubikMedium, fontWeight: 'medium' },
    { src: FontRubikSemiBold, fontWeight: 'semibold' },
    { src: FontRubikBold, fontWeight: 'bold' },
  ],
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: 'Rubik',
    fontSize: 9,
  },
  sectionHeading: {
    fontSize: 12,
    textTransform: 'uppercase',
    letterSpacing: 1,
    marginVertical: 10,
    fontWeight: 'semibold',
  },
  dataRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    marginBottom: 8,
  },
  dataCell: {
    width: '50%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  bookingCell: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  quotationCell: {
    width: '25%',
    textAlign: 'center',
  },
  watermark: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-160%, -50%)',
    opacity: 0.2,
    fontSize: 120,
    fontWeight: 'bold',
    color: 'gray',
    pointerEvents: 'none',
  },
  watermarkText: {
    transform: 'rotate(-45deg)',
  },
  additionalTermsAndConditionsHeader: {
    fontSize: 7,
    marginBottom: 3,
  },
  additionalTCBullet: {
    fontSize: 7,
    lineHeight: 1,
    marginLeft: 10,
    marginBottom: 2,
  },
});

export interface BookingConfirmationProps {
  booking: Booking;
  showQuotation?: boolean;
  onlyShowQuotation?: boolean;
}
const BookingConfirmation: React.FC<BookingConfirmationProps> = ({
  booking,
  showQuotation = true,
  onlyShowQuotation = false,
}) => {
  const isCarrierBookingCompleted = booking.stepsCompleted.some(
    step => step.dbRef === exportSteps[0].dbRef && step.status === StepStatus.Completed,
  );

  const termsAndConditions = onlyShowQuotation
    ? booking.quotationTermsAndConditions
    : booking.bookingTermsAndConditions;

  const emptyField = (field: string | undefined) => {
    return field && field.trim() !== '' ? field : '-';
  };

  return (
    <Document>
      <Page size='A4' style={styles.body}>
        {(!isCarrierBookingCompleted || !booking.carrierBookingNumber) && !onlyShowQuotation && (
          <View style={styles.watermark}>
            <Text style={styles.watermarkText}>DRAFT</Text>
          </View>
        )}
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 10,
          }}
        >
          <View>
            <Text
              style={{
                fontSize: 24,
                fontWeight: 'bold',
              }}
            >
              Reudan International Ltd
            </Text>
            <Text
              style={{
                fontSize: 8,
              }}
            >
              {reudanAddress}
            </Text>
            <Text
              style={{
                fontSize: 14,
                fontWeight: 'semibold',
                marginTop: 20,
              }}
            >
              {`${onlyShowQuotation ? 'Quotation' : 'Booking'} Confirmation: ${booking.bookingNumber}`}
            </Text>
          </View>
          <View
            style={{
              width: '15%',
            }}
          >
            <Image src={ReudanLogo} />
          </View>
        </View>
        <View
          style={{
            borderTop: '1px solid black',
          }}
        >
          <Text style={styles.sectionHeading}>Customer Details</Text>
          <View
            style={{
              marginBottom: 10,
              gap: 10,
            }}
          >
            <View>
              <Text
                style={{
                  marginBottom: 5,
                }}
              >
                {`Customer: ${booking.consignor?.name}`}
              </Text>

              <Text
                style={{
                  marginBottom: 5,
                }}
              >
                {`Address: ${booking.consignor?.address}, ${booking.consignor?.city}, ${booking.consignor?.postcode}, ${booking.consignor?.country}`}
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            borderTop: '1px solid black',
          }}
        >
          <Text style={styles.sectionHeading}>Shipment Details</Text>
          <View>
            <View style={styles.dataRow}>
              <View style={styles.dataCell}>
                <Text>{'Carrier:'}</Text>
                <Text
                  style={{
                    width: '85%',
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                    textAlign: 'right',
                  }}
                >
                  {booking.carrier?.name}
                </Text>
              </View>
              <View style={styles.dataCell}>
                <Text>{'Port of Loading:'}</Text>
                <Text>{booking.portOfLoading?.portName}</Text>
              </View>
            </View>
            <View style={styles.dataRow}>
              <View style={styles.dataCell}>
                <Text>{'Carrier Booking No:'}</Text>
                <Text
                  style={{
                    width: '55%',
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                    textAlign: 'right',
                  }}
                >
                  {booking.carrierBookingNumber}
                </Text>
              </View>
              <View style={styles.dataCell}>
                <Text>{'Port of Destination:'}</Text>
                <Text>{booking.portOfDestination?.portName}</Text>
              </View>
            </View>
            <View style={styles.dataRow}>
              <View style={styles.dataCell}>
                <Text>{'Cargo Details:'}</Text>
                <Text
                  style={{
                    width: '70%',
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                    textAlign: 'right',
                  }}
                >
                  {booking.cargoDescription}
                </Text>
              </View>
              <View style={styles.dataCell}>
                <Text>{'FPOD:'}</Text>
                <Text>{booking.placeOfDelivery?.portName}</Text>
              </View>
            </View>
            <View style={styles.dataRow}>
              <View style={styles.dataCell}>
                <Text>{'No. of Containers:'}</Text>
                <Text>{`${booking.numberOfContainers} x ${booking.containerType}`}</Text>
              </View>
              <View style={styles.dataCell}>
                <Text>{'Approximate Weight:'}</Text>
                <Text>{`${booking.approximateWeight} kg`}</Text>
              </View>
            </View>
            <View style={styles.dataRow}>
              <View style={styles.dataCell}>
                <Text>{'PO No:'}</Text>
                <Text>{`${booking.purchaseOrderNumber}`}</Text>
              </View>
              <View style={styles.dataCell}></View>
            </View>
          </View>
          {!onlyShowQuotation && (
            <View
              style={{
                paddingTop: 10,
                borderTop: '0.5px solid black',
              }}
            >
              <View style={styles.dataRow}>
                <View style={styles.dataCell}>
                  <Text>{'Vessel V. Voyage:'}</Text>
                  <Text
                    style={{
                      width: '70%',
                      display: 'flex',
                      flexDirection: 'column',
                      flexWrap: 'wrap',
                      textAlign: 'right',
                    }}
                  >{`${booking.vesselVoyage.vesselName} ${booking.vesselVoyage.voyageNumber}`}</Text>
                </View>
                <View style={styles.dataCell}>
                  <Text>{'Gate In Cut Off:'}</Text>
                  <Text>{`${formatDeadline(booking.deadlines?.GateIn)} @ ${extractDeadlineTime(
                    booking.deadlines?.GateIn,
                  )}`}</Text>
                </View>
              </View>
              <View style={styles.dataRow}>
                <View style={styles.dataCell}>
                  <Text>{'ETD:'}</Text>
                  <Text>{dayjs(booking.etd).format('DD-MM-YYYY')}</Text>
                </View>
                <View style={styles.dataCell}>
                  <Text>{'SI Cut Off:'}</Text>
                  <Text>{`${formatDeadline(
                    booking.deadlines?.SI,
                  )}  @ ${extractDeadlineTime(booking.deadlines?.SI)}`}</Text>
                </View>
              </View>
              <View style={styles.dataRow}>
                <View style={styles.dataCell}>
                  <Text>{'ETA:'}</Text>
                  <Text>{dayjs(booking.eta).format('DD-MM-YYYY')}</Text>
                </View>
                <View style={styles.dataCell}>
                  <Text>{'VGM Cut Off:'}</Text>
                  <Text>{`${formatDeadline(
                    booking.deadlines?.VGM,
                  )} @ ${extractDeadlineTime(booking.deadlines?.VGM)}`}</Text>
                </View>
              </View>
            </View>
          )}
          {booking.dangerousGoods?.isDangerous && (
            <View
              style={{
                paddingTop: 10,
                paddingBottom: 10,
                borderTop: '0.5px solid black',
              }}
            >
              <Text style={styles.sectionHeading}>Dangerous Goods Details</Text>
              <View style={styles.dataRow}>
                <View style={styles.dataCell}>
                  <Text>{'UN Number:'}</Text>
                  <Text>{emptyField(booking.dangerousGoods.unNumber)}</Text>
                </View>
                <View style={styles.dataCell}>
                  <Text>{'Class'}</Text>
                  <Text>{emptyField(booking.dangerousGoods.class)}</Text>
                </View>
              </View>
              <View style={styles.dataRow}>
                <View style={styles.dataCell}>
                  <Text>{'Packing Group:'}</Text>
                  <Text>{emptyField(booking.dangerousGoods.packingGroup)}</Text>
                </View>
                <View style={styles.dataCell}>
                  <Text>{'Flash Point:'}</Text>
                  <Text>{emptyField(booking.dangerousGoods.flashPoint)}</Text>
                </View>
              </View>
              <View style={styles.dataRow}>
                <View style={styles.dataCell}>
                  <Text>{'EMS:'}</Text>
                  <Text>{emptyField(booking.dangerousGoods.ems)}</Text>
                </View>
                <View style={styles.dataCell}>
                  <Text>{'Marine Pollutant:'}</Text>
                  <Text>{emptyField(booking.dangerousGoods.marinePollutant)}</Text>
                </View>
              </View>
              <View style={styles.dataRow}>
                <View style={styles.dataCell}>
                  <Text>{'Subsidiary Risk:'}</Text>
                  <Text>{emptyField(booking.dangerousGoods.subsidiaryRisk)}</Text>
                </View>
                <View style={styles.dataCell}>
                  <Text>{'Special Provision:'}</Text>
                  <Text>{emptyField(booking.dangerousGoods.specialProvision)}</Text>
                </View>
              </View>
              <View style={styles.dataRow}>
                <View style={styles.dataCell}>
                  <Text>{'Hazard Code:'}</Text>
                  <Text>{emptyField(booking.dangerousGoods.hazardCode)}</Text>
                </View>
                <View style={styles.dataCell}>
                  <Text>{'State:'}</Text>
                  <Text>{emptyField(booking.dangerousGoods.state)}</Text>
                </View>
              </View>
              <View style={styles.dataRow}>
                <View style={styles.dataCell}>
                  <Text>{'Emergency Contact:'}</Text>
                  <Text>{emptyField(booking.dangerousGoods.emergencyContact)}</Text>
                </View>
                <View style={styles.dataCell}>
                  <Text>{'Emergency Contact Number:'}</Text>
                  <Text>{emptyField(booking.dangerousGoods.emergencyContactNumber)}</Text>
                </View>
              </View>
            </View>
          )}
        </View>

        {showQuotation && (
          <View
            style={{
              borderTop: '1px solid black',
              marginBottom: 10,
            }}
          >
            <Text style={styles.sectionHeading}>Quotation Details</Text>
            <View
              style={{
                paddingHorizontal: 15,
              }}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  paddingBottom: 10,
                  borderBottom: '0.5px solid black',
                }}
              >
                <View
                  style={{
                    width: '40%',
                    textAlign: 'left',
                  }}
                >
                  <Text>Charge Name</Text>
                </View>
                <View style={styles.quotationCell}>
                  <Text>Currency</Text>
                </View>
                <View style={styles.quotationCell}>
                  <Text>Rate</Text>
                </View>
                <View
                  style={{
                    width: '10%',
                    textAlign: 'right',
                  }}
                >
                  <Text>Base</Text>
                </View>
              </View>
              {booking.sellRates.map((rate, index) => (
                <View
                  key={index}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    paddingTop: 10,
                  }}
                >
                  <View
                    style={{
                      width: '40%',
                      textAlign: 'left',
                    }}
                  >
                    <Text>{`${
                      typeof rate.chargeName === 'string'
                        ? rate.chargeName
                        : rate.chargeName?.chargeName || 'Unknown Charge Name'
                    }${rate.additionalDetails ? ` - ${rate.additionalDetails}` : ''}`}</Text>
                  </View>
                  <View style={styles.quotationCell}>
                    <Text>{rate.currency}</Text>
                  </View>
                  <View style={styles.quotationCell}>
                    <Text>{rate.rate}</Text>
                  </View>
                  <View
                    style={{
                      width: '10%',
                      textAlign: 'right',
                    }}
                  >
                    <Text>{rate.base}</Text>
                  </View>
                </View>
              ))}
            </View>
          </View>
        )}

        {booking.loadingTypeExport === 'SD' ? (
          <View>
            <View
              style={{
                borderTop: '1px solid black',
              }}
            >
              {onlyShowQuotation ? (
                <Text style={styles.sectionHeading}>Place Of Receipts</Text>
              ) : (
                <Text style={styles.sectionHeading}>{`${
                  booking.bookingType === 'Export' ? 'Loading' : 'Delivery'
                } Details`}</Text>
              )}
            </View>

            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 5,
                marginTop: 5,
                marginBottom: 20,
              }}
            >
              {booking.transportSites?.map((transportSite, index) => {
                const site = transportSite.site;
                return (
                  <View key={index}>
                    <Text
                      style={{
                        fontWeight: 'medium',
                        marginBottom: 4,
                      }}
                    >{`${booking.bookingType === 'Export' ? 'Loading' : 'Delivery'} Point ${index + 1}:`}</Text>
                    <Text
                      style={{
                        lineHeight: 1.4,
                      }}
                    >
                      {`${site?.siteName}, ${site?.address}, ${site?.city}, ${site?.postcode}`}
                    </Text>
                  </View>
                );
              })}
            </View>
            {!onlyShowQuotation && (
              <>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    paddingBottom: 10,
                    borderBottom: '0.5px solid black',
                  }}
                >
                  <View
                    style={{
                      width: '10%',
                      textAlign: 'center',
                    }}
                  >
                    <Text>S.No</Text>
                  </View>
                  <View
                    style={{
                      width: '25%',
                      textAlign: 'center',
                    }}
                  >
                    <Text>Site</Text>
                  </View>
                  <View
                    style={{
                      width: '20%',
                      textAlign: 'center',
                    }}
                  >
                    <Text>Date</Text>
                  </View>
                  <View
                    style={{
                      width: '20%',
                      textAlign: 'center',
                    }}
                  >
                    <Text>Time</Text>
                  </View>
                  <View
                    style={{
                      width: '25%',
                      textAlign: 'center',
                    }}
                  >
                    <Text>Reference</Text>
                  </View>
                </View>
                {booking.transportSchedule?.map((load, index) => (
                  <View
                    key={index}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      paddingTop: 10,
                    }}
                  >
                    <View
                      style={{
                        width: '10%',
                        textAlign: 'center',
                      }}
                    >
                      <Text>{`${index + 1}`}</Text>
                    </View>
                    <View
                      style={{
                        width: '25%',
                        textAlign: 'center',
                      }}
                    >
                      <Text>{load.site}</Text>
                    </View>
                    <View
                      style={{
                        width: '20%',
                        textAlign: 'center',
                      }}
                    >
                      <Text>{dayjs(load.date).format('DD-MM-YYYY')}</Text>
                    </View>
                    <View
                      style={{
                        width: '20%',
                        textAlign: 'center',
                      }}
                    >
                      <Text>{dayjs(load.time).format('HH:mm')}</Text>
                    </View>
                    <View
                      style={{
                        width: '25%',
                        textAlign: 'center',
                      }}
                    >
                      <Text>{load.reference}</Text>
                    </View>
                  </View>
                ))}
              </>
            )}
          </View>
        ) : (
          !onlyShowQuotation && (
            <View>
              <View
                style={{
                  borderTop: '1px solid black',
                }}
              >
                <Text style={styles.sectionHeading}>{`${
                  booking.bookingType === 'Export' ? 'Loading' : 'Delivery'
                } Details`}</Text>
              </View>
              <View>
                {booking.pickupDepot?.map((depot, index) => (
                  <>
                    <Text key={index} style={{ fontWeight: 'medium', marginBottom: 4 }}>
                      {`Pickup Depot ${index + 1} :`}
                    </Text>
                    <Text key={index}>
                      {`Address: ${depot.site?.siteName}, ${depot.site?.address}, ${depot.site?.city}, ${depot.site?.postcode} `}
                    </Text>
                    <Text key={index}>{`Containers: ${depot.containers}`}</Text>
                    <Text key={index}>{`Reference: ${depot.reference ?? 'N/A'}`}</Text>
                  </>
                ))}

                {booking.dropoffDepot?.map((depot, index) => (
                  <>
                    <Text key={index} style={{ fontWeight: 'medium', marginBottom: 4 }}>
                      {`Dropoff Depot ${index + 1} :`}
                    </Text>
                    <Text key={index}>
                      {`Address: ${depot.site?.siteName}, ${depot.site?.address}, ${depot.site?.city}, ${depot.site?.postcode} `}
                    </Text>
                    <Text key={index}>{`Containers: ${depot.containers}`}</Text>
                    <Text key={index}>{`Reference: ${depot.reference ?? 'N/A'}`}</Text>
                  </>
                ))}
              </View>
            </View>
          )
        )}
        <>
          {!onlyShowQuotation && (
            <View
              style={{
                paddingHorizontal: 5,
                paddingTop: 5,
                paddingBottom: 20,
                marginTop: 20,
                border: '1px solid black',
                borderRadius: 3,
              }}
            >
              <Text style={{ marginBottom: 5 }}>Remarks</Text>
              {booking.remarks?.map((remarkMap, index) => (
                <Text
                  key={index}
                  style={{
                    fontSize: 8,
                    lineHeight: 1.4,
                    marginBottom: 5,
                  }}
                >
                  {`${index + 1}. ${remarkMap.remark}`}
                </Text>
              ))}
            </View>
          )}
          <View
            style={{
              marginTop: 20,
              borderTop: '1px solid black',
            }}
          >
            <Text style={styles.sectionHeading}>Terms & Conditions</Text>
            {termsAndConditions?.map((term, index) => (
              <Text
                key={index}
                style={{
                  fontSize: 8,
                  lineHeight: 1.4,
                  marginBottom: 5,
                }}
              >
                {`${index + 1}. ${term.termsAndCondition}`}
              </Text>
            ))}
            {onlyShowQuotation && (
              <View>
                <Text
                  style={{
                    fontSize: 10,
                    marginVertical: 5,
                  }}
                >
                  Additional T&Cs
                </Text>
                {additionalQuotationTermsAndConditions.map((term, index) => (
                  <View key={index} style={{ marginBottom: 2 }}>
                    <Text style={styles.additionalTermsAndConditionsHeader}>{term.section}</Text>
                    {term.content.map((bullet, idx) => (
                      <Text key={idx} style={styles.additionalTCBullet}>
                        {`• ${bullet}`}
                      </Text>
                    ))}
                  </View>
                ))}
              </View>
            )}
          </View>
        </>
      </Page>
    </Document>
  );
};

export default BookingConfirmation;
